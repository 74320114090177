import React, { useEffect, useState } from "react";
import {
  Upload,
  Flex,
  Radio,
  notification,
  InputNumber,
  Select,
  Collapse,
  Typography,
  Switch,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import { CaretRightOutlined } from "@ant-design/icons";

import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  addeditdata as addedirstore,
  chnageStatus,
  categoryList,
} from "../../../controllers/stores/storesController";
import { fetchIpAddress } from "../../../controllers/API";

import MapComponent from "../../../components/Map/MapComponent";
import LocationMap from "../../../components/Map/LocationMap";

const UserListEditPage = () => {
  const { Panel } = Collapse;
  const { Text } = Typography;

  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;
    return <CaretRightOutlined rotate={isActive ? 90 : 0} />;
  };
  const { Option } = Select;
  const { Dragger } = Upload;

  const location = useLocation();
  const navigate = useNavigate();
  const { vendor, addeditdata } = location.state;
  const [error, serError] = useState([]);
  const [ADDEDITDATA, setAddEditSata] = useState(
    addeditdata ? addeditdata : []
  );
  const [image, setImage] = useState([]);
  const [category, setCategory] = useState([]);

  const [storeTime, setStoreTime] = useState([]);
  // console.log('ADDEDITDATA',ADDEDITDATA);
  const [petSize, setPerSize] = useState({
    small: addeditdata?.size?.small === "Y" ? "Y" : "N",
    medium: addeditdata?.size?.medium === "Y" ? "Y" : "N",
    large: addeditdata?.size?.large === "Y" ? "Y" : "N",
  });

  const [shopFulfillment, setShopFulfillment] = useState({
    in_store: addeditdata?.shopFulfillment?.in_store === "Y" ? "Y" : "N",
    dilevery: addeditdata?.shopFulfillment?.dilevery === "Y" ? "Y" : "N",
  });

  const [trainingFulfillment, setTrainingFulfillment] = useState({
    home: addeditdata?.trainingFulfillment?.home === "Y" ? "Y" : "N",
    institute: addeditdata?.trainingFulfillment?.institute === "Y" ? "Y" : "N",
  });

  const [groomingFulfillment, setGroomingFulfillment] = useState({
    in_store: addeditdata?.groomingFulfillment?.in_store === "Y" ? "Y" : "N",
    type_mobile:
      addeditdata?.groomingFulfillment?.type_mobile === "Y" ? "Y" : "N",
    pick_drop: addeditdata?.groomingFulfillment?.pick_drop === "Y" ? "Y" : "N",
  });

  const [friendlyFulfillment, setFriendlyFulfillment] = useState({
    park: addeditdata?.friendlyFulfillment?.park === "Y" ? "Y" : "N",
    restaurant:
      addeditdata?.friendlyFulfillment?.restaurant === "Y" ? "Y" : "N",
    cafe: addeditdata?.friendlyFulfillment?.cafe === "Y" ? "Y" : "N",
    beaches: addeditdata?.friendlyFulfillment?.beaches === "Y" ? "Y" : "N",
  });

  const [center_facilities, setCenterFacilities] = useState([]);

  /*********************************************************
   *  This function is use to handle imput chnage
   *********************************************************/
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddEditSata((pre) => ({
      ...pre,
      [name]: value,
    }));
    serError((pre) => ({
      ...pre,
      [name]: "",
    }));
  };
  /*********************************************************
   *  This function is use to handle image
   *********************************************************/
  const handleImageChnage = (e) => {
    setImage(e.fileList);
  };

  /*********************************************************
   *  This function is use to handle phone chnage chnage
   *********************************************************/
  const handlePhoneChnage = (value) => {
    setAddEditSata((pre) => ({
      ...pre,
      phone: value,
    }));
    serError((pre) => ({
      ...pre,
      phone: "",
    }));
  };

  /*********************************************************
   *  This function is use to handle phone code chnage
   *********************************************************/
  const handlePhoneCodeChnage = (value) => {
    setAddEditSata((pre) => ({
      ...pre,
      country_code: value,
    }));
  };

  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async () => {
    try {
      const {
        _id,
        shop_name,
        phone,
        email,
        opentime,
        closetime,
        longitude,
        latitude,
        city,
        address,
        street,
        in_store,
        pick_drop,
        delivery,
        type_mobile,
        short_details,
        title,
        next_day_delivery_charges,
        today_delivery_charges,
        branch_manager_phone,
        branch_manager,
        center_facilities,
        password,
        confirm_password
      } = ADDEDITDATA;
      const ipAddress = await fetchIpAddress();
      const form = new FormData();
      if (_id && _id !== "undefined" && _id !== null) {
        form.append("edit_id", _id);
      }else{
        form.append("password", password);
        form.append("confirm_password", confirm_password);
      }
      form.append("vendorid", vendor?._id);
      form.append("shop_name", shop_name);
      form.append("title", title);
      form.append("short_details", short_details);
      form.append("phone", phone);
      form.append("email", email);
      form.append("opentime", opentime);
      form.append("closetime", closetime);
      form.append("longitude", longitude);
      form.append("latitude", latitude);
      form.append("city", city);
      form.append("address", address);
      form.append("street", street);
      if (image.length > 0) {
        form.append("image", image[0]?.originFileObj);
      }
      form.append("in_store", in_store ? in_store : "N");
      form.append("pick_drop", pick_drop ? pick_drop : "N");
      form.append("type_mobile", type_mobile ? type_mobile : "N");
      form.append("delivery", delivery ? delivery : "N");
      form.append("store_time", JSON.stringify(storeTimes));

      form.append("next_day_delivery_charges", next_day_delivery_charges);
      form.append("today_delivery_charges", today_delivery_charges);

      form.append("branch_manager_phone", branch_manager_phone);
      form.append("branch_manager", branch_manager);

      form.append("size", JSON.stringify(petSize));
      form.append("shopFulfillment", JSON.stringify(shopFulfillment));
      form.append("trainingFulfillment", JSON.stringify(trainingFulfillment));
      form.append("groomingFulfillment", JSON.stringify(groomingFulfillment));
      form.append("friendlyFulfillment", JSON.stringify(friendlyFulfillment));
      form.append("center_facilities", center_facilities);
      form.append("ipAddress", ipAddress);
      const isValidate = await validateFormData(form);
      // console.log('isValidate',error);
      if (isValidate) {
        const options = {
          url: "accounts/stores/addeditdata",
          postData: form,
        };
        const res = await addedirstore(options);
        if (res.status === true) {
          notification.open({
            message: "Data updated.",
            description: `Data updated Successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          navigate("/vendors/list");
        } else {
          notification.open({
            message: "Opps!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      } else {
        notification.open({
          message: "Opps!",
          description: `Please enter the required fields.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /*********************************************************
   *  This function is use to validate form data before submit
   *********************************************************/
  const validateFormData = async (formData) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberRegex = /^[0-9]+$/;
    const phone_code = ADDEDITDATA?.country_code || '+971';
    if (
      formData.get("shop_name") === "undefined" ||
      formData.get("shop_name") === null ||
      formData.get("shop_name").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        shop_name: "Store name is required",
      }));
      return false;
    } else if (
      formData.get("title") === "undefined" ||
      formData.get("title") === null ||
      formData.get("title").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        title: "Title is required",
      }));
      return false;
    } else if (
      formData.get("short_details") === "undefined" ||
      formData.get("short_details") === null ||
      formData.get("short_details").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        short_details: "Short details is required",
      }));
      return false;
    } else if (
      formData.get("phone") === "undefined" ||
      formData.get("phone") === null ||
      formData.get("phone").trim() === ""||
      !numberRegex.test(formData.get("phone"))
    ) {
        serError((preError) => ({
          ...preError,
          phone: "Enter a valid phone number",
        }));
      return false;
    } else if ( 
      phone_code === '+971' && String(formData.get("phone")).length !== 9
    ) {
        serError((preError) => ({
          ...preError,
          phone: "Phone number should be 9 digits",
        }));
      return false;
    } else if ( 
      phone_code === '+91' && String(formData.get("phone")).length !== 10
    ) {
        serError((preError) => ({
          ...preError,
          phone: "Phone number should be 10 digits",
        }));
      return false;
    } else if (
      formData.get("email") === "undefined" ||
      formData.get("email") === null ||
      formData.get("email").trim() === "" ||
      !emailRegex.test(formData.get("email"))
    ) {
      if (formData.get("email").trim() === "") {
        serError((preError) => ({
          ...preError,
          email: "Email address is required",
        }));
      } else {
        serError((prevError) => ({
          ...prevError,
          email: "Enter a valid email address",
        }));
      }
      return false;
    } else if (
      formData.get("opentime") === "undefined" ||
      formData.get("opentime") === null ||
      formData.get("opentime").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        opentime: "Open Time is required",
      }));
      return false;
    } else if (
      formData.get("closetime") === "undefined" ||
      formData.get("closetime") === null ||
      formData.get("closetime").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        close_time: "Close time is required",
      }));
      return false;
    } else if (
      formData.get("today_delivery_charges") === "undefined" ||
      formData.get("today_delivery_charges") === null ||
      formData.get("today_delivery_charges").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        today_delivery_charges: "Today delivery charges is required",
      }));
      return false;
    } else if (
      formData.get("next_day_delivery_charges") === "undefined" ||
      formData.get("next_day_delivery_charges") === null ||
      formData.get("next_day_delivery_charges").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        next_day_delivery_charges: "Next day delivery charges is required",
      }));
      return false;
    } else if (
      formData.get("latitude") === "undefined" ||
      formData.get("latitude") === null ||
      formData.get("latitude").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        latitude: "Latitude is required",
      }));
      return false;
    } else if (
      formData.get("longitude") === "undefined" ||
      formData.get("longitude") === null ||
      formData.get("longitude").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        longitude: "Longitude is required",
      }));
      return false;
    } else if (
      formData.get("branch_manager") === "undefined" ||
      formData.get("branch_manager") === null ||
      formData.get("branch_manager").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        branch_manager: "Manager name is required",
      }));
      return false;
    } else if (
      formData.get("branch_manager_phone") === "undefined" ||
      formData.get("branch_manager_phone") === null ||
      formData.get("branch_manager_phone").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        branch_manager_phone: "Manager phone is required",
      }));
      return false;
    } else if (!ADDEDITDATA?._id) {
      if (!formData.get("image")) {
        serError((preError) => ({
          ...preError,
          image: "Image is required",
        }));
        return false;
      } else {
        return true;
      }
    } else if (
      formData.get("vendorid") === "undefined" ||
      formData.get("vendorid") === null ||
      formData.get("vendorid").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        vendor: "User Not Found",
      }));
      return false;
    } else if (
      formData.get("store_time") === "undefined" ||
      formData.get("store_time") === null ||
      formData.get("store_time").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        store_time: "Please  add the working hours.",
      }));
      return false;
    // } else if (
    //   formData.get("shopFulfillment") === "undefined" ||
    //   formData.get("shopFulfillment") === null ||
    //   formData.get("shopFulfillment").trim() === ""
    // ) {
    //   serError((preError) => ({
    //     ...preError,
    //     shop: "Please select any one option.",
    //   }));
    //   return false;
    // } else if (
    //   formData.get("trainingFulfillment") === "undefined" ||
    //   formData.get("trainingFulfillment") === null ||
    //   formData.get("trainingFulfillment").trim() === ""
    // ) {
    //   serError((preError) => ({
    //     ...preError,
    //     training: "Please select any one option.",
    //   }));
    //   return false;
    // } else if (
    //   formData.get("groomingFulfillment") === "undefined" ||
    //   formData.get("groomingFulfillment") === null ||
    //   formData.get("groomingFulfillment").trim() === ""
    // ) {
    //   serError((preError) => ({
    //     ...preError,
    //     grooming: "Please select any one option.",
    //   }));
    //   return false;
    // } else if (
    //   formData.get("friendlyFulfillment") === "undefined" ||
    //   formData.get("friendlyFulfillment") === null ||
    //   formData.get("friendlyFulfillment").trim() === ""
    // ) {
    //   serError((preError) => ({
    //     ...preError,
    //     friendly: "Please select any one option.",
    //   }));
    //   return false;
    } else if (
      formData.get("size") === "undefined" ||
      formData.get("size") === null ||
      formData.get("size").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        size: "Please select any one option.",
      }));
      return false;
    } else {
      const isValidStoreTime = await validateStoreTimes();
      // const isValidateFulfillment = await validateFulfillment();
      if (!isValidStoreTime) {
        serError((preError) => ({
          ...preError,
          store_time: "Please  add the working hours.",
        }));
        return false;
      // } else if (!isValidateFulfillment) {
      //   return false;
      } else {
        return true;
      }
    }
  }; //End

  const validateStoreTimes = async () => {
    let isOpenDay = false;

    for (const day in storeTimes) {
      if (storeTimes[day].status === "Open") {
        if (storeTimes[day].open_time && storeTimes[day].close_time) {
          isOpenDay = true;
          break;
        }
      }
    }

    return isOpenDay;
  };

  const validateFulfillment = async () => {
    try {
      if (
        category.includes("Training") &&
        trainingFulfillment?.home === "N" &&
        trainingFulfillment?.institute === "N"
      ) {
        serError((preError) => ({
          ...preError,
          training: "Please select any one option.",
        }));
        return false;
      } else if (
        category.includes("Grooming") &&
        groomingFulfillment?.in_store === "N" &&
        groomingFulfillment?.type_mobile === "N" &&
        groomingFulfillment?.pick_drop === "N"
      ) {
        serError((preError) => ({
          ...preError,
          grooming: "Please select any one option.",
        }));
        return false;
      } else if (
        category.includes("Friendly") &&
        friendlyFulfillment?.park === "N" &&
        friendlyFulfillment?.restaurant === "N" &&
        friendlyFulfillment?.cafe === "N" &&
        friendlyFulfillment?.beaches === "N"
      ) {
        serError((preError) => ({
          ...preError,
          friendly: "Please select any one option.",
        }));
        return false;
      } else if (
        category.includes("Shop") &&
        shopFulfillment?.in_store === "N" &&
        shopFulfillment?.delivery === "N"
      ) {
        serError((preError) => ({
          ...preError,
          shop: "Please select any one option.",
        }));
        return false;
      } else if (
        petSize?.small === "N" &&
        petSize?.medium === "N" &&
        petSize?.large === "N"
      ) {
        serError((preError) => ({
          ...preError,
          size: "Please select any one option.",
        }));
        return false;
      } else {
        return true;
      }
    } catch (error) {}
  };

  /*********************************************************
   *  This function is use to handle delete account
   *********************************************************/
  const handleDelete = async () => {
    try {
      const options = {
        url: `accounts/vendors/changestatus`,
        postData: {
          user_id: ADDEDITDATA?._id,
          status: "D",
        },
      };
      const res = await chnageStatus(options);
      if (res.status === true) {
        notification.open({
          message: "User deleted successfully.",
          description: `Data deleted Successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        navigate("/users/list");
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Opps!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
  };

  const handlePetSize = (value) => {
    try {
      setPerSize((pre) => ({
        ...pre,
        [value]: petSize[value] === "Y" ? "N" : "Y",
      }));
      serError((preError) => ({
        ...preError,
        size: "",
      }));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleShopFulfillment = (value) => {
    try {
      setShopFulfillment((pre) => ({
        ...pre,
        [value]: shopFulfillment[value] === "Y" ? "N" : "Y",
      }));
      serError((preError) => ({
        ...preError,
        shop: "",
      }));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleTrainingFulfillment = (value) => {
    try {
      setTrainingFulfillment((pre) => ({
        ...pre,
        [value]: trainingFulfillment[value] === "Y" ? "N" : "Y",
      }));
      serError((preError) => ({
        ...preError,
        training: "",
      }));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGroomingFulfillment = (value) => {
    try {
      setGroomingFulfillment((pre) => ({
        ...pre,
        [value]: groomingFulfillment[value] === "Y" ? "N" : "Y",
      }));
      serError((preError) => ({
        ...preError,
        grooming: "",
      }));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleFriendlyFulfillment = (value) => {
    try {
      setFriendlyFulfillment((pre) => ({
        ...pre,
        [value]: friendlyFulfillment[value] === "Y" ? "N" : "Y",
      }));
      serError((preError) => ({
        ...preError,
        friendly: "",
      }));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleTypeClick = (name) => {
    try {
      setAddEditSata((pre) => ({
        ...pre,
        [name]: ADDEDITDATA[name] === "Y" ? "N" : "Y",
      }));
    } catch (error) {}
  };

  const getAllCategory = async () => {
    try {
      const options = {
        condition: { vendor: ADDEDITDATA?.vendorData?._id, status: "A" },
      };
      const res = await categoryList(options);
      if (res.status === true) {
        const cat = res.result.map((item) => item.category.name);
        setCategory(cat);
      } else {
        setCategory([]);
      }
    } catch (error) {
      setCategory([]);
    }
  };

  useEffect(() => {
    const transformedData = Object.values(ADDEDITDATA?.store_time || {}).map(
      (dayData) => ({
        [dayData.day]: {
          day: dayData.day,
          open_time: dayData.open_time,
          close_time: dayData.close_time,
          status: dayData.status,
        },
      })
    );
    transformedData.map((item) =>
      setStoreTimes((pre) => ({ ...pre, ...item }))
    );
    getAllCategory();
    if (ADDEDITDATA?._id && Array.isArray(ADDEDITDATA?.center_facilities)) {
      setAddEditSata((pre) => ({
        ...pre,
        center_facilities: ADDEDITDATA?.center_facilities.join(", "),
      }));
    }
    document.title = `Frisbee Super Admin || ${
      ADDEDITDATA?._id ? "Edit" : "Add"
    } Store`;
  }, []);
  const handleBack = () => {
    navigate("/store/list", { state: vendor });
  };

  const [storeTimes, setStoreTimes] = useState({
    monday: {
      day: "monday",
      open_time: "08:00",
      close_time: "20:00",
      status: "Close",
    },
    tuesday: {
      day: "tuesday",
      open_time: "08:00",
      close_time: "20:00",
      status: "Close",
    },
    wednesday: {
      day: "wednesday",
      open_time: "08:00",
      close_time: "20:00",
      status: "Close",
    },
    thursday: {
      day: "thursday",
      open_time: "08:00",
      close_time: "20:00",
      status: "Close",
    },
    friday: {
      day: "friday",
      open_time: "08:00",
      close_time: "20:00",
      status: "Close",
    },
    saturday: {
      day: "saturday",
      open_time: "08:00",
      close_time: "20:00",
      status: "Close",
    },
    sunday: {
      day: "sunday",
      open_time: "08:00",
      close_time: "20:00",
      status: "Close",
    },
  });

  const handleStoreTimeClick = (day) => {
    try {
      setStoreTimes((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          status: prev[day].status === "Open" ? "Close" : "Open",
        },
      }));

      serError((preError) => ({
        ...preError,
        store_time: "",
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleStoreTimeChnage = (e) => {
    try {
      const { name, value } = e.target;
      const day = e.target.attributes.data_day.nodeValue;
      setStoreTimes((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          [name]: value,
        },
      }));
      serError((preError) => ({
        ...preError,
        store_time: "",
      }));
    } catch (error) {}
  };
  // console.log('groomingFulfillment',groomingFulfillment);
  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <div>
            <h5 className="inventory_edit_page_heading">
              {ADDEDITDATA?._id ? `Edit` : `Add`} Store
            </h5>
          </div>
          <div onClick={handleBack}>
            <button type="button" className="btn btn-secondary">
              <ArrowLeftOutlined /> Back
            </button>
          </div>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form method="post" className="inventory_form_main_contnet">
                <div className="row inventory_form_main_content_form_section">
                  <div className="row ">
                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="vendor_first_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>Store Name :{" "}
                      </label>
                      <input
                        title="Enter store name."
                        placeholder="Enter store name."
                        type="text"
                        name="shop_name"
                        className="form-control store_input_field"
                        id="shop_name"
                        value={ADDEDITDATA?.shop_name}
                        onChange={handleChange}
                      />
                      {error.shop_name ? (
                        <p style={{ color: "red" }}>{error.shop_name}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="title"
                        className="all_top_and_bottom_for_input_text"
                      >
                        <span style={{ color: "red" }}>*</span>Title :{" "}
                      </label>
                      <input
                        title="Enter title."
                        placeholder="Enter title."
                        type="text"
                        name="title"
                        className="form-control store_input_field"
                        id="title"
                        value={ADDEDITDATA?.title}
                        onChange={handleChange}
                      />
                      {error.title ? (
                        <p style={{ color: "red" }}>{error.title}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="short_details"
                        className="all_top_and_bottom_for_input_text"
                      >
                        <span style={{ color: "red" }}>*</span>Short Details :{" "}
                      </label>
                      <input
                        title="Enter Short Details."
                        placeholder="Enter Short Details."
                        type="text"
                        name="short_details"
                        className="form-control store_input_field"
                        id="short_details"
                        value={ADDEDITDATA?.short_details}
                        onChange={handleChange}
                      />
                      {error.short_details ? (
                        <p style={{ color: "red" }}>{error.short_details}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="email"
                        className="all_top_and_bottom_for_input_text"
                      >
                        <span style={{ color: "red" }}>*</span>Email Address :
                      </label>
                      <input
                        title="Enter your email address."
                        placeholder="Enter email address."
                        type="text"
                        name="email"
                        className="form-control store_input_field"
                        id="email"
                        value={ADDEDITDATA?.email}
                        onChange={handleChange}
                      />
                      {error.email ? (
                        <p style={{ color: "red" }}>{error.email}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label
                        htmlFor="phone"
                        className="all_top_and_bottom_for_input_text"
                      >
                        <span style={{ color: "red" }}>*</span>Phone Number :
                      </label>
                      <br />
                      <InputNumber
                        style={{ width: "100%" }}
                        onChange={handlePhoneChnage}
                        placeholder="Enter phone number."
                        addonBefore={
                          <Select
                            defaultValue={
                              ADDEDITDATA?.vendor_country_code
                                ? ADDEDITDATA.vendor_country_code
                                : "+971"
                            }
                            style={{ width: 150 }}
                            onChange={handlePhoneCodeChnage}
                          >
                            <Option value="+971">+971 (UAE)</Option>
                            {/* <Option value="+974">+974 (QA)</Option>
                          <Option value="+965">+965 (KW)</Option>
                          <Option value="+966">+966 (SA)</Option>
                          <Option value="+968">+968 (OM)</Option> */}
                            <Option value="+91">+91 (IND)</Option>
                          </Select>
                        }
                        value={ADDEDITDATA?.phone}
                      />
                      {error.phone ? (
                        <p style={{ color: "red" }}>{error.phone}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    {!ADDEDITDATA?._id && (
                      <React.Fragment>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                          <label
                            htmlFor="password"
                            className="all_top_and_bottom_for_input_text"
                          >
                            <span style={{ color: "red" }}>*</span>Password :
                          </label>
                          <input
                            title="Password"
                            placeholder="Enter your password"
                            type="password"
                            name="password"
                            className="form-control store_input_field"
                            id="password"
                            value={ADDEDITDATA?.password}
                            onChange={handleChange}
                          />
                          {error.password ? (
                            <p style={{ color: "red" }}>{error.password}</p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                          <label
                            htmlFor="confirm_password"
                            className="all_top_and_bottom_for_input_text"
                          >
                            <span style={{ color: "red" }}>*</span>Confirm Password :
                          </label>
                          <input
                            title="Confirm Password"
                            placeholder="Enter confirm password"
                            type="text"
                            name="confirm_password"
                            className="form-control store_input_field"
                            id="confirm_password"
                            value={ADDEDITDATA?.confirm_password}
                            onChange={handleChange}
                          />
                          {error.confirm_password ? (
                            <p style={{ color: "red" }}>{error.confirm_password}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </React.Fragment>
                    )}


                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="branch_manager"
                        className="all_top_and_bottom_for_input_text"
                      >
                        <span style={{ color: "red" }}>*</span>Manager Name :
                      </label>
                      <input
                        title="Enter your manager name."
                        placeholder="Enter your manager name."
                        type="text"
                        name="branch_manager"
                        className="form-control store_input_field"
                        id="branch_manager"
                        value={ADDEDITDATA?.branch_manager}
                        onChange={handleChange}
                      />
                      {error.branch_manager ? (
                        <p style={{ color: "red" }}>{error.branch_manager}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="branch_manager_phone"
                        className="all_top_and_bottom_for_input_text"
                      >
                        <span style={{ color: "red" }}>*</span>Manager Phone :
                      </label>
                      <input
                        title="Enter your manager name."
                        placeholder="Enter your manager name."
                        type="number"
                        name="branch_manager_phone"
                        className="form-control store_input_field"
                        id="branch_manager_phone"
                        value={ADDEDITDATA?.branch_manager_phone}
                        onChange={handleChange}
                      />
                      {error.branch_manager_phone ? (
                        <p style={{ color: "red" }}>
                          {error.branch_manager_phone}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="opentime"
                        className="all_top_and_bottom_for_input_text"
                      >
                        <span style={{ color: "red" }}>*</span>Open Time :{" "}
                      </label>
                      <input
                        title="Open Time."
                        placeholder="Enter open time."
                        type="text"
                        name="opentime"
                        className="form-control store_input_field"
                        id="opentime"
                        value={ADDEDITDATA?.opentime}
                        onChange={handleChange}
                      />
                      {error.opentime ? (
                        <p style={{ color: "red" }}>{error.opentime}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="closetime"
                        className="all_top_and_bottom_for_input_text"
                      >
                        <span style={{ color: "red" }}>*</span>Close Time :{" "}
                      </label>
                      <input
                        title="Close Time."
                        placeholder="Enter close time."
                        type="text"
                        name="closetime"
                        className="form-control store_input_field"
                        id="closetime"
                        value={ADDEDITDATA?.closetime}
                        onChange={handleChange}
                      />
                      {error.closetime ? (
                        <p style={{ color: "red" }}>{error.closetime}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="today_delivery_charges"
                        className="all_top_and_bottom_for_input_text"
                      >
                        Today Dilevery Charges (AED) :
                      </label>
                      <input
                        title="Enter your today delivery charges."
                        type="number"
                        name="today_delivery_charges"
                        className="form-control store_input_field"
                        id="today_delivery_charges"
                        value={ADDEDITDATA?.today_delivery_charges}
                        onChange={handleChange}
                      />
                      {error.today_delivery_charges ? (
                        <p style={{ color: "red" }}>
                          {error.today_delivery_charges}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="next_day_delivery_charges"
                        className="all_top_and_bottom_for_input_text"
                      >
                        Next Day Dilevery Charges (AED) :
                      </label>
                      <input
                        title="Enter your next today delivery charges."
                        type="number"
                        name="next_day_delivery_charges"
                        className="form-control store_input_field"
                        id="next_day_delivery_charges"
                        value={ADDEDITDATA?.next_day_delivery_charges}
                        onChange={handleChange}
                      />
                      {error.next_day_delivery_charges ? (
                        <p style={{ color: "red" }}>
                          {error.next_day_delivery_charges}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="latitude"
                        className="all_top_and_bottom_for_input_text"
                      >
                        <span style={{ color: "red" }}>*</span>Latitude :{" "}
                      </label>
                      <input
                        title="Enter store latitude."
                        placeholder="Enter store latitude."
                        type="number"
                        name="latitude"
                        className="form-control store_input_field"
                        id="latitude"
                        // readOnly
                        // disabled
                        value={ADDEDITDATA?.latitude}
                        onChange={handleChange}
                      />
                      {error.latitude ? (
                        <p style={{ color: "red" }}> {error.latitude}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm- col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="title"
                        className="all_top_and_bottom_for_input_text"
                      >
                        <span style={{ color: "red" }}>*</span>Longitude :{" "}
                      </label>
                      <input
                        title="Enter store longitude."
                        placeholder="Enter store longitude."
                        type="number"
                        name="longitude"
                        className="form-control store_input_field"
                        id="longitude"
                        value={ADDEDITDATA?.longitude}
                        // readOnly
                        // disabled
                        onChange={handleChange}
                      />
                      {error.longitude ? (
                        <p style={{ color: "red" }}>{error.longitude}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <MapComponent setAddEditSata={setAddEditSata} />
                    {/* <div className="form-group col-lg-12 col-md-12 col-sm- col-xs-12 vendor_Add_edit_data all_top_and_bottom_for_input_text map_section_main_div">
                    </div>*/}

                    <div className="form-group col-lg-12 col-md-12 col-sm- col-xs-12 vendor_Add_edit_data all_top_and_bottom_for_input_text map_section_main_div">
                      <LocationMap  latitude = {ADDEDITDATA?.latitude} longitude = {ADDEDITDATA?.longitude} setAddEditSata = {setAddEditSata}  />
                    </div> 
                    

                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="city"
                        className="all_top_and_bottom_for_input_text"
                      >
                        Province/City/Area :
                      </label>
                      <input
                        title="Enter your Province/City/Area."
                        type="text"
                        name="city"
                        className="form-control store_input_field"
                        id="city"
                        value={ADDEDITDATA?.city}
                        onChange={handleChange}
                      />
                      {error.city ? (
                        <p style={{ color: "red" }}>{error.city}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="street"
                        className="all_top_and_bottom_for_input_text"
                      >
                        Street :
                      </label>
                      <input
                        title="Enter street name."
                        type="text"
                        name="street"
                        className="form-control store_input_field"
                        id="street"
                        value={ADDEDITDATA?.street}
                        onChange={handleChange}
                      />
                      {error.street ? (
                        <p style={{ color: "red" }}>{error.street}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="address"
                        className="all_top_and_bottom_for_input_text"
                      >
                        Address(Building, Floor) :
                      </label>
                      <input
                        title="Enter Address(Building, Floor)"
                        type="text"
                        name="address"
                        className="form-control store_input_field"
                        id="address"
                        value={ADDEDITDATA?.address}
                        onChange={handleChange}
                      />
                      {error.address ? (
                        <p style={{ color: "red" }}>{error.address}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">
                      {!ADDEDITDATA?._id && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      Images
                    </label>
                    <Dragger
                      className="about_us_dragger_file"
                      maxCount="1"
                      listType="picture"
                      onChange={handleImageChnage}
                    >
                      <button
                        type="button"
                        className="about_us_dragger_file_button"
                      >
                        Upload file
                      </button>
                      {/* <p className="ant-upload-hint">
                        Support for a single. Only accept jpeg/jpg/png format.
                      </p> */}
                    </Dragger>
                    {error.image ? (
                      <p style={{ color: "red" }}>{error.image}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group row vendor_Add_edit_data">
                    <label className="all_top_and_bottom_for_input_text">
                      {" "}
                      Store Timming :{" "}
                    </label>
                    <div class="card ">
                      <div class="card-body store_details_all_collapse">
                        {/* <div className="vendor_collapse_main_div_div_div"> */}
                          <Collapse
                            expandIconPosition="right"
                            activeKey={
                              storeTimes["sunday"]?.status === "Open"
                                ? "sunday"
                                : ""
                            }
                            onChange={() => handleStoreTimeClick("sunday")}
                            size="small"
                          >
                            <Panel
                              className="store_switch_main_div"
                              header={
                                <div className="switch_panel_header">
                                  <Switch
                                    className="categories_switch_"
                                    name="sunday"
                                    id="sunday"
                                    value="sunday"
                                    checked={
                                      storeTimes["sunday"]?.status === "Open"
                                        ? true
                                        : false
                                    }
                                  />
                                  <span className="store_profile_weekly_name">
                                    Sunday
                                  </span>
                                </div>
                              }
                              key="sunday"
                            >
                              <Text>
                                <div className="store_time_picker">
                                  <div className="store_deatils_time_picker_contnet_section">
                                    <input
                                      type="time"
                                      name="open_time"
                                      id="open_time"
                                      data_day="monday"
                                      value={storeTimes["sunday"]?.open_time}
                                      onChange={handleStoreTimeChnage}
                                      className="store_time_picker_main_section"
                                    />
                                  </div>
                                  <span className="store_details_to">to</span>
                                  <div className="store_deatils_time_picker_contnet_section">
                                    <input
                                      type="time"
                                      name="close_time"
                                      id="close_time"
                                      data_day="sunday"
                                      value={storeTimes["sunday"]?.close_time}
                                      onChange={handleStoreTimeChnage}
                                      className="store_time_picker_main_section"
                                    />
                                  </div>
                                </div>
                              </Text>
                            </Panel>
                          </Collapse>

                          <Collapse
                            expandIconPosition="right"
                            activeKey={
                              storeTimes["monday"]?.status === "Open"
                                ? "monday"
                                : ""
                            }
                            onChange={() => handleStoreTimeClick("monday")}
                            size="small"
                          >
                            <Panel
                              className="store_switch_main_div"
                              header={
                                <div className="switch_panel_header">
                                  <Switch
                                    className="categories_switch_"
                                    name="monday"
                                    id="monday"
                                    value="monday"
                                    checked={
                                      storeTimes["monday"]?.status === "Open"
                                        ? true
                                        : false
                                    }
                                  />
                                  <span className="store_profile_weekly_name">
                                    Monday
                                  </span>
                                </div>
                              }
                              key="monday"
                            >
                              <Text>
                                <div className="store_time_picker">
                                  <div className="store_deatils_time_picker_contnet_section">
                                    <input
                                      type="time"
                                      name="open_time"
                                      id="open_time"
                                      data_day="monday"
                                      value={storeTimes["monday"]?.open_time}
                                      onChange={handleStoreTimeChnage}
                                      className="store_time_picker_main_section"
                                    />
                                  </div>
                                  <span className="store_details_to">to</span>
                                  <div className="store_deatils_time_picker_contnet_section">
                                    <input
                                      type="time"
                                      name="close_time"
                                      id="close_time"
                                      data_day="monday"
                                      value={storeTimes["monday"]?.close_time}
                                      onChange={handleStoreTimeChnage}
                                      className="store_time_picker_main_section"
                                    />
                                  </div>
                                </div>
                              </Text>
                            </Panel>
                          </Collapse>
                        {/* </div> */}

                        <Collapse
                          expandIconPosition="right"
                          activeKey={
                            storeTimes["tuesday"]?.status === "Open"
                              ? "tuesday"
                              : ""
                          }
                          onChange={() => handleStoreTimeClick("tuesday")}
                          size="small"
                        >
                          <Panel
                            className="store_switch_main_div"
                            header={
                              <div className="switch_panel_header">
                                <Switch
                                  className="categories_switch_"
                                  name="tuesday"
                                  id="tuesday"
                                  value="tuesday"
                                  checked={
                                    storeTimes["tuesday"]?.status === "Open"
                                      ? true
                                      : false
                                  }
                                />
                                <span className="store_profile_weekly_name">
                                  Tuesday 
                                </span>
                              </div>
                            }
                            key="tuesday"
                          >
                            <Text>
                              <div className="store_time_picker">
                                <div className="store_deatils_time_picker_contnet_section">
                                  <input
                                    type="time"
                                    name="open_time"
                                    id="open_time"
                                    data_day="tuesday"
                                    value={storeTimes["tuesday"]?.open_time}
                                    onChange={handleStoreTimeChnage}
                                    className="store_time_picker_main_section"
                                  />
                                </div>
                                <span className="store_details_to">to</span>
                                <div className="store_deatils_time_picker_contnet_section">
                                  <input
                                    type="time"
                                    name="close_time"
                                    id="close_time"
                                    data_day="tuesday"
                                    value={storeTimes["tuesday"]?.close_time}
                                    onChange={handleStoreTimeChnage}
                                    className="store_time_picker_main_section"
                                  />
                                </div>
                              </div>
                            </Text>
                          </Panel>
                        </Collapse>
                        <Collapse
                          expandIconPosition="right"
                          activeKey={
                            storeTimes["wednesday"]?.status === "Open"
                              ? "wednesday"
                              : ""
                          }
                          onChange={() => handleStoreTimeClick("wednesday")}
                          size="small"
                        >
                          <Panel
                            className="store_switch_main_div"
                            header={
                              <div className="switch_panel_header">
                                <Switch
                                  className="categories_switch_"
                                  name="wednesday"
                                  id="wednesday"
                                  value="wednesday"
                                  checked={
                                    storeTimes["wednesday"]?.status === "Open"
                                      ? true
                                      : false
                                  }
                                />
                                <span className="store_profile_weekly_name">
                                  Wednesday
                                </span>
                              </div>
                            }
                            key="wednesday"
                          >
                            <Text>
                              <div className="store_time_picker">
                                <div className="store_deatils_time_picker_contnet_section">
                                  <input
                                    type="time"
                                    name="open_time"
                                    id="open_time"
                                    data_day="wednesday"
                                    value={storeTimes["wednesday"]?.open_time}
                                    onChange={handleStoreTimeChnage}
                                    className="store_time_picker_main_section"
                                  />
                                </div>
                                <span className="store_details_to">to</span>
                                <div className="store_deatils_time_picker_contnet_section">
                                  <input
                                    type="time"
                                    name="close_time"
                                    id="close_time"
                                    data_day="wednesday"
                                    value={storeTimes["wednesday"]?.close_time}
                                    onChange={handleStoreTimeChnage}
                                    className="store_time_picker_main_section"
                                  />
                                </div>
                              </div>
                            </Text>
                          </Panel>
                        </Collapse>

                        <Collapse
                          expandIconPosition="right"
                          activeKey={
                            storeTimes["thursday"]?.status === "Open"
                              ? "thursday"
                              : ""
                          }
                          onChange={() => handleStoreTimeClick("thursday")}
                          size="small"
                        >
                          <Panel
                            className="store_switch_main_div"
                            header={
                              <div className="switch_panel_header">
                                <Switch
                                  className="categories_switch_"
                                  name="thursday"
                                  id="thursday"
                                  value="thursday"
                                  checked={
                                    storeTimes["thursday"]?.status === "Open"
                                      ? true
                                      : false
                                  }
                                />
                                <span className="store_profile_weekly_name">
                                  Thursday
                                </span>
                              </div>
                            }
                            key="thursday"
                          >
                            <Text>
                              <div className="store_time_picker">
                                <div className="store_deatils_time_picker_contnet_section">
                                  <input
                                    type="time"
                                    name="open_time"
                                    id="open_time"
                                    data_day="thursday"
                                    value={storeTimes["thursday"]?.open_time}
                                    onChange={handleStoreTimeChnage}
                                    className="store_time_picker_main_section"
                                  />
                                </div>
                                <span className="store_details_to">to</span>
                                <div className="store_deatils_time_picker_contnet_section">
                                  <input
                                    type="time"
                                    name="close_time"
                                    id="close_time"
                                    data_day="thursday"
                                    value={storeTimes["thursday"]?.close_time}
                                    onChange={handleStoreTimeChnage}
                                    className="store_time_picker_main_section"
                                  />
                                </div>
                              </div>
                            </Text>
                          </Panel>
                        </Collapse>

                        <Collapse
                          expandIconPosition="right"
                          activeKey={
                            storeTimes["friday"]?.status === "Open"
                              ? "friday"
                              : ""
                          }
                          onChange={() => handleStoreTimeClick("friday")}
                          size="small"
                        >
                          <Panel
                            className="store_switch_main_div"
                            header={
                              <div className="switch_panel_header">
                                <Switch
                                  className="categories_switch_"
                                  name="friday"
                                  id="friday"
                                  value="friday"
                                  checked={
                                    storeTimes["friday"]?.status === "Open"
                                      ? true
                                      : false
                                  }
                                />
                                <span className="store_profile_weekly_name">
                                  Friday
                                </span>
                              </div>
                            }
                            key="friday"
                          >
                            <Text>
                              <div className="store_time_picker">
                                <div className="store_deatils_time_picker_contnet_section">
                                  <input
                                    type="time"
                                    name="open_time"
                                    id="open_time"
                                    data_day="friday"
                                    value={storeTimes["friday"]?.open_time}
                                    onChange={handleStoreTimeChnage}
                                    className="store_time_picker_main_section"
                                  />
                                </div>
                                <span className="store_details_to">to</span>
                                <div className="store_deatils_time_picker_contnet_section">
                                  <input
                                    type="time"
                                    name="close_time"
                                    id="close_time"
                                    data_day="friday"
                                    value={storeTimes["friday"]?.close_time}
                                    onChange={handleStoreTimeChnage}
                                    className="store_time_picker_main_section"
                                  />
                                </div>
                              </div>
                            </Text>
                          </Panel>
                        </Collapse>

                        <Collapse
                          expandIconPosition="right"
                          activeKey={
                            storeTimes["saturday"]?.status === "Open"
                              ? "saturday"
                              : ""
                          }
                          onChange={() => handleStoreTimeClick("saturday")}
                          size="small"
                        >
                          <Panel
                            className="store_switch_main_div"
                            header={
                              <div className="switch_panel_header">
                                <Switch
                                  className="categories_switch_"
                                  name="saturday"
                                  id="saturday"
                                  value="saturday"
                                  checked={
                                    storeTimes["saturday"]?.status === "Open"
                                      ? true
                                      : false
                                  }
                                />
                                <span className="store_profile_weekly_name">
                                  Saturday
                                </span>
                              </div>
                            }
                            key="saturday"
                          >
                            <Text>
                              <div className="store_time_picker">
                                <div className="store_deatils_time_picker_contnet_section">
                                  <input
                                    type="time"
                                    name="open_time"
                                    id="open_time"
                                    data_day="saturday"
                                    value={storeTimes["saturday"]?.open_time}
                                    onChange={handleStoreTimeChnage}
                                    className="store_time_picker_main_section"
                                  />
                                </div>
                                <span className="store_details_to">to</span>
                                <div className="store_deatils_time_picker_contnet_section">
                                  <input
                                    type="time"
                                    name="close_time"
                                    id="close_time"
                                    data_day="saturday"
                                    value={storeTimes["saturday"]?.close_time}
                                    onChange={handleStoreTimeChnage}
                                    className="store_time_picker_main_section"
                                  />
                                </div>
                              </div>
                            </Text>
                          </Panel>
                        </Collapse>
                        {error.store_time ? (
                          <p style={{ color: "red" }}>{error.store_time}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Pet Size */}
                  <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label
                      htmlFor="phone"
                      className="all_top_and_bottom_for_input_text"
                    >
                      <span style={{ color: "red" }}>*</span> Size :
                    </label>
                    <Flex vertical gap="middle" className="">
                      <div
                        className="verndor_dashboard_add_edit_button"
                        defaultValue="Y"
                        name="small"
                        id="small"
                        size="large"
                      >
                        <div className="row">
                          <div
                            className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                            onClick={() => handlePetSize("small")}
                          >
                            <div className="vendor_dashbaord_gender_main_section">
                              <label htmlFor="male">Small</label>
                              <input
                                type="radio"
                                id="small"
                                name="small"
                                value="Y"
                                className="form-check-input"
                                disabled={true}
                                checked={petSize?.small === "Y" ? true : false}
                              />
                            </div>
                          </div>
                          <div
                            className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                            onClick={() => handlePetSize("medium")}
                          >
                            <div className="vendor_dashbaord_gender_main_section">
                              <label htmlFor="type_mobile">Medium</label>
                              <input
                                type="radio"
                                id="medium"
                                name="medium"
                                value="Y"
                                className="form-check-input"
                                disabled={true}
                                checked={petSize?.medium === "Y" ? true : false}
                              />
                            </div>
                          </div>
                          <div
                            className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                            onClick={() => handlePetSize("large")}
                          >
                            <div className="vendor_dashbaord_gender_main_section">
                              <label htmlFor="type_mobile">Large</label>
                              <input
                                type="radio"
                                id="large"
                                name="large"
                                value="Y"
                                className="form-check-input"
                                disabled={true}
                                checked={petSize?.large === "Y" ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                        {error.size ? (
                          <p style={{ color: "red" }}>{error.size}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </Flex>
                  </div>

                  {/* Shop Fulfillment type */}
                  {category.includes("Shop") ? (
                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label
                        htmlFor="phone"
                        className="all_top_and_bottom_for_input_text"
                      >
                        Shop Fulfillment Type :
                      </label>
                      <Flex vertical gap="middle" className="">
                        <div
                          className="verndor_dashboard_add_edit_button"
                          defaultValue="Y"
                          name="in_store"
                          id="in_store"
                          size="large"
                        >
                          <div className="row">
                            <div
                              className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handleShopFulfillment("in_store")}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="male">In Store</label>
                                <input
                                  type="radio"
                                  id="in_store"
                                  name="in_store"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={
                                    shopFulfillment?.in_store === "Y"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handleShopFulfillment("dilevery")}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="type_mobile">Delivery</label>
                                <input
                                  type="radio"
                                  id="dilevery"
                                  name="dilevery"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={
                                    shopFulfillment?.dilevery === "Y"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {error.shop ? (
                            <p style={{ color: "red" }}>{error.shop}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </Flex>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Training Fulfillment type */}
                  {category.includes("Training") ? (
                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label
                        htmlFor="phone"
                        className="all_top_and_bottom_for_input_text"
                      >
                        Training Fulfillment Type :
                      </label>
                      <Flex vertical gap="middle" className="">
                        <div
                          className="verndor_dashboard_add_edit_button"
                          defaultValue="Y"
                          name="home"
                          id="home"
                          size="large"
                        >
                          <div className="row">
                            <div
                              className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handleTrainingFulfillment("home")}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="male">At Home</label>
                                <input
                                  type="radio"
                                  id="home"
                                  name="home"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={
                                    trainingFulfillment?.home === "Y"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() =>
                                handleTrainingFulfillment("institute")
                              }
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="type_mobile">
                                  At Institute
                                </label>
                                <input
                                  type="radio"
                                  id="institute"
                                  name="institute"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={
                                    trainingFulfillment?.institute === "Y"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {error.training ? (
                            <p style={{ color: "red" }}>{error.training}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </Flex>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Grooming Fulfillment type */}
                  {category.includes("Grooming") ? (
                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label
                        htmlFor="phone"
                        className="all_top_and_bottom_for_input_text"
                      >
                        Grooming Fulfillment Type :
                      </label>
                      <Flex vertical gap="middle" className="">
                        <div
                          className="verndor_dashboard_add_edit_button"
                          defaultValue="Y"
                          name="in_store"
                          id="in_store"
                          size="large"
                        >
                          <div className="row">
                            <div
                              className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() =>
                                handleGroomingFulfillment("in_store")
                              }
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="male">In Store</label>
                                <input
                                  type="radio"
                                  id="in_store"
                                  name="in_store"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={
                                    groomingFulfillment?.in_store === "Y"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() =>
                                handleGroomingFulfillment("type_mobile")
                              }
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="type_mobile">Mobile</label>
                                <input
                                  type="radio"
                                  id="type_mobile"
                                  name="type_mobile"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={
                                    groomingFulfillment?.type_mobile === "Y"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() =>
                                handleGroomingFulfillment("pick_drop")
                              }
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="female">Pick & Drop</label>
                                <input
                                  type="radio"
                                  id="pick_drop"
                                  name="pick_drop"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={
                                    groomingFulfillment?.pick_drop === "Y"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {error.grooming ? (
                            <p style={{ color: "red" }}>{error.grooming}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </Flex>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Friendly Fulfillment type */}
                  {category.includes("Friendly") ? (
                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label
                        htmlFor="phone"
                        className="all_top_and_bottom_for_input_text"
                      >
                        Friendly Fulfillment Type :
                      </label>
                      <Flex vertical gap="middle" className="">
                        <div
                          className="verndor_dashboard_add_edit_button"
                          defaultValue="Y"
                          name="park"
                          id="park"
                          size="large"
                        >
                          <div className="row">
                            <div
                              className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handleFriendlyFulfillment("park")}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="male">Park</label>
                                <input
                                  type="radio"
                                  id="park"
                                  name="park"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={
                                    friendlyFulfillment?.park === "Y"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() =>
                                handleFriendlyFulfillment("restaurant")
                              }
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="restaurant">Restaurant</label>
                                <input
                                  type="radio"
                                  id="restaurant"
                                  name="restaurant"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={
                                    friendlyFulfillment?.restaurant === "Y"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() => handleFriendlyFulfillment("cafe")}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="cafe">Cafe</label>
                                <input
                                  type="radio"
                                  id="cafe"
                                  name="cafe"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={
                                    friendlyFulfillment?.cafe === "Y"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                              onClick={() =>
                                handleFriendlyFulfillment("beaches")
                              }
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="beaches">Beaches</label>
                                <input
                                  type="radio"
                                  id="Beaches"
                                  name="Beaches"
                                  value="Y"
                                  className="form-check-input"
                                  disabled={true}
                                  checked={
                                    friendlyFulfillment?.beaches === "Y"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {error.friendly ? (
                            <p style={{ color: "red" }}>{error.friendly}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </Flex>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Friendly Fulfillment type */}
                  
                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label
                        htmlFor="phone"
                        className="all_top_and_bottom_for_input_text"
                      >
                        Center Facilities :
                      </label>
                      <textarea
                        className="form-control store_input_field"
                        name="center_facilities"
                        id="center_facilities"
                        onChange={handleChange}
                        value={ADDEDITDATA?.center_facilities || ''}
                      >
                      </textarea>
                      <p
                        style={{ color: "blue" }}
                        className="all_top_and_bottom_for_input_text"
                      >
                        *Note: Please enter facilities as a comma-separated
                        list.
                      </p>
                      {error.center_facilities ? (
                        <p style={{ color: "red" }}>
                          {error.center_facilities}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                </div>

                <div className="inventory_edit_submit_btn">
                  <button
                    type="button"
                    className="btn btn-primary inventory_save_button"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserListEditPage;
