import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import {addQuantity} from "../../controllers/Products/inventory";

const InventoryEditPagePopUp = ({ inventoryPopUp, AddQuantityTogglePopUp, variantId, setVariantId }) => {
  const handleCancel = () => {
    AddQuantityTogglePopUp();
    setVariantId('');
  };
  const [quantity, setQuantity] = useState();
  // const handleDelete = () => {
  //   notification.open({
  //     message: "Notification Title",
  //     description: " Data Send Successfully .",
  //     placement: "topRight",
  //     icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
  //   });
  // };
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };
const handleChnage = (e) =>{
  const {name, value}= e.target;
  setQuantity(value);
}
  const handleSubmit = async () => {
    try {
      if(!quantity){
        notification.open({
          message: "Notification Title",
          description: "Quantity is empty.",
          placement: "topRight",
          icon: <ExclamationCircleOutlined twoToneColor="red" />,
        });
      } else if(!variantId){
        notification.open({
          message: "Notification Title",
          description: " Id not found .",
          placement: "topRight",
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        });
      } else{ 
        const options = {
          id : variantId,
          addQuantity : quantity
        }
        const res = await addQuantity(options);
        if(res.status === true){
          notification.open({
            message: "Notification Title",
            description: " Data Send Successfully .",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          AddQuantityTogglePopUp(); 
        }
      }
    } catch (error) {
      notification.open({
        message: "Notification Title",
        description: "Error add quantity .",
        placement: "topRight",
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      });
    }
  }

  
  return (
    <Dialog
      open={inventoryPopUp}
      onClose={AddQuantityTogglePopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Add Quantity
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form">
                  <div class="form-group">
                    <div class="form-group">
                      <label
                        for="formGroupExampleInput"
                        className="categories_open_popup_label_name"
                      >
                      Add Quantity
                      </label>
                      <input
                        type="number"
                        name="qty"
                        id="qty"
                        value={quantity}
                        onChange={handleChnage}
                        class="form-control categories_select_tg_enter_product"
                        placeholder="Add Quantity"
                      />
                    </div>
                  </div>
                  
                  <div className="inventory_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={handleSubmit}
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
          {/* <DialogActions>
          <Button color="primary">Cancel</Button>
          <Button style={{ color: "red" }}>Delete</Button>
        </DialogActions> */}
        </div>
      </div>
    </Dialog>
  );
};

export default InventoryEditPagePopUp;


