import React, { useState, useEffect, useRef } from "react";
import { TbCategory } from "react-icons/tb";
import { FaPlusSquare } from "react-icons/fa";
import { notification, Select } from "antd";
import { Switch } from "antd";
import { MdDeleteOutline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import TopNavBar from "../../DashBaord/Top_navbar";

import CategoryServiceDeletePopUp from "./ServiceDeletePopUp";
import ServiceAddEditData from "./ServiceAddEditData";
import "../Product List/ProductList.css";

import { ASSETS_BASE_URL } from "../../../config/constants";

import moment from "moment";
import { getPage } from "../../../controllers/common";
import { list, chnageStatus } from "../../../controllers/categories/services";
import SkeltonList from "../../../components/SkeltonEffect/list";

const CategoriesServiceList = () => {
  //Product delete PopUp
  const [deletePopUpCategory, setDeletePopUpCategory] = useState(false);
  /*********************************************************
   *  This function is use to handle delete popup
   *********************************************************/
  const handleProductDeleteToggle = () => {
    setDeletePopUpCategory(!deletePopUpCategory);
  }; //End

  const [showAddEditPopup, setAddEditPopup] = useState(false);
  const [EDITDATA, setEditData] = useState([]);
  /*********************************************************
   *  This function is use to handle Add/Edit Popup
   *********************************************************/
  const handleAddEditData = () => {
    setAddEditPopup(true);
  }; //End
  const [isLoading, setIsLoading] = useState(true);
  const [deteteId, setDeteteId] = useState("");
  const targetRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    filter_by: "",
    search: "",
  }); //End
  const [showRequest, setShowRequest] = useState("");
  const handleShowRequest = (value) => {
    setShowRequest(value);
  }
  /*********************************************************
   *  This function is use to handle notification
   *********************************************************/
  const handleNotification = (status, msg) => {
    notification.open({
      message: status,
      description: msg,
      placement: "topRight",
      duration: 2,
    });
  }; //End
  /*********************************************************
   *  This function is use to fetch category list
   *********************************************************/
  const getList = async () => {
    setListData([]);
    setIsLoading(true);
    const options = {
      type: "",
      condition: {
        ...(filter?.from
          ? {
            createdAt: {
              $gte: moment(
                `${filter?.from?.$y}-${filter?.from?.$M + 1}-${filter?.from?.$D
                }`
              ).format("YYYY-MM-DD"),
              $lte: moment(
                `${filter?.to?.$y}-0${filter?.to?.$M + 1}-${filter?.to?.$D + 1
                }`
              ).format("YYYY-MM-DD"),
            },
          }
          : null),
        ...(filter?.search
          ? { [filter?.filter_by]: { $regex: filter.search, $options: "i" } }
          : null),
        ...(showRequest ? { status: showRequest } : null)
      },
      select: {},
      sort: { _id: -1 },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10,
    };
    const listData = await list(options);
    setListData(listData?.result);
    setIsLoading(false);
    const countOptions = {
      type: "count",
      condition: {},
    };
    const count = await list(countOptions);
    setTotalPages(getPage(count?.result));
    setIsLoading(false);
  }; //End
  /*********************************************************
   *  This function is use to handle chnage status
   *********************************************************/
  const handleStatus = async (item) => {
    const option = {
      url: `categories/services/change-status`,
      postData: {
        id: item?._id,
        status: item.status === "A" ? "I" : "A",
      },
    };
    const res = await chnageStatus(option);
    if (res.status === true) {
      getList();
      handleNotification("Success!", "Status Updated.");
    } else {
      handleNotification("Error!", res?.message);
    }
  }; //End
  /*********************************************************
   *  This function is load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  }, [currentPage, showAddEditPopup, deletePopUpCategory, showRequest, filter]);

  // Read more Read less functionality
  const [showFullTextArray, setShowFullTextArray] = useState([]);
  // Function to toggle Read More/Read Less for a specific card
  const toggleText = (index) => {
    const updatedShowFullTextArray = [...showFullTextArray];
    updatedShowFullTextArray[index] = !updatedShowFullTextArray[index];
    setShowFullTextArray(updatedShowFullTextArray);
  };
  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TopNavBar title="Service Category"></TopNavBar>
        <div className="order_now_card_tilte">
          <div class="card">
            <div class="card-body categories_main_div">
              <div className="categories_tb_select">
                <TbCategory />
                <Select
                  name="show"
                  id="show"
                  className="product_select_tag_rating_review"
                  style={{ width: 120 }}
                  bordered={false}
                  onChange={handleShowRequest}
                  defaultValue={showRequest}
                >
                  <Select.Option value="" label="All" selected={true}>All</Select.Option>
                  <Select.Option value="A" label="Active" >Active</Select.Option>
                  <Select.Option value="I" label="Inactive" >Inactive</Select.Option>
                </Select>
              </div>
              <div className="promotions_filters_div">
                {/* <div className="fa_filter_rating_review">
                  <img
                    src={ASSETS_BASE_URL + "./images/Filter.svg"}
                    alt=""
                    className="user_iconses"
                  />

                  <a href="#" className="filter_btn_invontry_rating_review">
                    Filters
                  </a>
                </div> */}

                {/* <div>
                  <button
                    type="button"
                    class="btn btn-dark"
                    onClick={handleAddEditData}
                  >
                    <FaPlusSquare className="Promotions_faPlus_Squares" />
                    <span className="request_new_categories">Add New</span>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* //Card section */}

        <div class="row">
          {ALLLISTDATA.length > 0 ? (ALLLISTDATA.map((items, index) => (<>
            <div key={index} class="col-md-6 col-lg-4">
              <div class="card-box categories_card_box_for_pet">
                <div class="card-thumbnail">
                  <img
                    src={`${ASSETS_BASE_URL}${items?.image}`}
                    class="img-fluid  categroies_image_servies"
                    alt=""
                  />
                </div>
                <h3>
                  <span className="cotegories_pet_food">{items?.name}</span>
                </h3>
                <p className="cotegories_about_food_description">
                  {showFullTextArray[index]
                    ? items?.details
                    : items?.details.slice(0, 100)}
                  {items?.details.length > 100 && (
                    <span
                      onClick={() => toggleText(index)}
                      className={`read-more-link ${showFullTextArray[index] ? "read-less" : "read-more"
                        }`}
                    >
                      {showFullTextArray[index]
                        ? " Read Less..."
                        : " Read More..."}
                    </span>
                  )}
                </p>
                <div className="catogries_create_status">
                  <div>
                    <span className="cartegories_created_on">Created on</span>
                  </div>
                  <div>
                    <span className="cartegories_created_on_date">
                      {moment(`${items.createdAt}`).format("DD/MM/YYYY")}
                    </span>
                  </div>
                </div>
                <div className="catogries_create__switch">
                  <div>
                    <span className="cartegories_created_on">Status</span>
                  </div>
                  <div>
                    <span className="cartegories_created_on_date">
                      <Switch
                        className="categories_switch_"
                        checked={items.status === "A" ? true : false}
                        onClick={() => handleStatus(items)}
                      />
                    </span>
                  </div>
                </div>
                <div className="catogries_product_create__switch">
                  <div className="product_card_edit_button">
                    <button
                      class="btn btn-danger button_delete_producttt"
                      // onClick={togglePopUp}
                      onClick={() => {
                        setEditData(items);
                        handleAddEditData();
                      }}
                    >
                      <FaEdit /> Edit
                    </button>
                  </div>
                  <div
                    className="product_card_edit_button"
                    onClick={() => {
                      // setDeteteId(items._id);
                      // handleProductDeleteToggle();
                    }}
                  >
                    {/*Services cannot be deleted */}
                    {/* <button class="btn btn-dark button_delete_producttt">
                    <MdDeleteOutline /> Delete
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
          </>
          ))) : isLoading ? <SkeltonList row={10} col={5} /> : <tr>
            <td colspan="5" className="img-center" ><img src='https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg' width='55%' /></td>
          </tr>}
        </div>
        <CategoryServiceDeletePopUp
          id={deteteId}
          deletePopUpCategory={deletePopUpCategory}
          deleteProductToggle={handleProductDeleteToggle}
          setDeteteId={setDeteteId}
        />
        <ServiceAddEditData
          show={showAddEditPopup}
          editdata={EDITDATA}
          setEditData={setEditData}
          setAddEditPopup={setAddEditPopup}
        />
      </div>
    </>
  );
};

export default CategoriesServiceList;
