import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification, Select, Upload } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import "../../ServiceList.css";

import Loading from '../../../../components/Loading/LoadingEffect';
import { ASSETS_BASE_URL } from "../../../../config/constants";
import {ucfirst, commonList} from '../../../../controllers/common';
import { addEditData } from "../../../../controllers/services/serviceController"
import { list as vendorlist} from "../../../../controllers/vendors/vendorsController"
import { chnageStatus } from "../../../../controllers/services/requestController";

const RejectPopup = ({ show, editdata, setEditData, setAddEditPopup }) => {
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };
    
  /*********************************************************
  *  This function is use to handle cancle button and close popup
  *********************************************************/
   const handleCancel = () => {
    setEditData("");
    setFormData("");
    setAddEditPopup(false);
  };
  /*********************************************************
  *  This function is use to handle success notification show
  *********************************************************/
  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data saved Successfully .",
      placement: "topRight",
      duration : 2,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };//End
  /*********************************************************
  *  This function is use to handle error notification show
  *********************************************************/
  const handleErrorMsg = (msg="") => {
    notification.open({
      message: "Opps!",
      description: error?.formError || msg,
      placement: "topRight",
      duration : 2,
      icon: <CloseOutlined  style={{color : "red"}} />,
    });
  };//End
  const [isLoading, setIsLoading] = useState(true);
  const [error, serError] = useState([]);
  const [formData, setFormData] = useState("");
  const [ALLVENDOR, setAllVendor] = useState([]);
  const [ALLSTORE, setAllStore] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);
  const [ALLCATEGORY, setAllCategory] = useState([]);
  const [ALLSUBCATEGORY, setAllSubCategory] = useState([]);
  const [filterSubCat, setFilterSubCat] = useState([]);

  const getCommonListData = async (vendorId) => {
    try{
      setAllStore([]);
      setAllBranch([]);
      setAllCategory([]);
      setAllSubCategory([]);
      const options ={
        vendorId : vendorId,
        list : ["service_category","store","branch"],
        serviceCat : { select : { name : true} },
        serviceSubCat : { select : { category : true, name : true } },
        store : { select : { shop_name : true } },
        branch : { select : { storeData : true, branch_name : true } }
      }
      const res =await commonList(options);
      if(res?.status === true){
        setAllStore(res?.result?.store);
        setAllBranch(res?.result?.branch);
        setAllCategory(res?.result?.serviceCat);
        setAllSubCategory(res?.result?.serviceSubCat);

        if(editdata?.branch){
          const filterArray = res?.result?.branch.filter(val=> val.storeData == editdata?.store?._id );
          setFilterBranch(filterArray);
        }
        if(editdata.subcategory){
          const filterArray = res?.result?.serviceSubCat.filter(val=> val.category == editdata?.category?._id )
          setFilterSubCat(filterArray);
        }
      }
      setIsLoading(false)
    } catch (error){
      setIsLoading(false)
    }
  }

  /*********************************************************
  *  This function is use to handle input chnage and set in state
  *********************************************************/
  const handleChnage = (e)=>{
    const {name, value} = e.target;
    if(name === 'vendor'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      // alert(value)
      getCommonListData(value);
      setFilterBranch([]);
      setFilterSubCat([]);
    } else if(name === 'store'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      const filterArray = ALLBRANCH.filter(val=> val.storeData == value )
      setFilterBranch(filterArray);
      setFilterSubCat([]);
    } else if(name === 'category'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      const filterArray = ALLSUBCATEGORY.filter(val=> val.category == value )
      setFilterSubCat(filterArray);
    } else{
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }))
    }
    serError((pre)=>({
      ...pre,
      [e.target.name] : ""
    }))
  }; //End
  /*********************************************************
  *  This function is use to handle form submit
  *********************************************************/
  const handleSubmit = async (e)=>{
    e.preventDefault();
    setIsLoading(true);
    const form = new FormData(e.target);
    form.append("image_url", editdata.image);
    form.append("ipAddress", sessionStorage.getItem('IP_ADDRESS'));

    form.append("store",editdata?.store?._id || "");
    form.append("branch", editdata?.branch?._id || "");
    form.append("category",editdata?.category?._id || "");
    const isValidate = validateFormData(form)
    if(isValidate){
      const res = await addEditData(form);
      if(res.status === true){
        const options = {
          id : editdata._id,
          status : 'C'
        }
        const result = await chnageStatus(options);
        if(result.status === true){
          setIsLoading(false)
          setFormData("");
          handleSuccessMsg();
          handleCancel();
        }else{
          setIsLoading(false)
          setFormData("");
          handleErrorMsg('Service created by request status not chnage.');
          handleCancel();
        }
      }else{
        setIsLoading(false)
        serError((pre)=>({
          ...pre,
          formError : res?.message
        }))
        handleErrorMsg();
      }
    }else{
      setIsLoading(false)
      handleErrorMsg('Please fill required fields.');
    }
  } //End
  /*********************************************************
  *  This function is use to validate form data before submit
  *********************************************************/
  const validateFormData = (formData) => {
    console.log('details',formData.get('details'));
    if(formData.get('vendor') === 'undefined' || formData.get('vendor') === null || formData.get('vendor').trim() === ''){
      serError((preError)=>({
        ...preError,
        vendor : "Vendor is required"
      }));
      return false;
    } else if(formData.get('store') === 'undefined' || formData.get('store') === null || formData.get('store').trim() === ''){
      serError((preError)=>({
        ...preError,
        store : "Store is required"
      }));
      return false;
    } else if(formData.get('branch') === 'undefined' || formData.get('branch') === null || formData.get('branch').trim() === ''){
      serError((preError)=>({
        ...preError,
        branch : "Branch is required"
      }));
      return false;
    } else if(formData.get('category') === 'undefined' || formData.get('category') === null || formData.get('category').trim() === ''){
      serError((preError)=>({
        ...preError,
        category : "Category is required"
      }));
      return false;
    // } else if(formData.get('subcategory') === 'undefined' || formData.get('subcategory') === null || formData.get('subcategory').trim() === ''){
    //   serError((preError)=>({
    //     ...preError,
    //     subcategory : "Sub category is required"
    //   }));
    //   return false;
    } else if(formData.get('name') === 'undefined' || formData.get('name') === null || formData.get('name').trim() === ''){
      serError((preError)=>({
        ...preError,
        name : "Service name is required"
      }));
      return false;
    } else if(formData.get('details') === 'undefined' || formData.get('details') === null || formData.get('details').trim() === ''){
      serError((preError)=>({
        ...preError,
        details : "Service details is required"
      }));
      return false;
    } else if(formData.get('price') === 'undefined' || formData.get('price') === null || formData.get('price').trim() === ''){
      serError((preError)=>({
        ...preError,
        price : "Price is required"
      }));
      return false;
    } else if(!editdata?._id && formData.get('image') === 'null'){
      serError((preError)=>({
        ...preError,
        image : "Image is required"
      }));
      return false;
    }else {
      return true;
    }
  }; //End

  useEffect(()=>{
    
  },[show]);
  return ( 
    <Dialog
      open={show}
      onClose={handleCancel}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="categories_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="categories_popup_title_heading"
            >
              Request Service Details
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                
              </div>
            </div>
          </Typography>
          {/* <DialogActions>
          <Button color="primary">Cancel</Button>
          <Button style={{ color: "red" }}>Delete</Button>
        </DialogActions> */}
        </div>
        <Loading
          isLoading={isLoading}
        />
      </div>
    </Dialog>
  );
};

export default RejectPopup;
