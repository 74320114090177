import { API_ADMIN_URL } from "../../config/constants";
import { postRequest } from "../API";


/*********************************************************
* Function Name : List
* Description   : Get list of all banner
* By            : Noor ALam
* Date          : 01-05-2024 
*********************************************************/
export const list = async (options) => {
    try {
        const params = {
            url: `${API_ADMIN_URL}cms/banner/list`,
            postData: options
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}


/*********************************************************
* Function Name : addEditData
* Description   : Get list of addEditData
* By            : Noor ALam
* Date          : 01-05-2024 
*********************************************************/
export const addEditData = async (options) => {
    try {
        const params = {
            url: `${API_ADMIN_URL}${options.url}`,
            postData: options.postData
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return { status: true, result: res?.data?.response?.result};
        }else{
            return {status: false, message: res?.response?.data?.statusMessage}
        }
    }
    catch (error) {
     console.log("Error", error);
     return {status: false, mesasge: "Under Maintenance, Please try after some time."}
    }
};//End of Function


/*********************************************************
* Function Name : changeStatus
* Description   : Get list of changeStatus banner
* By            : Noor ALam
* Date          : 01-05-2024 
*********************************************************/
export const changeStatus = async (options) => {
    console.log("Options", options)
    try{
    const {url, postData ={}} = options;
    const params = {
      url: `${API_ADMIN_URL}${url}`,
      postData: {
        ...postData, 
        ipAddress: sessionStorage.getItem("IP_ADDRESS")
      }
    }
    const res = await postRequest(params);
    if(res.status === true || res.status === 200){
        return {status: true, result: res?.data?.response?.result};
    }else{
        return {status: false, result: res?.response?.data?.statusMessage};
    }
    }catch(error){
        console.log("Error", error);
        return {status: false, message: "Under Maintenance, Please try after some time"};
    }
}


/*********************************************************
* Function Name : List
* Description   : Get list of all banner
* By            : Noor ALam
* Date          : 01-05-2024 
*********************************************************/
export const deleteBanner = async (options) => {
    try{
    const { url, postData = {}} = options;
    const params = {
        url: `${API_ADMIN_URL}${url}`,
        postData: {
            ...postData,
            ipAddress: sessionStorage.getItem('IP_ADDRESS')
        }
    }
    const res = await postRequest(params);
    if(res.status === true || res.status === 200){
        return {status: true, result: res?.data?.response?.result};
    }else{
        return {status: false, message: res?.response?.data?.statusMessage}
    }
    }catch(error){
        console.log("ERORR", error);
        return {status: false, message: "Under Maintenance, Please try after some time"}
    }
}