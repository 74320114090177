import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification, Select, Upload } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import "../../ServiceList.css";
import moment from 'moment'; 
import Loading from '../../../../components/Loading/LoadingEffect';
import { ASSETS_BASE_URL } from "../../../../config/constants";
import {ucfirst, commonList} from '../../../../controllers/common';
import { addEditRequestAdoption, chnageAdoptionRequestStatus } from "../../../../controllers/services/serviceController"
import { list as vendorlist} from "../../../../controllers/vendors/vendorsController"
// import { chnageStatus } from "../../../controllers/services/requestController";

const RejectPopup = ({ show, editdata, setEditData, setAddEditPopup }) => {
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };
    
  /*********************************************************
  *  This function is use to handle cancle button and close popup
  *********************************************************/
   const handleCancel = () => {
    setEditData("");
    setFormData("");
    setAddEditPopup(false);
  };
  /*********************************************************
  *  This function is use to handle success notification show
  *********************************************************/
  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data saved Successfully .",
      placement: "topRight",
      duration : 2,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };//End
  /*********************************************************
  *  This function is use to handle error notification show
  *********************************************************/
  const handleErrorMsg = (msg="") => {
    notification.open({
      message: "Opps!",
      description: error?.formError || msg,
      placement: "topRight",
      duration : 2,
      icon: <CloseOutlined  style={{color : "red"}} />,
    });
  };//End
  const [isLoading, setIsLoading] = useState(true);
  const [error, serError] = useState([]);
  const [formData, setFormData] = useState("");
  const [ALLVENDOR, setAllVendor] = useState([]);
  const [ALLSTORE, setAllStore] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);
  const [ALLCATEGORY, setAllCategory] = useState([]);
  const [ALLSUBCATEGORY, setAllSubCategory] = useState([]);
  const [filterSubCat, setFilterSubCat] = useState([]);

/*********************************************************
  *  This function is use to get all vendor list
  *********************************************************/
  const getVendorList = async () => {
    try {
      const options ={
        condition : { status : "A"},
        select : {
          vendor_id : false, 
          vendor_email : false,
          creation_date : false,
          createdAt : false,
          creation_ip : false,
          vendor_address : false,
          vendor_city : false,
          vendor_country : false,
          vendor_image : false,
          vendor_otp : false,
          vendor_phone : false,
          vendor_pincode : false,
          vendor_state : false,
          created_by : false,
          update_date : false,
          update_ip : false,
          updatedAt : false,
          updated_by : false,
          vendor_commission : false,
          vendor_country_code : false,
          vendor_gender : false,
          vendor_revenue : false,
          vendor_street : false
        },
        sort : {vendor_first_name : 1},
        skip : 0,
        limit : 0
      }
      const res = await vendorlist(options);
      if(res.status === true){
        setAllVendor(res?.result)
      }else{
        setAllVendor([]);
      }
    } catch (error) {
      setAllVendor([]);
    }
  };

  const getCommonListData = async (vendorId) => {
    try{
      setAllStore([]);
      setAllBranch([]);
      setAllCategory([]);
      setAllSubCategory([]);
      const options ={
        vendorId : vendorId,
        list : ["service_category","store","branch"],
        serviceCat : { select : { name : true} },
        serviceSubCat : { select : { category : true, name : true } },
        store : { select : { shop_name : true } },
        branch : { select : { storeData : true, branch_name : true } }
      }
      const res =await commonList(options);
      if(res?.status === true){
        setAllStore(res?.result?.store);
        setAllBranch(res?.result?.branch);
        setAllCategory(res?.result?.serviceCat);
        setAllSubCategory(res?.result?.serviceSubCat);

        if(editdata?.branch){
          const filterArray = res?.result?.branch.filter(val=> val.storeData == editdata?.store?._id );
          setFilterBranch(filterArray);
        }

      }
      setIsLoading(false)
    } catch (error){
      setIsLoading(false)
    }
  }

  /*********************************************************
  *  This function is use to handle input chnage and set in state
  *********************************************************/
  const handleChnage = (e)=>{
    const {name, value} = e.target;
    if(name === 'vendor'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      setEditData((pre)=>({
        ...pre,
        [name] : value
      }))
      // alert(value)
      getCommonListData(value);
      setFilterBranch([]);
      setFilterSubCat([]);
    } else if(name === 'store'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      setEditData((pre)=>({
        ...pre,
        [name] : value
      }))
      const filterArray = ALLBRANCH.filter(val=> val.storeData == value )
      setFilterBranch(filterArray);
      setFilterSubCat([]);
    } else if(name === 'category'){
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }));
      setEditData((pre)=>({
        ...pre,
        [name] : value
      }))
      // const filterArray = ALLSUBCATEGORY.filter(val=> val.category == value )
      // setFilterSubCat(filterArray);
    } else{
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }))
      setEditData((pre)=>({
        ...pre,
        [name] : value
      }))
    }
    serError((pre)=>({
      ...pre,
      [e.target.name] : ""
    }))
  }; //End
  /*********************************************************
  *  This function is use to handle form submit
  *********************************************************/
  const handleSubmit = async (e)=>{
    e.preventDefault();
    setIsLoading(true);
    const form = new FormData(e.target);
    form.append("image", editdata.image);
    form.append("gender", editdata.gender);
    form.append('branch',editdata?.branch?._id||"");
    form.append("ipAddress", sessionStorage.getItem('IP_ADDRESS'));
    const isValidate = validateFormData(form)

    if(isValidate){
      const res = await addEditRequestAdoption(form);
      if(res.status === true){
        const options = {
          id : editdata._id,
          status : 'C'
        }
        const result = await chnageAdoptionRequestStatus(options);
        if(result.status === true){
          setIsLoading(false)
          setFormData("");
          handleSuccessMsg();
          handleCancel();
        }else{
          setIsLoading(false)
          setFormData("");
          handleErrorMsg('Service created by request status not chnage.');
          handleCancel();
        }
      }else{
        setIsLoading(false)
        serError((pre)=>({
          ...pre,
          formError : res?.message
        }))
        handleErrorMsg();
      }
    }else{
      setIsLoading(false)
      handleErrorMsg('Please fill required fields.');
    }
  } //End
  /*********************************************************
  *  This function is use to validate form data before submit
  *********************************************************/
  const  validateFormData = (formData) => {
    console.log('details',formData.get('details'));
    if(formData.get('vendor') === 'undefined' || formData.get('vendor') === null || formData.get('vendor').trim() === ''){
      serError((preError)=>({
        ...preError,
        vendor : "Vendor is required"
      }));
      return false;
    } else if(formData.get('store') === 'undefined' || formData.get('store') === null || formData.get('store').trim() === ''){
      serError((preError)=>({
        ...preError,
        store : "Store is required"
      }));
      return false;
    } else if(formData.get('branch') === 'undefined' || formData.get('branch') === null || formData.get('branch').trim() === ''){
      serError((preError)=>({
        ...preError,
        branch : "Branch is required"
      }));
      return false;
    } else if(formData.get('pet_name') === 'undefined' || formData.get('pet_name') === null || formData.get('pet_name').trim() === ''){
      serError((preError)=>({
        ...preError,
        pet_name : "petName is required"
      }));
      return false;
    } else if(formData.get('description') === 'undefined' || formData.get('description') === null || formData.get('description').trim() === ''){
      serError((preError)=>({
        ...preError,
        description : "Description is required"
      }));
      return false;
    } else if(formData.get('breed') === 'undefined' || formData.get('breed') === null || formData.get('breed').trim() === ''){
      serError((preError)=>({
        ...preError,
        breed : "breed name is required"
      }));
      return false;
    } else if(formData.get('mobile_no') === 'undefined' || formData.get('mobile_no') === null || formData.get('mobile_no').trim() === ''){
      serError((preError)=>({
        ...preError,
        mobile_no : "mobile_no is required"
      }));
      return false;
    } else if(formData.get('gender') === 'undefined' || formData.get('gender') === null || formData.get('gender').trim() === ''){
      serError((preError)=>({
        ...preError,
        gender : "Service details is required"
      }));
      return false;
    } else if(!editdata?._id && formData.get('image') === 'null'){
      serError((preError)=>({
        ...preError,
        image : "Image is required"
      }));
      return false;
    }else {
      return true;
    }
  }; //End

  ////Adoption radio
  const handleTypeClick = (name) => {
    try {
      setFormData((pre) => ({
        ...pre,
        gender: name === "male" ? "male" : "female",
      }));
      setEditData((pre) => ({
        ...pre,
        gender: name === "male" ? "male" : "female",
      }));
      serError((preError) => ({
        ...preError,
        type: ""
      }));
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(()=>{
    getVendorList();
    if(editdata?._id){
      getCommonListData(editdata?.vendor)
    }
  },[show]);
  return ( 
    <Dialog
      open={show}
      onClose={handleCancel}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="categories_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="categories_popup_title_heading"
            >
              Adoption Request Details
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form" onSubmit={handleSubmit}>
                  <div class="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="vendor" className="categories_open_popup_label_name"> Vendor </label>
                      <select id="vendor" name="vendor" onChange={handleChnage} class="form-control store_input_field">
                        <option value="">Select a vendor</option>
                        {ALLVENDOR && ALLVENDOR.map((items, index)=>(
                          <option 
                            key={index} 
                            value={items._id} 
                            selected={editdata?.vendor === items._id ? true:false}>
                            {`${ucfirst(items.vendor_first_name)} ${ucfirst(items.vendor_last_name)}`}
                          </option>
                        ))}
                      </select>
                      {error?.vendor?<p style={{color:"red"}}>{error.vendor}</p>:""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="store" className="categories_open_popup_label_name"> Store </label>
                      <select id="store" name="store" onChange={handleChnage} class="form-control store_input_field">
                        <option value="">Select a store</option>
                        {ALLSTORE && ALLSTORE.map((items, index)=>(
                          <option 
                            key={index} 
                            value={items._id}
                            selected={editdata?.store?._id === items._id ? true:false}
                          >
                            {`${ucfirst(items.shop_name)}`}
                          </option>
                        ))}
                      </select>
                      {error?.store?<p style={{color:"red"}}>{error.store}</p>:""}
                    </div>

                    {/* <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="branch" className="categories_open_popup_label_name"> Branch </label>
                      <select id="branch" name="branch" onChange={handleChnage} class="form-control store_input_field">
                        <option value="">Select a branch</option>
                        {filterBranch && filterBranch.map((items, index)=>(
                          <option 
                            key={index} 
                            value={items._id}
                            selected={editdata?.branch?._id === items._id ? true:false}
                          >
                            {`${ucfirst(items.branch_name)}`}
                          </option>
                        ))}
                      </select>
                      {error?.branch?<p style={{color:"red"}}>{error.branch}</p>:""}
                    </div> */}

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="name" className="categories_open_popup_label_name"> Pet Name </label>
                      <input
                        type="text"
                        class="form-control store_input_field"
                        id="pet_name"
                        placeholder="Enter pet name"
                        name="pet_name"
                        value={formData?.pet_name?formData?.pet_name:editdata?.pet_name}
                        onChange={handleChnage}
                      />
                      {error?.pet_name?<p style={{color:"red"}}>{error.pet_name}</p>:""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="description" className="categories_open_popup_label_name"> Description </label>
                      <textarea class="form-control store_input_field"
                        id="description"
                        placeholder="Enter Description"
                        name="description"
                        onChange={handleChnage}>{formData?.description?formData?.description:editdata?.description}</textarea>
                      {error?.description?<p style={{color:"red"}}>{error.description}</p>:""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label htmlFor="about" className="categories_open_popup_label_name">About</label>
                      <textarea
                        class="form-control store_input_field"
                        id="about"
                        placeholder="Enter about the dog"
                        name="about"
                        onChange={handleChnage}
                      >
                      {formData?.about ? formData?.about : editdata?.about}
                      </textarea>
                      {error?.about ? <p style={{ color: "red" }}>{error.about}</p> : ""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="price" className="categories_open_popup_label_name"> Breed </label>
                      <input
                        type="text"
                        class="form-control store_input_field"
                        id="breed"
                        placeholder="Enter breed"
                        name="breed"
                        value={formData?.breed?formData?.breed:editdata?.breed}
                        onChange={handleChnage}
                      />
                      {error?.breed?<p style={{color:"red"}}>{error.breed}</p>:""}
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="price" className="categories_open_popup_label_name"> Mobile Number </label>
                      <input
                        type="text"
                        class="form-control store_input_field"
                        id="mobile_no"
                        placeholder="Enter mobile number"
                        name="mobile_no"
                        value={formData?.mobile_no?formData?.mobile_no:editdata?.mobile_no}
                        onChange={handleChnage}
                      />
                      {error?.mobile_no?<p style={{color:"red"}}>{error.mobile_no}</p>:""}
                    </div>

                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label for="gender" className="categories_open_popup_label_name"> Gender </label>
                      <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="gender" id="gender" size="large" >
                        <div className="row">
                          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" onClick={() => handleTypeClick('male')}>
                            <div className="vendor_dashbaord_gender_main_section">
                              <label htmlFor="parks">Male</label>
                              <input
                                type="radio"
                                id="male"
                                name="gender"
                                value="Y"
                                className="form-check-input"
                                disabled={true}
                                checked={formData?.gender === 'male' ? true : false || editdata.gender === 'male' }
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" onClick={() => handleTypeClick('female')}>
                            <div className="vendor_dashbaord_gender_main_section">
                              <label htmlFor="type_mobile">Female</label>
                              <input
                                type="radio"
                                id="female"
                                name="gender"
                                value="Y"
                                className="form-check-input"
                                disabled={true}
                                checked={formData?.gender === 'female' ? true : false || editdata.gender === 'female' ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {error?.type ? <p style={{ color: "red" }}>{error.type}</p> : ""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label htmlFor="dob" className="categories_open_popup_label_name">Date of Birth </label>
                      <input
                        type="date"
                        class="form-control store_input_field"
                        id="dob"
                        placeholder="Enter mobile number"
                        name="dob"
                        value={formData?.dob ? moment(formData?.dob).format('YYYY-MM-DD') : moment(editdata?.dob).format('YYYY-MM-DD')}
                        onChange={handleChnage}
                      />
                      {error?.dob ? <p style={{ color: "red" }}>{error.dob}</p> : ""}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label className="categories_open_popup_label_name" >Image</label>
                      <div className="inventory_upload_file_main_div">
                        <img src={`${ASSETS_BASE_URL}${editdata?.image}`} width={ `100px`}/>
                      </div>
                    </div>

                  </div>

                  <div className="product_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="btn btn-danger "
                    >
                      {`Save & Complete`}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
          {/* <DialogActions>
          <Button color="primary">Cancel</Button>
          <Button style={{ color: "red" }}>Delete</Button>
        </DialogActions> */}
        </div>
        <Loading
          isLoading={isLoading}
        />
      </div>
    </Dialog>
  );
};

export default RejectPopup;
