import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification, Upload, Space } from "antd";
import Loading from "../../components/Loading/LoadingEffect";
import {
  deleteNotifications,
  sendNotifications,
} from "../../controllers/notifications/notificationsControllers";
import { list as userlist } from "../../controllers/users/usersControllet";
import { InfoCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import Select from "react-select";
import { beforeUpload } from "../../controllers/common";
const NotificationAddEditPopUp = ({
  NotificationsPopUpmodal,
  notificationToggleViewPopUp,
  viewData,
  setAddEditData,
}) => {
  const handleCancel = () => {
    setAddEditData("");
    notificationToggleViewPopUp();
  };
  const [userAddEditData, setuserAddEditData] = useState([]);

  const handleChangeUser = (value) => {
    setuserAddEditData(value);
    setErrors((preError) => ({
      ...preError,
      product_category: "",
    }));
  };

  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [USER, setUSER] = useState([]);
  const [imageList, setImageList] = useState("");
  const [formUserData, setFormUserData] = useState("");

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const form = new FormData(e.target);
      // console.log("res form", form)
      form.append("image", imageList ? imageList : null);
      form.append("userList", formUserData || "");
      // form.append("message", formData?.message || 'N');
      const isValidate = validateFormData(form);
      console.log("isValidate", isValidate);

      const res = await sendNotifications(form);
      // console.log("optionsklhdf", options)
      if (res.status === true) {
        notification.open({
          message: "User Notifiactions deleted successfully.",
          description: `Notifiactions delete successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        // setDeleteId("");
        notificationToggleViewPopUp();
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      console.log("error", error);
      notification.open({
        message: "Opps!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
  };

  /*********************************************************
   *  This function is use to validate form data before submit
   *********************************************************/
  const validateFormData = (formData) => {
    console.log("userList", formData.get("userList"));
    if (
      formData.get("title") === "undefined" ||
      formData.get("title") === null ||
      formData.get("title").trim() === ""
    ) {
      setErrors((preError) => ({
        ...preError,
        title: "Title is required",
      }));
      return false;
    } else if (
      formData.get("message") === "undefined" ||
      formData.get("message") === null ||
      formData.get("message").trim() === ""
    ) {
      setErrors((preError) => ({
        ...preError,
        message: "message is required",
      }));
      return false;
    } else {
      return true;
    }
  }; //End

  /*********************************************************
   *  This function is use to handle image object
   *********************************************************/
  const handleImageChnage = (e) => {
    setImageList(e.fileList[0].originFileObj);
  };

  /*********************************************************
   *  This function is use to get all vendor list
   *********************************************************/
  const getUserList = async () => {
    try {
      const userWhere = {
        url: "accounts/users/list",
        postData: {
          condition: { status: "A" },
        },
      };

      const userList = await userlist(userWhere);
      // console.log("userList", userList)
      if (userList && userList.status && userList.result) {
        const user = userList.result.map((item, key) => ({
          value: item._id,
          label: item.name || `New User_${key}`,
        }));
        setUSER(user);
      } else {
        setUSER([]);
      }
    } catch (error) {
      setUSER([]);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);
  //  console.log('USER',USER);
  const handleUserList = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value).join(",")
      : "";
    setFormUserData(values);
  };
  return (
    <Dialog
      open={NotificationsPopUpmodal}
      onClose={notificationToggleViewPopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Send Notifications
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form
                  className="categories_open_popup_form"
                  onSubmit={handleSubmit}
                >
                  <div className="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="vendor"
                        className="all_lable_for_vendor_dashbaord"
                      >
                        <span style={{ color: "red" }}>*</span> User:{" "}
                      </label>
                      <Space style={{ width: "100%" }} direction="vertical">
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          options={USER}
                          onChange={handleUserList}
                          className="notification_select_item_main"
                        />
                      </Space>
                      {errors?.vendor ? (
                        <p style={{ color: "red" }}>{errors.vendor}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data input">
                      <label
                        for="title"
                        className="all_lable_for_vendor_dashbaord   "
                      >
                        <span style={{ color: "red" }}>*</span>Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        // value={viewData?.title}
                        class="form-control "
                        placeholder="Enter title"
                      />
                      {errors?.title ? (
                        <p className="text-danger">{errors.title}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label
                        for="short_description"
                        className=" all_lable_for_vendor_dashbaord  "
                      >
                        <span style={{ color: "red" }}>*</span>Message
                      </label>
                      <textarea
                        name="message"
                        id="message"
                        class="form-control categories_select_tg_enter_product"
                        placeholder="Enter message."
                      >
                        {/* {viewData?.message} */}
                      </textarea>
                      {errors?.message ? (
                        <p className="text-danger">{errors.message}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label className="all_lable_for_vendor_dashbaord   ">
                        Image
                      </label>
                      <div className="inventory_upload_file_main_div">
                        <Upload
                          onChange={handleImageChnage}
                          beforeUpload={beforeUpload}
                          name="image"
                          maxCount="1"
                          listType="picture-card"
                          className="product_inventory_edit_upload"
                        >
                          <button
                            className="inventroy_edit_page_antd_uplaod_button"
                            type="button"
                          >
                            <div className="inventroy_edit_page_antd_choose_file">
                              Choose File
                            </div>
                          </button>
                        </Upload>
                      </div>
                      <p style={{ color: "blue" }} className="all_lebel_margin_top_main_div  ">
                        *Note: Please choose an image of JPEG/JPG/PNG format
                        file.
                      </p>
                      {errors?.image ? (
                        <p style={{ color: "red" }}>{errors.image}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div></div>

                    <div className="inventory_open_popup_action_btn">
                      <button
                        type="button"
                        class="btn btn-light categories_cancel_button"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button type="Submit" class="btn btn-danger ">
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
        </div>
        <Loading isLoading={loading} />
      </div>
    </Dialog>
  );
};

export default NotificationAddEditPopUp;
