import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import { notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const BookingDeletePopup = ({ popUpOpen, togglePopUp }) => {
  const handleCancel = () => {
    togglePopUp();
  };

  const handleDelete = () => {
    notification.open({
      message: "Notification Title",
      description: " Data Deleted Successfully .",
      placement: "topRight",
      icon: <DeleteOutlined style={{ color: "red" }} />,
    });
  };

  return (
    <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirm
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUp}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Do you want to remove this item?</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} style={{ color: "red" }}>
            Delete
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default BookingDeletePopup;
