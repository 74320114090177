import React, {useState, useEffect} from "react";
import "./main_dashboard.css";
import Top_navbar from "./Top_navbar";
import Recent_order from "./Recent_order";
import { Select, Skeleton } from "antd";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {getDashboardData} from '../../controllers/users/usersControllet';



const Dashboard = () => {
  // User's Count Section
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setuserDate] = useState('');
  const [totaluser, setTotalUser] = useState('');
  const [orderData, setOrderDate] = useState('');
  const [revenueData, setRevenueDate] = useState('');
  const [salesData, setSalesDate] = useState('');
  const [ordersDetails, setOrdersDetails] = useState('');

  const [TOTALUSERSCOUNT, setTotalUsersCount] = useState('')
  const [TOTALORDERSCOUNT, setTotalordersCount] = useState('')
  const [TOTALREVENUECOUNT, setTotalRevenueCount] = useState('')
  const [TOTALSALECOUNT, setTotalSaleCount] = useState('')

  const [usersShowRequest, setusersShowRequest] = useState("");
  
  const handleUserShowRequest =async (value) => {
    if(value === 'Active'){
      setusersShowRequest("A");
      const activeUser = userData.find(item => item.status === "A")?.count;
      setTotalUsersCount(activeUser || 0);
    } else if(value === 'Inactive'){
      setusersShowRequest("I");
      const inactiveUser = userData.find(item => item.status === "I")?.count;
      setTotalUsersCount(inactiveUser || 0);
    } else if(value === 'Delete'){
      setusersShowRequest("D");
      const deletedUser = userData.find(item => item.status === "D")?.count;
      setTotalUsersCount(deletedUser  || 0);
    } else{
      setusersShowRequest("");
      setTotalUsersCount(totaluser);
    }
  }
  const handleOrdersShowRequest =async (value) => {
    if(value === 'all'){
      setTotalordersCount(orderData.totalOrder);
    } else {
      setTotalordersCount(orderData[value]);
    }
  }
  const handleRevenueShowRequest =async (value) => {
    if(value === 'all'){
      setTotalRevenueCount(revenueData.totalRevenue);
    } else {
      setTotalRevenueCount(revenueData[value]);
    }
  }
  const handleSaleShowRequest =async (value) => {
    if(value === 'all'){
      setTotalSaleCount(salesData.totalSale);
    } else {
      setTotalSaleCount(salesData[value]);
    }
  }
 

  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await getDashboardData();
      setuserDate(res?.users || '');
      setTotalUsersCount(res?.totalUser || 0)
      setTotalUser(res?.totalUser || 0)
  
      setOrderDate(res?.ordersCount || '')
      setTotalordersCount(res?.ordersCount.totalOrder || 0);
  
      setRevenueDate(res?.revenue || '');
      setTotalRevenueCount(res?.revenue?.totalRevenue || 0)
  
      setSalesDate(res?.sales || '');
      setTotalSaleCount(res?.sales?.totalSale || 0);
  
      setOrdersDetails(res?.ordersList || [{shop : [{order_seq_id : 'No Data Found'}]}] );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    // console.log('res',res);
  }
  useEffect(()=>{
    getData();
  }, [])
 
  return (
    <>
      <Top_navbar></Top_navbar>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 px-0">
            <div className="transaction_form">
              <div className="transaction_Card margin-right">
                <div className="transction_main_Card ">
                  <h1>Users</h1>
                  <div>
                    <Select
                      defaultValue="All"
                      className="admin_dashboard_select"
                      style={{
                        width: 90,
                      }}
                      bordered={false}
                      onChange={handleUserShowRequest}
                      options={[
                        {
                          value: "All",
                          label: "All",
                          className:"admin_dashboard_select"
                        },
                        {
                          value: "Active",
                          label: "Active",
                          className:"admin_dashboard_select"
                        },
                        {
                          value: "Inactive",
                          label: "Inactive",
                        },
                        {
                          value: "Delete",
                          label: "Delete",
                        },
                      ]}
                    />
                  </div>
                </div>

                <p className="presentation">
                  Represents total number of registered <br />
                  users till date.
                </p>
                {TOTALUSERSCOUNT !== "" ? (
                  <p className="sales_text">{TOTALUSERSCOUNT}</p>
                  ):(
                    <Skeleton loading={true} active paragraph={{ rows: 0 }} />
                  )}
              </div>

              <div className="transaction_Card margin-right">
                <div className="transction_main_Card">
                  <h1>Orders/Bookings</h1>
                  <div>
                    <Select
                      defaultValue="All"
                      style={{
                        width: 90,
                      }}
                      bordered={false}
                      onChange={handleOrdersShowRequest}
                      options={[
                        {
                          value: "all",
                          label: "All",
                        },
                        {
                          value: "shop",
                          label: "Shop",
                        },
                        {
                          value: "grooming",
                          label: "Grooming",
                        },
                        {
                          value: "boarding",
                          label: "Boarding",
                        },
                        {
                          value: "veterinary",
                          label: "Veterinary",
                        },
                        {
                          value: "training",
                          label: "Training",
                        },
                        {
                          value: "daycare",
                          label: "Day care",
                        }
                      ]}
                    />
                  </div>
                </div>
                <p className="presentation">
                  Represents total number of Orders/Booking till date.
                </p>
                {TOTALORDERSCOUNT !== "" ? (
                  <p className="sales_text">{TOTALORDERSCOUNT}</p>
                ):(
                  <Skeleton loading={true} active paragraph={{ rows: 0 }} />
                )}
              </div>

              <div className="transaction_Card margin-right">
                <div className="transction_main_Card">
                  <h1>Revenue</h1>
                  <div>
                    <Select
                      defaultValue="All"
                      style={{
                        width: 90,
                      }}
                      bordered={false}
                      onChange={handleRevenueShowRequest}
                      options={[
                        {
                          value: "all",
                          label: "All",
                        },
                        {
                          value: "shop",
                          label: "Shop",
                        },
                        {
                          value: "grooming",
                          label: "Grooming",
                        },
                        {
                          value: "boarding",
                          label: "Boarding",
                        },
                        {
                          value: "veterinary",
                          label: "Veterinary",
                        },
                        {
                          value: "training",
                          label: "Training",
                        },
                        {
                          value: "daycare",
                          label: "Day care",
                        }
                      ]}
                    />
                  </div>
                </div>
                <p className="presentation">
                  Represents total revenue generated in <br/> the past 6 months.
                </p>
                {TOTALREVENUECOUNT !== "" ? (
                  <p className="sales_text">{`AED ${TOTALREVENUECOUNT.toFixed(2)}`}</p>
                ):(
                  <Skeleton loading={true} active paragraph={{ rows: 0 }} />
                )}
              </div>

              <div className="transaction_Card margin-right">
                <div className="transction_main_Card">
                  <h1>Sales</h1>
                  <div>
                    <Select
                      defaultValue="All"
                      style={{
                        width: 90,
                      }}
                      bordered={false}
                      onChange={handleSaleShowRequest}
                      options={[
                        {
                          value: "all",
                          label: "All",
                        },
                        {
                          value: "shop",
                          label: "Shop",
                        },
                        {
                          value: "grooming",
                          label: "Grooming",
                        },
                        {
                          value: "boarding",
                          label: "Boarding",
                        },
                        {
                          value: "veterinary",
                          label: "Veterinary",
                        },
                        {
                          value: "training",
                          label: "Training",
                        },
                        {
                          value: "daycare",
                          label: "Day Care",
                        }
                      ]}
                    />
                  </div>
                </div>
                <p className="presentation">
                  Represents total sales generated <br/> in the past 6 months.
                </p>
                {TOTALSALECOUNT !== "" ? (
                  <p className="sales_text">{`AED ${TOTALSALECOUNT.toFixed(2)}`}</p>
                ):(
                  <Skeleton loading={true} active paragraph={{ rows: 0 }} />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* tables */}
        <div className="row">
          <div className="col-md-12">
            <Recent_order 
              orderList = {ordersDetails}
              isLoading = {isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
