import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, notification, InputNumber, Select,Upload } from "antd";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import TobNavBar from "../../DashBaord/Top_navbar"
import VendorListDeletePopUp from "./VendorListDeletePopUp";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../UserList/UserList.css";

import { InfoCircleOutlined, CheckCircleOutlined, LikeOutlined, DislikeOutlined } from "@ant-design/icons";

import moment from "moment";
import { ASSETS_BASE_URL } from "../../../config/constants";
import SkeltonList from "../../../components/SkeltonEffect/list";
// import { list, chnageStatus } from "../../../controllers/stores/storesController";
import { getPage, statusMessage } from '../../../controllers/common';

import {list, addeditdata, chnageStatus} from "../../../controllers/brancges/brancgesController";

import { fetchIpAddress } from "../../../controllers/API";
import { FaPlusSquare } from "react-icons/fa";


function VendorList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

VendorList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {

  const { Dragger } = Upload;

  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setAddEditData("")
    setValue(newValue);
  };
  const { Option } = Select;
  /************************************************************************************* */
  const location = useLocation();
  const { state } = location;
  const [STOREDATA, setStoreData]= useState(state?state:"");
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const [deleteId, setDeleteId]= useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from : '',
    to : new Date(),
    filter_by : "",
    search : ""
  })//End

  const [showRequest, setShowRequest] = useState("");
  const [image, setImage] = useState([]);


  const handleEdit = async (item={}) =>{
    setAddEditData(item);
    setValue(1)
  }
  /*********************************************************
  *  This function is use to fetch vendor list
  *********************************************************/
  const getList = async ()=>{
    setListData([]);
    const options = {
      type : "",
      condition : { 
        ...(filter?.from ? {
          createdAt: {
            '$gte': moment(filter?.from).format('YYYY-MM-DD'),
            '$lte': moment().format('YYYY-MM-DD'),
          },
        } : null),
        ...(filter?.search?{[filter?.filter_by]:{ $regex: filter.search, $options: 'i' }}:null),
        ...(showRequest?{status : showRequest}:null),
        storeData : STOREDATA?._id,
        vendorData : STOREDATA.vendorData?._id
      },
      select    : { },
      sort      : {"_id" : -1},
      skip      : SKIP?SKIP:0,
      limit     : LIMIT?LIMIT:10
    }
    const listData = await list(options);
    if(listData.status === true){
      if(listData?.result?.length > 0){
        setListData(listData?.result);
      } else{
        setListData([{branch_name : 'No Data Found'}]);  
      }
      const countOptions = {
        type : "count",
        condition : {
          ...(filter?.from ? {
            createdAt: {
              '$gte': moment(filter?.from).format('YYYY-MM-DD'),
              '$lte': moment().format('YYYY-MM-DD'),
            },
          } : null),
          ...(showRequest?{status : showRequest}:null),
          storeData : STOREDATA?._id,
          vendorData : STOREDATA.vendorData?._id
        }
      }
      const count = await list(countOptions);
      setTotalPages(getPage(count?.result)); 
    }else{
      setListData([{branch_name : 'No Data Found'}]);
    }
  };//End

  /*********************************************************
  *  This function is for handle page change
  *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage -1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };
  /*********************************************************
  *  This function is use to handle remove vendor
  *********************************************************/
  const handleStatusChnage = async (status='', id) => {
    try{
      setListData([]);
      const options ={
        url : `accounts/vendors/changestatus`,
        postData :{
          user_id : id,
          status : status,
        }
      }
      const res = await chnageStatus(options);
      if(res.status === true){
        notification.open({
          message: "Data updated.",
          description:  `Data updated Successfully.`,
          placement: "topRight", 
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
          duration:2
        });
      } else{
        notification.open({
          message: "Opps!",
          description:  `${res?.message}`,
          placement: "topRight", 
          icon: <InfoCircleOutlined style={{ color: 'red' }} />,
          duration:2
        });
      }
    } catch (error){
      notification.open({
        message: "Opps!",
        description:  `Operation not perform yet! please try in some time.`,
        placement: "topRight", 
        icon: <InfoCircleOutlined style={{ color: 'red' }} />,
        duration:2
      });
    }
    getList();
  }

  /*********************************************************
   *  This function is use to handle image
   *********************************************************/
  const handleImageChnage = (e) => {
    setImage(e.fileList);
  };

  const dropdownMenu = (items) => {
    return <>
    <Menu>
      <Menu.Item key="1" onClick={()=>handleEdit(items)}>
          <FaEdit />
          <span className="show_span_edit">Edit</span>
      </Menu.Item>
      {items?.status==='I' && (
        <Menu.Item key={`02`} onClick={()=>{ handleStatusChnage('A', items._id) }}>
          <LikeOutlined /> <span className="show_span">Active</span>
        </Menu.Item>  
      )}
      {items?.status==='A' && (
        <Menu.Item key={`02`} onClick={()=>{ handleStatusChnage('I', items._id) }}>
          <DislikeOutlined /> <span className="show_span">Inactive</span>
        </Menu.Item>  
      )}
    </Menu>
    </>
  };

  /*********************************************************
  *  This function is load when page load and with dependency update
  *********************************************************/
  useEffect(()=>{
    getList();
    document.title = 'Frisbee Super Admin || Branch List'
  },[currentPage,showRequest, popUpOpen, value]);

  /*************************************************************
   * ********************* ADD/EDIT DATA ***********************
   **************************************************************/
  const [ADDEDITDATA, setAddEditData] = useState("");
  const [error, serError] = useState([]);

  /*********************************************************
   *  This function is use to handle imput chnage
   *********************************************************/
  const handleInputChange = (e) => {
    setAddEditData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    serError((pre) => ({
      ...pre,
      [e.target.name]: "",
    }));
  };

  
  /*********************************************************
   *  This function is use to handle phone chnage chnage
   *********************************************************/
  const handlePhoneChnage = (value) => {
    setAddEditData((pre) => ({
      ...pre,
      phone: value,
    }));
    serError((pre) => ({
      ...pre,
      phone: "",
    }));
  };

  /*********************************************************
   *  This function is use to handle phone code chnage
   *********************************************************/
  const handlePhoneCodeChnage = (value) => {
    setAddEditData((pre) => ({
      ...pre,
      country_code: value,
    }));
  };

   /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
   const handleSubmit = async () => {
    // console.log('productCatAddEditData',productCatAddEditData)
    // console.log('serviceCatAddEditData',serviceCatAddEditData)
    // return false;
    try {
      const {
        _id,
        branch_name,
        phone,
        email,
        city,
        address,
        street,
        branch_manager,
        latitude,
        longitude
      } = ADDEDITDATA;
      const ipAddress = await fetchIpAddress();
      const form = new FormData();
      if (_id && _id !== "undefined" && _id !== null) {
        form.append("edit_id", _id);
      } 
      form.append("storeid", STOREDATA?._id);
      form.append("vendorid", STOREDATA.vendorData?._id);

      form.append("branch_name", branch_name);
      form.append("branch_manager", branch_manager);
      form.append("phone", phone);
      form.append("email", email);
      form.append("image", image[0].originFileObj);
      
      form.append("city", city);
      form.append("address", address);
      form.append("street", street);
      
      form.append("latitude", latitude);
      form.append("longitude", longitude);
      
      form.append("ipAddress", ipAddress);
      const isValidate = validateFormData(form);
      if (isValidate) {
        const options = {
          url: "accounts/branch/addeditdata",
          postData: form,
        };
        const res = await addeditdata(options);
        if (res.status === true) {
          notification.open({
            message: "Data updated.",
            description: `Data updated Successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          navigate("/branch/list");
        } else {
          notification.open({
            message: "Opps!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      } else {
        notification.open({
          message: "Opps!",
          description: `Please enter the required fields.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /*********************************************************
   *  This function is use to validate form data before submit
   *********************************************************/
  const validateFormData = (formData) => {
    if (
      formData.get("branch_name") === "undefined" ||
      formData.get("branch_name") === null ||
      formData.get("branch_name").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        branch_name: "Branch name is required",
      }));
      return false;
    } else if (
      formData.get("phone") === "undefined" ||
      formData.get("phone") === null ||
      formData.get("phone").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        phone: "Phone number is required",
      }));
      return false;
    } else if (
      formData.get("email") === "undefined" ||
      formData.get("email") === null ||
      formData.get("email").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        email: "Email address is required",
      }));
      return false;
    } else if (
      formData.get("latitude") === "undefined" ||
      formData.get("latitude") === null ||
      formData.get("latitude").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        latitude: "Latitude is required",
      }));
      return false;
    } else if (
      formData.get("longitude") === "undefined" ||
      formData.get("longitude") === null ||
      formData.get("longitude").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        longitude: "Longitude is required",
      }));
      return false;
    } else if (
      formData.get("vendorid") === "undefined" ||
      formData.get("vendorid") === null ||
      formData.get("vendorid").trim() === ""
      ){
        serError((preError) => ({
          ...preError,
          vendor: "Vendor Not Found",
        }));
        return false;
    } else if (
      formData.get("storeid") === "undefined" ||
      formData.get("storeid") === null ||
      formData.get("storeid").trim() === ""
      ){
        serError((preError) => ({
          ...preError,
          store: "Store Not Found",
        }));
        return false;
    } else {
      return true;
    }
  }; //End

  
  /************************END of Section ***********************/
  return (
    <>
      <div className="container-fluid">
        <TobNavBar title={`${STOREDATA.shop_name}'s Branch List`}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="List"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Add Branch"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </div>
                <div className="fa_filter_rating_review">
                  <div className="inventory_filter_div">
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt="filter"
                      className="user_iconses"
                    />
                    <Link to={false} className="filter_btn_invontry_rating_review">
                      Filters
                    </Link>
                  </div>
                </div>
              </div>
            </Box>

            {/* First Inventory */}
            <VendorList value={value} index={0} ref={targetRef}>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    {/* <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        All Vendor's List
                      </h6>
                    </div> */}
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Owner's Data</th>
                              <th>Created On</th>
                              <th>Contact No</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                          {ALLLISTDATA?.length > 0 ? (ALLLISTDATA.map((items, index)=>(<>
                            <tr key={index}>
                              <td>
                                <div className="admin_user_list_table">
                                  <div className="admin_user_list_name_gmail">
                                    <span className="admin_user_list_name">
                                      {`${items?.branch_name}`}
                                    </span>
                                    <span className="admin_user_list_gmail">
                                      {items?.email}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {items?.vendorData && (
                                  <>
                                  {`${items?.vendorData?.vendor_first_name} ${items?.vendorData?.vendor_last_name} `}<br/>   
                                  {`${items?.vendorData?.vendor_email}`}<br/>
                                  {`${items?.vendorData?.vendor_phone}`}
                                  </>
                                )}
                              </td>
                              <td className="admin_user_list_date">
                              {moment(`${items?.createdAt}`).format('DD/MM/YYYY, h:m A')}{" "}
                              </td>
                              <td className="admin_user_list_date"> {items?.phone} </td>
                              <td className="admin_user_list_date" dangerouslySetInnerHTML={{__html : statusMessage(items.status)}}></td>
                              <td>
                                <Dropdown
                                  overlay={()=>dropdownMenu(items)}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            </>))) : (<SkeltonList row={10} col={4} />)}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={TOTALPAGES} onChange={handlePageChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </VendorList>
            {/* Ad/Edit Branch */}
            <VendorList value={value} index={1}>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 all_border_bottom_for_hide">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        {ADDEDITDATA?._id?'Edit':'Add'} Branch
                      </h6>
                    </div>
                    <div className="card-body pt-0">
                      <div className="responsive">
                        <form method="post" className="inventory_form_main_contnet">
                          <div className="row inventory_form_main_content_form_section">
                            <div className="row">

                              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                                <label htmlFor="vendor_first_name">
                                  <span style={{ color: "red" }}>*</span>Branch Name :{" "}
                                </label>
                                <input
                                  title="Enter branch name."
                                  placeholder="Enter branch name."
                                  type="text"
                                  name="branch_name" 
                                  className="form-control store_input_field"
                                  id="branch_name"
                                  value={ADDEDITDATA?.branch_name}
                                  onChange={handleInputChange}
                                />
                                {error.branch_name ? (
                                  <p style={{ color: "red" }}>
                                    {error.branch_name}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                                <label htmlFor="branch_manager">
                                  <span style={{ color: "red" }}>*</span>Branch Manager :{" "}
                                </label>
                                <input
                                  title="Enter branch name."
                                  placeholder="Enter branch manager name."
                                  type="text"
                                  name="branch_manager"
                                  className="form-control store_input_field"
                                  id="branch_manager"
                                  value={ADDEDITDATA?.branch_manager}
                                  onChange={handleInputChange}
                                />
                                {error.branch_manager ? (
                                  <p style={{ color: "red" }}>
                                    {error.branch_manager}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                                <label htmlFor="email">
                                  <span style={{ color: "red" }}>*</span>Email Address :
                                </label>
                                <input
                                  title="Enter your email address."
                                  placeholder="Enter email address."
                                  type="text"
                                  name="email"
                                  className="form-control store_input_field"
                                  id="email"
                                  value={ADDEDITDATA?.email}
                                  onChange={handleInputChange}
                                />
                                {error.email ? (
                                  <p style={{ color: "red" }}>{error.email}</p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <label htmlFor="phone">
                                  <span style={{ color: "red" }}>*</span>Phone Number :
                                </label>
                                <br />
                                <InputNumber
                                  style={{ width: "100%" }}
                                  onChange={handlePhoneChnage}
                                  placeholder="Enter phone number."
                                  addonBefore={
                                    <Select
                                      defaultValue={
                                        ADDEDITDATA?.vendor_country_code
                                          ? ADDEDITDATA.vendor_country_code
                                          : "+971"
                                      }
                                      style={{ width: 150 }}
                                      onChange={handlePhoneCodeChnage}
                                    >
                                      <Option value="+971">+971 (UAE)</Option>
                                      {/* <Option value="+974">+974 (QA)</Option>
                                    <Option value="+965">+965 (KW)</Option>
                                    <Option value="+966">+966 (SA)</Option>
                                    <Option value="+968">+968 (OM)</Option> */}
                                      <Option value="+91">+91 (IND)</Option>
                                    </Select>
                                  }
                                  value={ADDEDITDATA?.phone}
                                />
                                {error.phone ? (
                                  <p style={{ color: "red" }}>{error.phone}</p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                                <label htmlFor="city">Province/City/Area :</label>
                                <input
                                  title="Enter your Province/City/Area."
                                  type="text"
                                  name="city"
                                  className="form-control store_input_field"
                                  id="city"
                                  value={ADDEDITDATA?.city}
                                  onChange={handleInputChange}
                                />
                                {error.city ? (
                                  <p style={{ color: "red" }}>{error.city}</p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                                <label htmlFor="street">Street :</label>
                                <input
                                  title="Enter street name."
                                  type="text"
                                  name="street"
                                  className="form-control store_input_field"
                                  id="street"
                                  value={ADDEDITDATA?.street}
                                  onChange={handleInputChange}
                                />
                                {error.street ? (
                                  <p style={{ color: "red" }}>{error.street}</p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                                <label htmlFor="address">
                                  Address(Building, Floor) :
                                </label>
                                <input
                                  title="Enter Address(Building, Floor)"
                                  type="text"
                                  name="address"
                                  className="form-control store_input_field"
                                  id="address"
                                  value={ADDEDITDATA?.address}
                                  onChange={handleInputChange}
                                />
                                {error.address ? (
                                  <p style={{ color: "red" }}>{error.address}</p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                                <label htmlFor="latitude">
                                  Latitude :
                                </label>
                                <input
                                  title="Enter latitude"
                                  type="text"
                                  name="latitude"
                                  className="form-control store_input_field"
                                  id="latitude"
                                  value={ADDEDITDATA?.latitude}
                                  onChange={handleInputChange}
                                />
                                {error.latitude ? (
                                  <p style={{ color: "red" }}>{error.latitude}</p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                                <label htmlFor="longitude"> longitude : </label>
                                <input
                                  title="Enter longitude"
                                  type="text"
                                  name="longitude"
                                  className="form-control store_input_field"
                                  id="longitude"
                                  value={ADDEDITDATA?.longitude}
                                  onChange={handleInputChange}
                                />
                                {error.longitude ? (
                                  <p style={{ color: "red" }}>{error.longitude}</p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="form-group">
                                <label htmlFor="exampleInputPassword1">
                                  {!ADDEDITDATA?._id && (
                                    <span style={{ color: "red" }}>*</span>
                                  )}
                                  Images
                                </label>
                                <Dragger
                                  className="about_us_dragger_file"
                                  maxCount="1"
                                  listType="picture"
                                  showUploadList={false}
                                  onChange={handleImageChnage}
                                >
                                  {/* <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                  </p> */}
                                  <button
                                    type="button"
                                    className="about_us_dragger_file_button"
                                  >
                                    {image[0]?.name?image[0].name:'Upload file'}
                                  </button>
                                  {/* <p className="ant-upload-hint">
                                    Support for a single. Only accept jpeg/jpg/png format.
                                  </p> */}
                                </Dragger>
                                {error.image ? (
                                  <p style={{ color: "red" }}>{error.image}</p>
                                ) : (
                                  ""
                                )}
                              </div>

                              

                            </div>
                          </div>

                        

                          <div className="inventory_edit_submit_btn">
                            <button
                              type="button"
                              className="btn btn-primary inventory_save_button"
                              onClick={handleSubmit}
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </VendorList>
            {/* Third Inventory */}
          </Box>
        </div>
        <VendorListDeletePopUp
          popUpOpen={popUpOpen}
          togglePopUp={togglePopUp}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
        />
      </div>
    </>
  );
}
