import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Tabs, Tab } from "@mui/material";
import { Upload, Button, Select, Flex, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FaFileUpload } from "react-icons/fa";
import ExcelJS from 'exceljs';
import Loading from "../../components/Loading/LoadingEffect";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  list as getlist,
  addEditData,
} from "../../controllers/Products/product";
import { commonList, ucfirst } from "../../controllers/common";
import { list as vendorlist } from "../../controllers/vendors/vendorsController";
import { list as inventoryList, bulkUpload } from "../../controllers/Products/inventory";
import { ASSETS_BASE_URL } from '../../config/constants'
const conicColors = {
  '0%': '#87d068',
  '50%': '#ffe58f',
  '100%': '#ffccc7',
};
const InventoryAddProductPopUp = ({
  inventoryAddProProductPopUp,
  inventoryToggleAddProductPopUp,
  editdata = {},
}) => {
  const [tabValue, setTabValue] = useState(0);

  const handleCancel = () => {
    inventoryToggleAddProductPopUp();
  };
  const handleDelete = () => {
    notification.open({
      message: "Notification Title",
      description: " Request Send Successfully .",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  /**************************** Integration Start Here *****************/
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(editdata || []);
  const [ALLVENDOR, setAllVendor] = useState([]);
  const [ALLPRODUCT, setAllProduct] = useState([]);
  const [ALLSHOP, setAllShop] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]); 
  const [filterBranch, setFilterBranch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [imageList, setImageList] = useState("");
  const [jsonData, setJsonData] = useState(null);
  const [progress, setProgress] = useState(0);
  /*********************************************************
   *  This function is use to get all vendor list
   *********************************************************/
  const getVendorList = async () => {
    try {
      const options = {
        condition: { status: "A" },
        select: {
          vendor_id: false,
          vendor_email: false,
          creation_date: false,
          createdAt: false,
          creation_ip: false,
          vendor_address: false,
          vendor_city: false,
          vendor_country: false,
          vendor_image: false,
          vendor_otp: false,
          vendor_phone: false,
          vendor_pincode: false,
          vendor_state: false,
          created_by: false,
          update_date: false,
          update_ip: false,
          updatedAt: false,
          updated_by: false,
          vendor_commission: false,
          vendor_country_code: false,
          vendor_gender: false,
          vendor_revenue: false,
          vendor_street: false,
        },
        sort: { vendor_first_name: 1 },
        skip: 0,
        limit: 0,
      };
      const res = await vendorlist(options);
      if (res.status === true) {
        setAllVendor(res?.result);
      } else {
        setAllVendor([]);
      }
    } catch (error) {
      setAllVendor([]);
    }
  };

  const getProductList = async () => {
    try {
      const options = {
        condition: { status: "A" },
        select: { name: true },
      };
      const res = await getlist(options);
      if (res?.status === true && res?.result?.length > 0) {
        setAllProduct(res?.result);
      } else {
        setAllProduct([]);
      }
    } catch (error) {
      setAllProduct([]);
    }
  };

  const getComonList = async () => {
    try {
      const options = {
        vendorId: formData?.vendor,
        list: ["store", "branch"],
        store: { select: { shop_name: true } },
        branch: { select: { storeData: true, branch_name: true } },
      };
      const res = await commonList(options);
      // console.log('res',res);
      if (res?.status === true && res?.result) {
        setAllShop(res?.result?.store);
        setAllBranch(res?.result?.branch);
      } else {
        setAllShop([]);
        setAllBranch([]);
      }
    } catch (error) {
      setAllBranch([]);
      setAllShop([]);
    }
  };

  useEffect(() => {
    getProductList();
    getVendorList();
  }, []);

  const [ALLVARIANTS, setAllvariants] = useState([]);
  /********** This function is used for add more variants ********* */
  const handleAddMorevariants = () => {
    setAllvariants([
      ...ALLVARIANTS,
      {
        unit: "",
        rate: "",
        qty: "",
        discount: "",
      },
    ]);
  };
  /******* This function is used for dynamically render All Varients ********** */
  const variants = (ALLVARIANTS = []) => {
    return ALLVARIANTS.map((items, index) => (
      <div key={index} className="variantCount row">
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="form-group vendor_Add_edit_data_form">
            <label htmlFor="unit" className="vendor_popup_product_name">
              Unit
            </label>
            <input
              title="Enter your last name."
              type="text"
              name={`unit_${index}`}
              className="form-control store_input_field"
              id="unit"
            />
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="form-group vendor_Add_edit_data_form">
            <label htmlFor="Price" className="vendor_popup_product_name">
              Price{" "}
              <span style={{ fontSize: "xx-small" }}>(Inclusive of VAT)</span>
            </label>
            <input
              title="Enter your last name."
              type="number"
              name={`price_${index}`}
              className="form-control store_input_field"
              id="Price"
            />
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="form-group vendor_Add_edit_data_form">
            <label htmlFor="qty" className="vendor_popup_product_name">
              Qty
            </label>
            <input
              title="Enter your last name."
              type="number"
              name={`qty_${index}`}
              className="form-control store_input_field"
              id="qty"
            />
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="form-group vendor_Add_edit_data_form">
            <label htmlFor="discount" className="vendor_popup_product_name">
              Discount (%)
            </label>
            <input
              title="Enter your last name."
              type="number"
              name={`discount_${index}`}
              className="form-control store_input_field"
              id="discount"
            />
          </div>
        </div>

        <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label htmlFor="size" className="vendor_popup_product_name">
            Size :
          </label>
          <Flex vertical gap="middle" className="">
            <div
              className="verndor_dashboard_add_edit_button"
              defaultValue="Y"
              name="size"
              id="size"
              size="small"
            >
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                  <div className="vendor_dashbaord_gender_main_section">
                    <label htmlFor={`small_${index}`}>Small</label>
                    <input
                      type="checkbox"
                      id={`small_${index}`}
                      name={`small_${index}`}
                      value="Y"
                      className="form-check-input"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                  <div className="vendor_dashbaord_gender_main_section">
                    <label htmlFor={`medium_${index}`}>Medium</label>
                    <input
                      type="checkbox"
                      id={`medium_${index}`}
                      name={`medium_${index}`}
                      value="Y"
                      className="form-check-input"
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                  <div className="vendor_dashbaord_gender_main_section">
                    <label htmlFor={`large_${index}`}>Large</label>
                    <input
                      type="checkbox"
                      id={`large_${index}`}
                      name={`large_${index}`}
                      value="Y"
                      className="form-check-input"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Flex>
        </div>
      </div>
    ));
  };

  /*********** This function is used for handle form submit ******** */
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      const formData = new FormData(event.target);
      const formDataObject = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
      const params = await transformData(formDataObject);
      // console.log('params',params);
      // return false;
      const isValidate = validateForm(params);
      if (isValidate) {
        const options = {
          url: "poductsmaster/addedit-inventory",
          postData: params,
        };
        const res = await addEditData(options);
        if (res.status === true) {
          event.target.reset();
          setIsLoading(false);
          notification.open({
            message: "Notification Title",
            description: " Request Send Successfully .",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
        } else {
          notification.open({
            message: "Oops!!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          setIsLoading(false);
        }
      } else {
        notification.open({
          message: "Validation Error!",
          description: " Please fill the required fields.",
          placement: "topRight",
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /**** This function is used for generate post form data *********** */
  const transformData = (input) => {
    const { vendor, productid, store, branch, ...variants } = input;
    const variantKeys = Object.keys(variants).filter(
      (key) =>
        key !== "productid" &&
        key !== "vendor" &&
        key !== "store" &&
        key !== "branch"
    );

    const transformedData = {
      vendor: vendor,
      productid: productid,
      store: store,
      branch: branch,
      varients: [],
    };

    for (let i = 0; i < variantKeys.length / 7; i++) {
      const unit = variants[`unit_${i}`];
      const price = variants[`price_${i}`];
      const qty = variants[`qty_${i}`];
      const small = variants[`small_${i}`] ? variants[`small_${i}`] : "N";
      const medium = variants[`medium_${i}`] ? variants[`medium_${i}`] : "N";
      const large = variants[`large_${i}`] ? variants[`large_${i}`] : "N";

      // Only add the variant if all fields are non-empty
      if (unit !== "" && price !== "" && qty !== "") {
        transformedData.varients.push({
          unit,
          price,
          qty,
          discount: variants[`discount_${i}`],
          small,
          medium,
          large,
        });
      }
    }

    return transformedData;
  };

  /********  */
  const validateForm = (formData) => {
    try {
      const { vendor, store, branch, productid, varients } = formData;
      if (!vendor) {
        setErrors((pre) => ({
          ...pre,
          vendor: "Please select the vendor.",
        }));
        return false;
      } else if (!store) {
        setErrors((pre) => ({
          ...pre,
          store: "Please select the store.",
        }));
        return false;
      } else if (!productid) {
        setErrors((pre) => ({
          ...pre,
          store: "Please select the store.",
        }));
        return false;
        // } else if(!branch){
        //   setErrors((pre)=>({
        //     ...pre,
        //     branch : "Please select the store."
        //   }))
        //   return false;
      } else if (!varients || varients.length === 0) {
        setErrors((pre) => ({
          ...pre,
          variants: "Incomplete variants.",
        }));
        return false;
      } else {
        return true;
      }
    } catch (error) {
      setErrors((pre) => ({
        ...pre,
        formError: "Server is under maintance.",
      }));
      return false;
    }
  };

  const handleChnage = async (e) => {
    try {
      const { name, value } = e.target;
      if (name === "vendor") {
        setFormData((pre) => ({
          ...pre,
          [name]: value,
        }));
        // setAllShop([]);
        // getComonList();
      } else if (name === "store") {
        // console.log('value',value);
        // const val = value.split("___");
        const filteredArray = ALLBRANCH.filter(
          (item) => item.storeData === value
        );
        setFilterBranch(filteredArray);
        setFormData((pre) => ({
          ...pre,
          [name]: value,
        }));

      } else if (name === "branch") {
        setFormData((pre) => ({
          ...pre,
          [name]: value,
        }));
      } else if (name === "productid") {
        setLoading(true);
        setFormData((pre) => ({
          ...pre,
          productid: value,
        }));
        await chechDuplicateProduct(value);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (formData.vendor) {
      setAllShop([]);
      getComonList();
    }
  }, [formData.vendor]);
  useEffect(()=>{
    setImageList('');
    setJsonData('');
    setProgress(0);
  }, [inventoryAddProProductPopUp])
  const chechDuplicateProduct = async (pid) => {
    try {
      const option = {
        type: "single",
        condition: {
          productData: pid,
          branch: formData.branch,
        },
      };
      const res = await inventoryList(option);
      if (res.status === true) {
        if (res?.result?._id) {
          setErrors((pre) => ({
            ...pre,
            duplicateProduct: "Product already  exist in this Branch.",
          }));
        } else {
          setErrors((pre) => ({
            ...pre,
            duplicateProduct: "",
          }));
        }
      }
      setLoading(false);
      // return true;
    } catch (error) {
      setLoading(false);
    }
  };
  
  /*********************************************************
  *  This function is use to handle image object
  *********************************************************/
  const handleImageChnage = (e) => {
    setImageList(e.fileList[0].originFileObj);
    const file = e.fileList[0].originFileObj;
    const workbook = new ExcelJS.Workbook();
    const reader = new FileReader();

    reader.onload = async (event) => {
      const buffer = event.target.result;
      await workbook.xlsx.load(buffer);

      const worksheet = workbook.worksheets[0];
      const rows = [];

      worksheet.eachRow((row, rowNumber) => {
        const rowValues = row.values.slice(1); // Remove the first empty value
        rows.push(rowValues);
      });

      // Convert rows to JSON (assuming first row is the header)
      const headers = rows[0];
      const data = rows.slice(1).map(row => {
        const rowData = {};
        row.forEach((cell, index) => {
          rowData[headers[index]] = cell;
        });
        return rowData;
      });
      setJsonData(data);
    };

    reader.readAsArrayBuffer(file);
  }

  /*********************************************************
  *  This function is use to handle bulk upload
  *********************************************************/
  const handleBulkUpload = async () => {
    try {
      const options = {
        vendor : formData?.vendor,
        store : formData?.store,
        branch : ALLBRANCH[0]._id,
        data : jsonData
      }
      const dataArray = await transformBulkData(options);
      if(dataArray && dataArray?.length > 0){
        // const res = await bulkUpload(data);
        if (dataArray && dataArray?.length > 0) {
          for (let i = 0; i < dataArray.length; i += 10) {
              const batch = dataArray.slice(i, i + 10);
              const res = await bulkUpload(batch);
              if (res.status !== true) {
                  setErrors((pre) => ({
                      ...pre,
                      formError: res?.message,
                  }));
                  notification.open({
                    message: "Oops!!",
                    description: `Error while uploading`,
                    placement: "topRight",
                    icon: <CheckCircleTwoTone twoToneColor="red" />,
                  });
                  break;
              }
              const progressPercentage = Math.round(((i + 10) / dataArray.length) * 100);
              setProgress(progressPercentage);
          }
          notification.open({
            message: "Success",
            description: "Upload Successfully.",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="green" />,
          });
          handleCancel();
        }
      } else {
        notification.open({
          message: "Oops!!",
          description: "Uploaded file is empty.",
          placement: "topRight",
          icon: <CheckCircleTwoTone twoToneColor="red" />,
        });
      }
    } catch (error) {
      console.log('error',error)
    }
  }

  const transformBulkData = async (option) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { vendor, store, branch, data } = option;
            let result = [];

            for (let i = 0; i < data.length; i += 10) {
                const batch = data.slice(i, i + 10);
                const transformedBatch = await Promise.all(batch.map(async (item) => {
                    return {
                      vendorData: vendor,
                      store: store,
                      branch: branch,
                      productData: item.productid,
                      unit: item?.unit || 0,
                      qty: item?.qty || 0,
                      rate: item?.rate || 0,
                      discountType : item?.discountType,
                      discount : item?.discount,
                      small : item?.small || 'N',
                      medium : item?.medium || 'N',
                      large : item?.large || 'N',
                      status : 'A'
                    };
                }));
                result = result.concat(transformedBatch);
            }

            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
  };
  return (
    <Dialog
      open={inventoryAddProProductPopUp}
      onClose={inventoryToggleAddProductPopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Add Product
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <div className="inventory_select_opetion_popup_modal">
                  <span>Select option</span>
                </div>
                <form method="post" onSubmit={handleSubmit}>
                  <Tabs
                    value={tabValue}
                    onChange={(event, newValue) => setTabValue(newValue)}
                    aria-label="tabs example"
                    className="frisbee_dashbaord_inventory_popup_tabs"
                    indicatorColor="secondary"
                  >
                    <Tab
                      id="tab1"
                      label={
                        <div className="vendor_dashbaord_signle_product">
                          <span>Single product</span>
                          <input
                            type="radio"
                            id="radio1"
                            checked={tabValue === 0}
                            onChange={() => setTabValue(0)}
                            className="vendor_dashboard_radio_color"
                          />
                        </div>
                      }
                      className="frisbee_dashbaord_pop_up_tabs1"
                    />
                    
                    <Tab
                      id="tab2"
                      label={
                        <div className="vendor_dashbaord_signle_product">
                          <span>Multiple products</span>
                          <input
                            type="radio"
                            id="radio2"
                            checked={tabValue === 1}
                            onChange={() => setTabValue(1)}
                            className="vendor_dashboard_radio_color"
                          />
                        </div>
                      }
                      className="frisbee_dashbaord_pop_up_tabs1"
                    />
                  </Tabs>
                  {/* Content for each tab */}
                  {tabValue === 0 && (
                    <React.Fragment>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                          <label
                            htmlFor="vendor"
                            className="all_lable_for_vendor_dashbaord"
                          >
                            {" "}
                            Vendor{" "}
                          </label>
                          <select
                            id="vendor"
                            name="vendor"
                            onChange={handleChnage}
                            // className="form-control"
                            className="form-control store_input_field"
                          >
                            <option value="">Select a vendor</option>
                            {ALLVENDOR &&
                              ALLVENDOR.map((items, index) => (
                                <option
                                  key={index}
                                  value={items._id}
                                  select={
                                    editdata?.vendor === items._id
                                      ? true
                                      : false
                                  }
                                >
                                  {`${ucfirst(
                                    items.vendor_first_name
                                  )} ${ucfirst(items.vendor_last_name)}`}
                                </option>
                              ))}
                          </select>
                          {errors?.vendor ? (
                            <p style={{ color: "red" }}>{errors.vendor}</p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                          <label
                            htmlFor="store"
                            className="all_lable_for_vendor_dashbaord"
                          >
                            Store :
                          </label>
                          <select
                            name="store"
                            id="store"
                            className="form-control store_input_field"
                            onChange={handleChnage}
                          >
                            <option value="">Select Store</option>
                            {ALLSHOP?.length > 0 ? (
                              ALLSHOP.map((items, index) => (
                                <>
                                  <option key={index} value={`${items?._id}`}>
                                    {items?.shop_name}
                                  </option>
                                </>
                              ))
                            ) : (
                              <option value="">No Data Found</option>
                            )}
                          </select>
                          {errors?.store ? (
                            <p style={{ color: "red" }}>{errors.store}</p>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                          <label
                            htmlFor="store"
                            className="vendor_popup_product_name"
                          >
                            Branch :
                          </label>
                          <select name="branch" id="branch" className="form-control" onChange={handleChnage}>
                              <option value="">Select Branch</option>
                              {filterBranch?.length > 0 ? (filterBranch.map((items, index)=>(<>
                                <option key={index} value={`${items?._id}`}>{items?.branch_name}</option>
                              </>))) : (<option value="">No Data Found</option>)}
                          </select>
                          {errors?.branch?(<p style={{color:'red'}}>{errors.branch}</p>):""}
                        </div> */}

                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                          <label
                            htmlFor="product"
                            className="all_lable_for_vendor_dashbaord"
                          >
                            Product :
                          </label>
                          <select
                            name="productid"
                            id="productid"
                            className="form-control store_input_field"
                            onChange={handleChnage}
                          >
                            <option>Select products</option>
                            {ALLPRODUCT?.length > 0 ? (
                              ALLPRODUCT.map((items, index) => (
                                <>
                                  <option key={index} value={`${items?._id}`}>
                                    {items?.name}
                                  </option>
                                </>
                              ))
                            ) : (
                              <option>No Data Found</option>
                            )}
                          </select>
                          {errors?.productid ? (
                            <p style={{ color: "red" }}>{errors.productid}</p>
                          ) : (
                            ""
                          )}
                          {errors?.duplicateProduct ? (
                            <p style={{ color: "red" }}>
                              {errors.duplicateProduct}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* // next line */}
                        {variants(ALLVARIANTS)}
                        <div className="all_top_and_bottom_for_input_text">
                          <Link onClick={handleAddMorevariants}>
                            Add {ALLVARIANTS.length > 0 ? "more" : ""} variant
                          </Link>
                        </div>
                        {errors?.variants ? (
                          <p style={{ color: "red" }}>{errors.variants}</p>
                        ) : (
                          ""
                        )}
                      <div className="inventory_open_popup_action_btn_upload_main">
                        {errors?.formError ? (
                          <p style={{ colors: "red" }}>{errors.formError}</p>
                        ) : (
                          ""
                        )}
                        <button
                          type="button"
                          className="btn btn-light categories_cancel_button"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-danger "
                          // onClick={handleDelete}
                        >
                          Create
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                  {tabValue === 1 && (
                    <React.Fragment>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                        <label
                          htmlFor="vendor"
                          className="all_lable_for_vendor_dashbaord"
                        >
                          {" "}
                          Vendor{" "}
                        </label>
                        <select
                          id="vendor"
                          name="vendor"
                          onChange={handleChnage}
                          className="form-control store_input_field"
                        >
                          <option value="">Select a vendor</option>
                          {ALLVENDOR &&
                            ALLVENDOR.map((items, index) => (
                              <option
                                key={index}
                                value={items._id}
                                select={
                                  editdata?.vendor === items._id
                                    ? true
                                    : false
                                }
                              >
                                {`${ucfirst(
                                  items.vendor_first_name
                                )} ${ucfirst(items.vendor_last_name)}`}
                              </option>
                            ))}
                        </select>
                        {errors?.vendor ? (
                          <p style={{ color: "red" }}>{errors.vendor}</p>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                        <label
                          htmlFor="store"
                          className="all_lable_for_vendor_dashbaord"
                        >
                          Store :
                        </label>
                        <select
                          name="store"
                          id="store"
                          className="form-control store_input_field"
                          onChange={handleChnage}
                        >
                          <option value="">Select Store</option>
                          {ALLSHOP?.length > 0 ? (
                            ALLSHOP.map((items, index) => (
                              <>
                                <option key={index} value={`${items?._id}`}>
                                  {items?.shop_name}
                                </option>
                              </>
                            ))
                          ) : (
                            <option value="">No Data Found</option>
                          )}
                        </select>
                        {errors?.store ? (
                          <p style={{ color: "red" }}>{errors.store}</p>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data inventory_upload_excel_section">
                        <label htmlFor="company_registration_document" className="all_lable_for_vendor_dashbaord">
                          Upload excel
                        </label>
                        <div
                          className="sign_up_file_upload_for_inventory"
                          id="company_registration_document"
                        >
                          <div className="company_registration_document_button">{imageList?.name ? imageList.name : 'Upload File'}</div>
                          <Upload 
                            id="company_registration_document"
                            fileList={[]}
                            onChange={handleImageChnage}
                          >
                            <Button icon={<FaFileUpload />}>{imageList?.name ? `Change` : 'Upload File'}</Button>
                          </Upload>
                        </div>
                          <Link to={`${ASSETS_BASE_URL}assets/bulk-inventory-upload-templet.xlsx`} target="_blank">Download template</Link>
                      </div>
                      {progress === 0 ? (
                        <div className="inventory_open_popup_action_btn_upload_main">
                          <button
                            type="button"
                            className="btn btn-light categories_cancel_button"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger "
                            onClick={handleBulkUpload}
                          >
                            Create
                          </button>
                        </div>
                      ):(
                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data inventory_upload_excel_section">
                          <Progress type="line" percent={progress} strokeColor={conicColors} />
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </form>
              </div>
            </div>
          </Typography>
        </div>
      </div>
      <Loading isLoading={loading} />
    </Dialog>
  );
};

export default InventoryAddProductPopUp;
