import React, { useState, useEffect, useRef } from "react";
import "../ServiceList.css";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, Switch, Select, notification, Drawer, Button, Space } from "antd";
import { TbCategory } from "react-icons/tb";
import { UploadOutlined } from "@ant-design/icons";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import Topnavbar from '../../DashBaord/Top_navbar'
import CategoryProductDeletePopUp from "../Grooming/DeletePopUp";
import ProductAddEditData from "./addEditDogAdoption"
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { FaPlusSquare } from "react-icons/fa";
import { ASSETS_BASE_URL } from '../../../config/constants'
import moment from 'moment';
import { getPage } from '../../../controllers/common';
import { dogAdoptionList, changeStatus } from "../../../controllers/services/dogAdoption";
import SkeltonList from "../../../components/SkeltonEffect/list";
import SkeltonEffect from "../../../components/SkeltonEffect/list";
import { Link } from "react-router-dom";
import BlukUpload from './BlukUpload';

function CategoriesProductList(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CategoriesProductList.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function DogAdoptionTabs() {
    const [deletePopUpCategory, setDeletePopUpCategory] = useState(false);
    const handleProductDeleteToggle = () => {
        setDeletePopUpCategory(!deletePopUpCategory);
    };

    const [bulkUploadPopUp, setBulkUploadPopUp] = useState(false);
    const handleBulkUploadPopUp = () => {
        setBulkUploadPopUp(!bulkUploadPopUp);
    };

    /********************** Integratin Stare Here **********************/
    const [deteteId, setDeteteId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const targetRef = useRef(null);
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [ALLLISTDATA, setListData] = useState([]);
    const [TOTALPAGES, setTotalPages] = useState(1);
    const [LIMIT, setLimit] = useState(10);
    const [SKIP, setSkip] = useState(0);
    const [filter, setFilter] = useState({
        from: "",
        to: "",
        filter_by: "",
        search: ""
    })//End
    const [showRequest, setShowRequest] = useState("");
    const handleShowRequest = (value) => {
        setShowRequest(value);
    }

    const [showAddEditPopup, setAddEditPopup] = useState(false);
    const [EDITDATA, setEditData] = useState([]);
    /*********************************************************
    *  This function is use to handle notification
    *********************************************************/
    const handleNotification = (status, msg) => {
        notification.open({
            message: status,
            description: msg,
            placement: "topRight",
            duration: 2
        });

    };//End
    /*********************************************************
    *  This function is use to handle Add/Edit Popup
    *********************************************************/
    const handleAddEditData = () => {
        setAddEditPopup(true);
    };//End

    /*********************************************************
    *  This function is use to fetch category list
    *********************************************************/
    const getList = async () => {
        setListData([]);
        setIsLoading(true);
        const options = {
            type: "",
            condition: {
                ...(showRequest ? { status: showRequest } : null)
            },
            ...(filter ? { filter: filter } : null),
            select: {},
            sort: { "_id": -1 },
            skip: SKIP ? SKIP : 0,
            limit: LIMIT ? LIMIT : 10
        }
        const listData = await dogAdoptionList(options);
        if (listData.status === true && listData?.result?.length > 0) {
            setListData(listData?.result);
            setIsLoading(false);
            const countOptions = {
                type: "count",
                condition: { ...(showRequest ? { status: showRequest } : null) },
                ...(filter ? { filter: filter } : null)
            }
            const count = await dogAdoptionList(countOptions);
            setTotalPages(getPage(count?.result));
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };//End

    /*********************************************************
    *  This function is use to handle chnage status
    *********************************************************/
    const handleStatus = async (item) => {
        const option = {
            id: item?._id,
            status: item.status === 'A' ? "I" : "A",
        }
        const res = await changeStatus(option);
        if (res.status === true) {
            getList();
            handleNotification('Success!', 'Status Updated.')
        } else {
            handleNotification('Error!', res?.message)
        }
    };//End

    /*********************************************************
    *  This function is for handle page change
    *********************************************************/
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
        setSkip((newPage - 1) * LIMIT);
        targetRef.current.scrollIntoView({
            behavior: "smooth",
        });
    };

    const [isFilterShow, setFilterShow] = useState(false);
    const handleFiterDrawer = () => {
        setFilterShow(!isFilterShow);
    }
    /*********************************************************
    *  This function is ued for handle filter input chnage
    *********************************************************/
    const handleFilterReset = () => {
        try {
            setFilter('');
            inputRef1.current.value = '';
            inputRef2.current.value = '';
            inputRef3.current.value = '';
            inputRef4.current.value = '';
        } catch (error) {

        }
    }

    const handleFilterApply = (e) => {
        try {
            e.preventDefault();
            const form = new FormData(e.target);
            setFilter((pre) => ({
                ...pre,
                'filter_by': form.get('filter_by') || '',
                'search': form.get('search') || '',
                'to': form.get('to_date') || '',
                'from': form.get('from_date') || '',

            }))
            handleFiterDrawer();
        } catch (error) {

        }
    }

    /*********************************************************
    *  This function is load when page load and with dependency update
    *********************************************************/
    useEffect(() => {
        getList();
        targetRef.current.scrollIntoView({
            behavior: 'smooth',
        });
        document.title = "Frisbee Super Admin || Dog Adoption Services"
    }, [currentPage, showAddEditPopup, deletePopUpCategory, showRequest, filter]);
    /********************** Integratin End Here **********************/
    const [expandedItems, setExpandedItems] = useState({});
    const toggleDescription = (id) => {
        setExpandedItems((prev) => ({
            ...prev,
            [id]: !prev[id]
        }));
    };
    return (
        <>
            <div className="container-fluid" ref={targetRef}>
                <Topnavbar title="Adoption List"></Topnavbar>
                <div className="order_now_card_tilte">
                    <div class="card">
                        <div class="card-body categories_main_div">
                            <div className="categories_tb_select">
                                <TbCategory />
                                <Select
                                    name="show"
                                    id="show"
                                    className="product_select_tag_rating_review"
                                    style={{ width: 120 }}
                                    bordered={false}
                                    onChange={handleShowRequest}
                                    defaultValue={showRequest}
                                >
                                    <Select.Option value="" label="All" selected={true}>All</Select.Option>
                                    <Select.Option value="A" label="Active" >Active</Select.Option>
                                    <Select.Option value="I" label="Inactive" >Inactive</Select.Option>
                                </Select>
                            </div>
                            <div className="promotions_filters_div">
                                <div className="fa_filter_rating_review" onClick={handleFiterDrawer}>
                                    <img
                                        src={ASSETS_BASE_URL + "./images/Filter.svg"}

                                        alt=""
                                        className="user_iconses"
                                    />

                                    <a href="#" className="filter_btn_invontry_rating_review">
                                        Filters
                                    </a>
                                </div>
                                <React.Fragment>
                                    <button
                                        type="button"
                                        class="btn btn-dark"
                                        onClick={handleAddEditData}
                                    >
                                        <FaPlusSquare className="Promotions_faPlus_Squares" />
                                        <span className="request_new_categories">Add New</span>
                                    </button>
                                </React.Fragment>

                                <React.Fragment>
                                    <button
                                        type="button"
                                        class="btn btn-dark"
                                        onClick={handleBulkUploadPopUp}
                                    >
                                        <UploadOutlined className="Promotions_faPlus_Squares" />
                                        <span className="request_new_categories">Blulk Upload</span>
                                    </button>
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="inventory_tabs_main_div">
                    <Box sx={{ width: "100%" }}>
                        <CategoriesProductList>
                            <div>
                                <div id="content-wrapper" className="d-flex flex-column">
                                    <div className="card shadow mb-4">
                                        <div className="card-body pt-0">
                                            <div className="table-responsive">
                                                <table
                                                    className="table table-bordered"
                                                    id="dataTable"
                                                    width="100%"
                                                    cellspacing="0"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>Image</th>
                                                            <th>PetName</th>
                                                            <th>Description</th>
                                                            <th>Breed</th>
                                                            <th>Gender</th>
                                                            <th>Created At</th>
                                                            <th>Status</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {ALLLISTDATA.length > 0 ? (ALLLISTDATA.map((items, index) => (<>
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className="admin_prodcut_list_span">
                                                                        {items?.image ? (
                                                                            <img src={`${ASSETS_BASE_URL}${items.image}`} alt="" />
                                                                        ) : (
                                                                            <img src={"../images/IntentoryTableLogo.png"} alt="" />
                                                                        )}
                                                                        <span className="admin_prodcut_list_span_name">
                                                                            {items?.name}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span>{items?.pet_name}</span><br />
                                                                </td>
                                                                <td>
                                                                    <>
                                                                        {items?.description?.length > 256 ? (
                                                                            <span>
                                                                                {expandedItems[items._id]
                                                                                    ? items.description
                                                                                    : `${items.description.substring(0, 128)}...`}
                                                                                <Link to={false} onClick={() => toggleDescription(items._id)}>
                                                                                    {expandedItems[items._id] ? ' Show Less' : ' Show More'}
                                                                                </Link>
                                                                            </span>
                                                                        ) : (
                                                                            <span>{items?.description} <Link to={false}>Show Less</Link></span>
                                                                        )}
                                                                    </>
                                                                </td>

                                                                <td>{items?.breed}</td>
                                                                <td>{items?.gender}</td>
                                                                <td>{moment(`${items.createdAt}`).format('DD/MM/YYYY, h:m A')}</td>
                                                                <td>
                                                                    <Switch
                                                                        className="categories_switch_"
                                                                        checked={items?.status === 'A' ? true : false}
                                                                        onClick={() => handleStatus(items)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Dropdown
                                                                        overlay={
                                                                            <Menu>
                                                                                <Menu.Item key="1" onClick={() => {
                                                                                    setEditData(items)
                                                                                    handleAddEditData()
                                                                                }}>
                                                                                    <FaEdit />
                                                                                    <span className="show_span_edit">Edit</span>
                                                                                </Menu.Item>
                                                                                {/* <Menu.Item key="2" onClick={() => {
                                                                                    setDeteteId(items?._id)
                                                                                    handleProductDeleteToggle()
                                                                                }}>
                                                                                    <MdDelete /> <span className="show_span">Remove</span>
                                                                                </Menu.Item> */}
                                                                            </Menu>
                                                                        }
                                                                        placement="bottomLeft"
                                                                        arrow
                                                                    >
                                                                        <HiOutlineDotsHorizontal />
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        </>))) : isLoading ? <SkeltonList row={10} col={5} /> : <tr>
                                                            <td colspan="5" className="img-center" ><img src='https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg' width='55%' /></td>
                                                        </tr>}
                                                    </tbody>
                                                </table>
                                                <div className="table_pagination_sales_order">
                                                    <Pagination count={TOTALPAGES} onChange={handlePageChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CategoriesProductList>
                    </Box>
                </div>

                <CategoryProductDeletePopUp
                    id={deteteId}
                    deletePopUpCategory={deletePopUpCategory}
                    deleteProductToggle={handleProductDeleteToggle}
                    setDeteteId={setDeteteId}
                />
                <ProductAddEditData
                    show={showAddEditPopup}
                    editdata={EDITDATA}
                    setEditData={setEditData}
                    setAddEditPopup={setAddEditPopup}
                />
                <BlukUpload 
                    show={bulkUploadPopUp}
                    setBulkUploadPopUp={setBulkUploadPopUp}
                />



            </div>
            {/* Filter */}
            <Drawer
                title="Dog Adoption Service Filter"
                placement={`right`}
                width={500}
                onClose={handleFiterDrawer}
                open={isFilterShow}
                extra={
                    <Space>
                        <Button onClick={handleFiterDrawer}>Cancel</Button>
                        <Button type="primary" onClick={handleFilterReset}>
                            Reset
                        </Button>
                    </Space>
                }
            >
                <div className="responsive">
                    <div className="row">
                        <form id='filter_form' onSubmit={handleFilterApply}>
                            <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                                    Search Field :
                                </label>
                                <select name="filter_by" className="form-control" id="filter_by" ref={inputRef1}>
                                    <option value=''>Select search field</option>
                                    <option value='shop_name' selected={filter?.filter_by === 'shop_name' ? true : false}>Store Name</option>
                                    <option value='store_phone' selected={filter?.filter_by === 'store_phone' ? true : false}>Store Phone</option>
                                    <option value='store_email' selected={filter?.filter_by === 'store_email' ? true : false}>Store Email</option>
                                    <option value='gender' selected={filter?.filter_by === 'category' ? true : false}>Gender</option>
                                    <option value='breed' selected={filter?.filter_by === 'subcategory' ? true : false}>Breed</option>
                                    <option value='status' selected={filter?.filter_by === 'status' ? true : false}>Status</option>
                                </select>
                            </div>
                            <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                                    Search Text :
                                </label>
                                <input
                                    title="Enter search text."
                                    placeholder="Enter search text."
                                    type="text"
                                    name="search"
                                    className="form-control"
                                    id="search"
                                    ref={inputRef2}
                                // onChange={handleFilterChnage} 
                                // value={filter?.search}             
                                />
                            </div>
                            <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                                    From :
                                </label>
                                <input
                                    title="Enter search text."
                                    type="date"
                                    name="from_date"
                                    className="form-control"
                                    id="from_date"
                                    ref={inputRef3}
                                // onChange={handleFilterChnage}          
                                />
                            </div>
                            <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                                    To :
                                </label>
                                <input
                                    title="Enter search text."
                                    type="date"
                                    name="to_date"
                                    className="form-control"
                                    id="to_date"
                                    ref={inputRef4}
                                // onChange={handleFilterChnage}             
                                />
                            </div>
                            <br />
                            <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <button
                                    title="Enter search text."
                                    type="submit"
                                    className="form-control btn btn-primary "
                                >Apply</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Drawer>
        </>
    );
}
