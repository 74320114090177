import React, { useEffect, useState } from "react";
import { Upload, Flex, Radio, notification, InputNumber, Select } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import {
  addeditdata,
  chnageStatus,
  categoryList,
} from "../../../controllers/vendors/vendorsController";
import { fetchIpAddress } from "../../../controllers/API";

import LoadingEffect from "../../../components/Loading/LoadingEffect";
import { beforeUpload } from "../../../controllers/common";

const UserListEditPage = () => {
  const { Option } = Select;
  const { Dragger } = Upload;

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [error, setError] = useState([]);
  const [ADDEDITDATA, setAddEditSata] = useState(state ? state : []);
  const [productCat, setProductCat] = useState([]);
  const [serviceCat, setServiceCat] = useState([]);
  const [productCatAddEditData, setProductCatAddEditData] = useState([]);
  const [serviceCatAddEditData, setServiceCatAddEditData] = useState([]);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [image, setImage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    vendor_account_number: ADDEDITDATA?.vendor_account_number || "",
  });
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  /*********************************************************
   *  This function is use to handle imput chnage
   *********************************************************/
  const handleChange = (e) => {
    setAddEditSata((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setError((pre) => ({
      ...pre,
      [e.target.name]: "",
    }));
  };
  /*********************************************************
   *  This function is use to handle image
   *********************************************************/
  const handleImageChnage = (e) => {
    setImage(e.fileList);
  };
  /*********************************************************
   *  This function is use to handle genger checkbox chnage
   *********************************************************/
  const onCheckedChange = (e) => {
    setAddEditSata((pre) => ({
      ...pre,
      vendor_gender: e.target.value,
    }));
  };

  /*********************************************************
   *  This function is used to handle phone number change
   *********************************************************/
  const handlePhoneChange = (value) => {
    setAddEditSata((prev) => ({
      ...prev,
      vendor_phone: value,
    }));
    setError((prev) => ({
      ...prev,
      vendor_phone: "", // Clear previous error message
    }));
  };

  /*********************************************************
   *  This function is used to handle phone code change
   *********************************************************/
  const handlePhoneCodeChange = (value) => {
    setAddEditSata((prev) => ({
      ...prev,
      vendor_country_code: value,
      vendor_phone: "", // Clear phone number
    }));
    setError((prev) => ({
      ...prev,
      vendor_phone: "", // Clear previous error message
    }));
  };

  const handleProductcategory = (value) => {
    console.log("value", value);
    setProductCatAddEditData(value);
    setError((preError) => ({
      ...preError,
      product_category: "",
    }));
  };

  const handleServicesCategory = (value) => {
    setServiceCatAddEditData(value);
    setError((preError) => ({
      ...preError,
      service_category: "",
    }));
  };

  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const {
        _id,
        vendor_first_name,
        vendor_last_name,
        vendor_email,
        vendor_commission,
        vendor_country_code,
        vendor_phone,
        vendor_gender,
        vendor_city,
        vendor_address,
        vendor_street,
        password,
        c_password,
      } = ADDEDITDATA;
      const ipAddress = await fetchIpAddress();

      const form = new FormData();
      if (_id && _id !== "undefined" && _id !== null) {
        form.append("edit_id", _id);
      } else {
        form.append("password", password);
        form.append("c_password", c_password);
      }
      form.append("first_name", vendor_first_name);
      form.append("last_name", vendor_last_name);
      form.append("email", vendor_email);
      form.append("commission", vendor_commission ? vendor_commission :'' );
      form.append("country_code", vendor_country_code);
      form.append("phone", vendor_phone);
      form.append("gender", vendor_gender ? vendor_gender : "Male");
      if (image.length > 0) {
        form.append("image", image[0]?.originFileObj);
      }
      form.append("city", vendor_city ? vendor_city : "");
      form.append("address", vendor_address ? vendor_address : "");
      form.append("street", vendor_street ? vendor_street : "");
      form.append("productCat", productCatAddEditData || []);
      form.append("serviceCat", serviceCatAddEditData || []);
      form.append("ipAddress", ipAddress);

      const isValidate = validateFormData(form);

      if (isValidate) {
        const options = {
          url: "accounts/vendors/addeditdata",
          postData: form,
        };
        const res = await addeditdata(options);
        if (res.status === true) {
          notification.open({
            message: "Data updated.",
            description: `Data updated Successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          navigate("/vendors/list");
        } else {
          notification.open({
            message: "Opps!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      } else {
        notification.open({
          message: "Opps!",
          description: `Please enter the required fields.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /*********************************************************
   *  This function is used to validate form data before submit
   *********************************************************/
  const validateFormData = (formData) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberPattern = /^[0-9]+$/;
    const vendor_country_code = formData.get("country_code");
  
    // Validate first name
    if (!formData.get("first_name")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        vendor_first_name: "First name is required",
      }));
      return false;
    }
  
    // Validate last name
    if (!formData.get("last_name")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        vendor_last_name: "Last name is required",
      }));
      return false;
    }
  
    // Validate email
    const email = formData.get("email")?.trim();
    if (!email) {
      setError((prevError) => ({
        ...prevError,
        vendor_email: "Email address is required",
      }));
      return false; // This should be false, not true.
    }
  
    if (!emailPattern.test(email)) {
      setError((prevError) => ({
        ...prevError,
        vendor_email: "Please enter a valid email address",
      }));
      return false;
    }
  
    // Validate commission
    if (!formData.get("commission")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        vendor_commission: "Commission is required",
      }));
      return false;
    }
  
    // Validate phone number based on country code
    const phone = formData.get("phone")?.trim();
    if (
      !phone ||
      (vendor_country_code === "+91" && phone.length !== 10) ||
      (vendor_country_code === "+971" && phone.length !== 7)
    ) {
      setError((prevError) => ({
        ...prevError,
        vendor_phone: !phone
          ? "Phone number is required"
          : `Phone number must be exactly ${vendor_country_code === "+91" ? 10 : 7} digits for ${vendor_country_code === "+91" ? "India" : "UAE"}`,
      }));
      return false;
    }
  
    // Validate gender
    if (!formData.get("gender")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        vendor_gender: "Please select any one",
      }));
      return false;
    }
  
    // Validate password and confirm password if not editing (i.e., if ADDEDITDATA is undefined)
    if (!ADDEDITDATA?._id) {
      const password = formData.get("password")?.trim();
      const confirmPassword = formData.get("c_password")?.trim();
  
      if (!password) {
        setError((prevError) => ({
          ...prevError,
          password: "Password is required",
        }));
        return false;
      }
  
      if (!confirmPassword) {
        setError((prevError) => ({
          ...prevError,
          c_password: "Confirm password is required",
        }));
        return false;
      }
  
      if (password !== confirmPassword) {
        setError((prevError) => ({
          ...prevError,
          password: "New password and confirm password don't match.",
        }));
        return false;
      }
  
      // Validate image upload
      if (!formData.get("image")) {
        setError((prevError) => ({
          ...prevError,
          image: "Image is required",
        }));
        return false;
      }
    }
  
    return true;
  };
  
  /*********************************************************
   *  This function is use to handle delete account
   *********************************************************/
  const handleDelete = async () => {
    try {
      const options = {
        url: `accounts/vendors/changestatus`,
        postData: {
          user_id: ADDEDITDATA?._id,
          status: "D",
        },
      };
      const res = await chnageStatus(options);
      if (res.status === true) {
        notification.open({
          message: "User deleted successfully.",
          description: `Data deleted Successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        navigate("/users/list");
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Opps!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
  };

  /*********************************************************
   *  This function is used for get all product categories
   *********************************************************/
  const getAssignProductCategories = async () => {
    setIsLoading(true);
    try {
      const productWhere = {
        url: "categories/products/assign-category",
        postData: {
          condition: { vendor: ADDEDITDATA?._id, status: "A" },
          select: { category: true, vendor: true, status: true },
        },
      };
      const productCat = await categoryList(productWhere);
      if (productCat.status === true) {
        const cat = productCat?.result.map(
          (item) => `${item?.category?.name}___${item?.category?._id}`
        );
        setProductCatAddEditData(cat);
        setIsLoading(false);
      } else {
        setProductCatAddEditData([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setProductCatAddEditData([]);
      setIsLoading(false);
    }
  }; // End og function

  /*********************************************************
   *  This function is used for get all product categories
   *********************************************************/
  const getProductCategories = async () => {
    try {
      setIsLoading(true);
      const productWhere = {
        url: "categories/products/list",
        postData: {
          condition: { status: "A" },
          select: { name: true },
        },
      };
      const productCat = await categoryList(productWhere);
      if (productCat && productCat.status && productCat.result) {
        const cat = productCat.result.map((item) => ({
          value: `${item.name}___${item._id}`,
          label: item.name,
        }));
        setProductCat(cat);
        setIsLoading(false);
      } else {
        setProductCat([]);
        setIsLoading(false);
      }
    } catch (error) {
      setProductCat([]);
      setIsLoading(false);
    }
  }; // End og function

  /*********************************************************
   *  This function is used for get all product categories
   *********************************************************/
  const getAssignServiceCategories = async () => {
    try {
      const serviceWhere = {
        url: "categories/services/assign-category",
        postData: {
          condition: { vendor: ADDEDITDATA?._id, status: "A" },
          select: { category: true, vendor: true, status: true },
        },
      };
      const serviceCat = await categoryList(serviceWhere);
      if (serviceCat && serviceCat.status && serviceCat.result) {
        const cat = serviceCat.result.map(
          (item) => `${item.category.name}___${item.category._id}`
        );
        setServiceCatAddEditData(cat);
      } else {
        setServiceCatAddEditData([]);
      }
    } catch (error) {
      setServiceCatAddEditData([]);
    }
  }; // End og function

  /*********************************************************
   *  This function is used for get all product categories
   *********************************************************/
  const getServiceCategories = async () => {
    try {
      setIsLoading(true);
      const productWhere = {
        url: "categories/services/list",
        postData: {
          condition: { status: "A" },
          select: { name: true },
        },
      };
      const serviceCat = await categoryList(productWhere);
      if (serviceCat && serviceCat.status && serviceCat.result) {
        const cat = serviceCat.result.map((item) => ({
          value: `${item.name}___${item._id}`,
          label: item.name,
        }));
        setServiceCat(cat);
        setIsLoading(false);
      } else {
        setServiceCat([]);
        setIsLoading(false);
      }
    } catch (error) {
      setServiceCat([]);
      setIsLoading(false);
    }
  }; // End of function

  useEffect(() => {
    if (ADDEDITDATA?._id) {
      getAssignProductCategories();
      getAssignServiceCategories();
    }
    getProductCategories();
    getServiceCategories();
    document.title = `Frisbee Super Admin || ${ADDEDITDATA?._id ? "Edit" : "Add"
      } Vendor`;
  }, []);
  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <div>
            <h5 className="inventory_edit_page_heading">
              {ADDEDITDATA?._id ? `Edit` : `Add`} Vendor
            </h5>
          </div>
          <Link to="/vendors/list">
            <div>
              <button type="button" className="btn btn-secondary">
                <ArrowLeftOutlined /> Back
              </button>
            </div>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form method="post" className="inventory_form_main_contnet">
                <div className="row inventory_form_main_content_form_section">
                  <div className="row">
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="vendor_first_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>First Name :{" "}
                      </label>
                      <input
                        title="Enter your first name."
                        placeholder="Enter your first name."
                        type="text"
                        name="vendor_first_name"
                        className="form-control store_input_field"
                        id="vendor_first_name"
                        value={ADDEDITDATA?.vendor_first_name}
                        onChange={handleChange}
                      />
                      {error.vendor_first_name ? (
                        <p style={{ color: "red" }}>
                          {error.vendor_first_name}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="vendor_last_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>Last Name :{" "}
                      </label>
                      <input
                        title="Enter your last name."
                        placeholder="Enter your last name."
                        type="text"
                        name="vendor_last_name"
                        className="form-control store_input_field"
                        id="vendor_last_name"
                        value={ADDEDITDATA?.vendor_last_name}
                        onChange={handleChange}
                      />
                      {error.vendor_last_name ? (
                        <p style={{ color: "red" }}>{error.vendor_last_name}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="vendor_email"
                        className="all_section_for_label_main_class  all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Email Address :
                      </label>
                      <input
                        title="Enter your email address."
                        placeholder="Enter your email address."
                        type="text"
                        name="vendor_email"
                        className="form-control store_input_field"
                        id="vendor_email"
                        value={ADDEDITDATA?.vendor_email}
                        readOnly={ADDEDITDATA?._id ? true : false}
                        onChange={handleChange}
                      />
                      {error.vendor_email ? (
                        <p style={{ color: "red" }}>{error.vendor_email}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="vendor_commission"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>FrisBee
                        Commission :
                      </label>
                      <input
                        title="Enter revenue in %."
                        placeholder="Enter revenue in %."
                        type="number"
                        name="vendor_commission"
                        className="form-control store_input_field"
                        id="vendor_commission"
                        value={ADDEDITDATA?.vendor_commission}
                        onChange={handleChange}
                      />
                      {error.vendor_commission ? (
                        <p style={{ color: "red" }}>
                          {error.vendor_commission}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-lg-9 col-md-6 col-sm-12 col-xs-12">
                      <label
                        htmlFor="vendor_phone"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>Phone Number:
                      </label>
                      <br />
                      <InputNumber
                        style={{ width: "100%" }}
                        onChange={handlePhoneChange}
                        addonBefore={
                          <Select
                            defaultValue={"+971"}
                            style={{ width: 150 }}
                            onChange={handlePhoneCodeChange}
                          >
                            <Option value="+971" selected={true}>
                              +971 (UAE)
                            </Option>
                            <Option value="+91">+91 (IND)</Option>
                          </Select>
                        }
                        value={ADDEDITDATA?.vendor_phone}
                        readOnly={ADDEDITDATA?._id ? true : false}
                      />
                      {error.vendor_phone ? (
                        <p style={{ color: "red" }}>{error.vendor_phone}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="from-group col-lg-3 col-md-6 col-sm-12 col-xs-12">
                      <label htmlFor="phone">
                        <span style={{ color: "red" }}>*</span>Gender :
                      </label>
                      <Flex vertical gap="middle" className="">
                        <div
                          className="verndor_dashboard_add_edit_button"
                          defaultValue="Male"
                          name="gender"
                          id="gender"
                          size="large"
                          onChange={onCheckedChange}
                        >
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="male">Male</label>
                                <input
                                  type="radio"
                                  id="male"
                                  name="gender"
                                  value="Male"
                                  className="form-check-input"
                                  checked={
                                    ADDEDITDATA?.vendor_gender === "Male"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                              <div className="vendor_dashbaord_gender_main_section">
                                <label htmlFor="female">Female</label>
                                <input
                                  type="radio"
                                  id="female"
                                  name="gender"
                                  value="Female"
                                  className="form-check-input "
                                  checked={
                                    ADDEDITDATA?.vendor_gender === "Female"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                    </div>
                  </div>
                  {!ADDEDITDATA?._id && (
                    <>
                      <div className="row">
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                          <label
                            htmlFor="password"
                            className="all_section_for_label_main_class"
                          >
                            <span style={{ color: "red" }}>*</span>New Password
                            :
                          </label>
                          <input
                            title="Enter your email address."
                            type="password"
                            name="password"
                            className="form-control store_input_field"
                            id="password"
                            value={ADDEDITDATA?.password}
                            onChange={handleChange}
                          />
                          {error.password ? (
                            <p style={{ color: "red" }}>{error.password}</p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                          <label
                            htmlFor="c_password"
                            className="all_section_for_label_main_class"
                          >
                            <span style={{ color: "red" }}>*</span>Confirm
                            Password :
                          </label>
                          <input
                            title="Enter your email address."
                            type="password"
                            name="c_password"
                            className="form-control store_input_field"
                            id="c_password"
                            value={ADDEDITDATA?.c_password}
                            onChange={handleChange}
                          />
                          {error.c_password ? (
                            <p style={{ color: "red" }}>{error.c_password}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {/* <hr /> */}
                  <div className="form-group vendor_Add_edit_data">
                    <label
                      htmlFor="vendor_city"
                      className="all_section_for_label_main_class"
                    >
                      Province/City/Area :
                    </label>
                    <input
                      title="Enter your Province/City/Area."
                      type="text"
                      name="vendor_city"
                      className="form-control store_input_field"
                      id="vendor_city"
                      value={ADDEDITDATA?.vendor_city}
                      onChange={handleChange}
                    />
                    {error.vendor_city ? (
                      <p style={{ color: "red" }}>{error.vendor_city}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label
                      htmlFor="vendor_state"
                      className="all_section_for_label_main_class"
                    >
                      Street :
                    </label>
                    <input
                      title="Enter street name."
                      type="text"
                      name="vendor_street"
                      className="form-control store_input_field"
                      id="vendor_streete"
                      value={ADDEDITDATA?.vendor_street}
                      onChange={handleChange}
                    />
                    {error.vendor_street ? (
                      <p style={{ color: "red" }}>{error.vendor_street}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label
                      htmlFor="vendor_address"
                      className="all_section_for_label_main_class"
                    >
                      Address(Building, Floor) :
                    </label>
                    <input
                      title="Enter Address(Building, Floor)"
                      type="text"
                      name="vendor_address"
                      className="form-control store_input_field"
                      id="vendor_address"
                      value={ADDEDITDATA?.vendor_address}
                      onChange={handleChange}
                    />
                    {error.vendor_address ? (
                      <p style={{ color: "red" }}>{error.vendor_address}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data admin_add_product_select_all_main_div">
                    <label
                      htmlFor="product_category"
                      className="all_section_for_label_main_class"
                    >
                      Add products categories :{" "}
                    </label>
                    <Select
                      mode="multiple"
                      placeholder="Select products categories"
                      variant="product_category"
                      name="product_category"
                      className="form-control store_input_field"
                      style={{ flex: 1 }}
                      value={productCatAddEditData}
                      onChange={handleProductcategory}
                      options={productCat}
                    />
                    {error.product_category ? (
                      <p style={{ color: "red" }}>{error.product_category}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data admin_add_product_select_all_main_div">
                    <label
                      htmlFor="service_category"
                      className="all_section_for_label_main_class"
                    >
                      Add services categories :{" "}
                    </label>
                    <Select
                      mode="multiple"
                      value={serviceCatAddEditData}
                      placeholder="Please select service category"
                      variant="service_category"
                      name="service_category"
                      className="form-control store_input_field"
                      onChange={handleServicesCategory}
                      style={{ flex: 1 }}
                      options={serviceCat}
                    />
                    {error.service_category ? (
                      <p style={{ color: "red" }}>{error.service_category}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="all_section_for_label_main_class"
                  >
                    {!ADDEDITDATA?._id && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                    Images
                  </label>
                  <Dragger
                    className="about_us_dragger_file"
                    maxCount="1"
                    // listType="picture"
                    onChange={handleImageChnage}
                    beforeUpload={beforeUpload}
                  >
                    <button
                      type="button"
                      className="about_us_dragger_file_button"
                    >
                      Upload file
                    </button>
                  </Dragger>
                  <p
                    style={{ color: "blue" }}
                    className="all_top_and_bottom_for_input_text"
                  >
                    *Note: Please select an image format (JPEG/JPG/PNG) and
                    ensure its dimensions are 420px x 280px.
                  </p>
                  {error.image ? (
                    <p style={{ color: "red" }}>{error.image}</p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="inventory_edit_submit_btn">
                  <button
                    type="button"
                    className="btn btn-primary inventory_save_button"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <LoadingEffect isLoading={isLoading} />
    </div>
  );
};

export default UserListEditPage;
