import React, { useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { FaDesktop, FaArrowLeft } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addEditData } from "../../../controllers/Products/product";
import { notification, Switch, Upload } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import { list as categoryList } from "../../../controllers/categories/products";
import { list } from "../../../controllers/subcategories/products";
import ProductListDeletePopUp from "./ProductListDeletePopUp";

import { fetchIpAddress } from "../../../controllers/API";
import { beforeUpload } from '../../../controllers/common';

function ProductListEditPage() {
  const navigate = useNavigate();
  const data = useLocation().state;
  const [dataForm, setFormData] = useState({
    name: data?.name || "",
    details: data?.details || "",
    image: data?.image || "",
    status: "A",
    ipAddress: data?.creation_ip || "",
  });
  const { Dragger } = Upload;
  const [error, setError] = useState([]);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [imageList, setImageList] = useState("");
  const [categories, setcategory] = useState([]);
  const [categoryId, setcategoryId] = useState(data?.category._id ?? "");
  const [subCategoryId, setSubCategoryId] = useState(
    data?.subcategory._id ?? ""
  );
  const [subCategories, setSubCategories] = useState([]);
  const [isAddPage, setIsAddPage] = useState(data ? false : true);

  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  /*********************************************************
   *  This function is use to handle success notification show
   *********************************************************/
  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data saved Successfully .",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  }; //End
  /*********************************************************
   *  This function is use to handle error notification show
   *********************************************************/
  const handleErrorMsg = () => {
    notification.open({
      message: "Opps!",
      description: error?.formError,
      placement: "topRight",
      icon: <CloseOutlined twoToneColor="#fc2339" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  }; //End

  /*********************************************************
   *  This function is use to handle cancle button and close popup
   *********************************************************/
  const handleCancel = () => {
    setFormData("");
    setImageList("");
  };
  /*********************************************************
   *  This function is use to handle image object
   *********************************************************/
  const handleFileChange = (e) => {
    setImageList(e.fileList[0].originFileObj);
  };

  /*********************************************************
   *  This function is use to fetch category list
   *********************************************************/
  const getCategoryList = async () => {
    const options = {
      type: "",
      condition: { status: "A" },
      select: { name: true },
      sort: { name: 1 },
      populate: { key: "", select: "" },
    };
    const listData = await categoryList(options);
    if (listData.status === true) {
      setcategory(listData?.result);
    } else {
      setcategory([]);
    }
  }; //End

  /*********************************************************
   *  This function is use to fetch sub category list
   *********************************************************/
  const getSubCategories = async (carId) => {
    setSubCategories([]);
    const options = {
      type: "",
      condition: {
        categoryId: carId,
      },
      select: {},
      sort: { _id: -1 },
      populate: { key: "categoryId", select: "name" },
    };
    const listData = await list(options);
    console.log('listData',listData);
    if (listData.status === true) {
      setSubCategories(listData?.result);
    } else {
      setSubCategories([]);
    }
  }; //End

  /*********************************************************
   *  This function is use to handle input chnage and set in state
   *********************************************************/
  const handleChange = (e) => {
    if (e.target.name === "category") {
      setcategoryId(e.target.value);
      getSubCategories(e.target.value);
      setSubCategoryId("");
      setError("");
    } else if (e.target.name === "subCategory") {
      setSubCategoryId(e.target.value);
      setError("");
    } else {
      setFormData((pre) => ({
        ...pre,
        [e.target.name]: e.target.value,
      }));

      setError((pre) => ({
        ...pre,
        [e.target.name]: "",
      }));
    }
  }; //End

  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("name", dataForm?.name || "");
    form.append("category", categoryId ? categoryId : data?.category._id ?? "");
    form.append(
      "subcategory",
      subCategoryId ? subCategoryId : data?.subcategory._id ?? ""
    );
    form.append("details", dataForm?.details || "");
    form.append("image", imageList ?? "");
    form.append("status", "A");
    form.append("ipAddress", await fetchIpAddress());

    const isValidate = validateFormData(form);
    console.log('isValidate',isValidate);
    if (isValidate) {
      const params = {
        url: `poductsmaster/addeditdata/${data ? data._id : ":editId"}`,
        postData: form,
      };
      const res = await addEditData(params); 
      if (res.status === true) {
        if (isAddPage === true) {
          setFormData("");
          setImageList("");
          handleSuccessMsg();
          handleCancel();
        }
        navigate("/product/list");
      } else {
        setError((pre) => ({
          ...pre,
          formError: res?.message,
        }));

        handleErrorMsg();
        setFormData("");
        setImageList("");
        handleCancel();
        navigate("/product/list");
      }
    }
  }; //End

  // *  This function is use to validate form data before submit
  // *********************************************************/
  const validateFormData = (formData) => {
    let isValid = true;
    const name = formData.get("name");
    const details = formData.get("details");
    console.log('sdfsdf',!/^\d+$/.test(name));
    if (!name) {
      setError((prevError) => ({
        ...prevError,
        name: "Name is required",
      }));
      isValid = false;
    } else if (/^\d+$/.test(name)) {
      setError((prevError) => ({
        ...prevError,
        name: "Name should not contain only numeric characters",
      }));
      isValid = false;
    } else if (name.trim() === "") {
      setError((prevError) => ({
        ...prevError,
        name: "Name should not be blank",
      }));
      isValid = false;
    }
    if (!details) {
      setError((prevError) => ({
        ...prevError,
        details: "Details are required",
      }));
      isValid = false;
    } else if (/^\d+$/.test(details)) {
      setError((prevError) => ({
        ...prevError,
        details: "Details should not contain only numeric characters",
      }));
      isValid = false;
    } else if (details.trim() === "") {
      setError((prevError) => ({
        ...prevError,
        details: "Details should not be blank",
      }));
      isValid = false;
    }

    if (!data?._id && formData.get("image") === null) {
      setError((prevError) => ({
        ...prevError,
        image: "Image is required",
      }));
      isValid = false;
    }
    if (formData.get("category") === null || formData.get("category") === "") {
      setError((prevError) => ({
        ...prevError,
        category: "category is required",
      }));
      isValid = false;
    }
    if (
      formData.get("subcategory") === null ||
      formData.get("subcategory") === ""
    ) {
      setError((prevError) => ({
        ...prevError,
        subcategory: "sub catergory is required",
      }));
      isValid = false;
    } else {
      return isValid;
    }
  }; //End

  useEffect(() => {
    getCategoryList();
    if(data?.subcategory?._id){
      // console.log('data.subcategory._id',data.subcategory._id)
      getSubCategories(data?.categoryId?._id)
    }
  }, []);

  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <div>
            <h5 className="inventory_edit_page_heading">
              {isAddPage ? "Add" : "Edit"} Product{" "}
            </h5>
          </div>
          <Link to={`/product/list`}>
            <div>
              <button type="button" className="btn btn-secondary">
                <FaArrowLeft /> Back
              </button>
            </div>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          {/* <div className="card-header py-0 all_border_bottom_for_hide">
            <div>
              <h6 className="m-0 font-weight-bold text-primary"></h6>
            </div>
            {isAddPage ? (
              ""
            ) : (
              <div>
                <button
                  type="button"
                  className="btn btn-danger all_save_btn_btn_btn"
                  onClick={togglePopUp}
                >
                  Delete Data
                </button>
              </div>
            )}
          </div> */}
          <div className="card-body">
            <div className="responsive">
              <form action="" >
                <div className="form-group">
                  <label htmlFor="exampleInputText" className="all_vendor_radio_button_div">Product Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control categories_select_tg_enter_product"
                    id="exampleInputText"
                    value={dataForm.name}
                    onChange={handleChange}
                  />
                  {error?.name ? (
                    <p style={{ color: "red" }}>{error.name}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputText" className="all_vendor_radio_button_div">Product Details</label>
                  <input
                    type="text"
                    name="details"
                    className="form-control categories_select_tg_enter_product"
                    id="exampleInputText"
                    value={dataForm.details}
                    onChange={handleChange}
                  />
                  {error?.details ? (
                    <p style={{ color: "red" }}>{error.details}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div class="form-group">
                  <div class="form-group">
                    <label
                      for="categoryId"
                      className="all_vendor_radio_button_div"
                    >
                      Select Category
                    </label>
                    <select
                      value={categoryId || ""}
                      name="category"
                      id="categoryId"
                      className="form-control categories_select_tg_enter_product"
                      onChange={handleChange}
                    >
                      {isAddPage ? (
                        <option value="">Select Category</option>
                      ) : (
                        ""
                      )}
                      {categories &&
                        categories.map((item, index) => (
                          <option
                            key={index}
                            value={item?._id}
                            selected={
                              data?.categoryId?._id === item?._id ? true : false
                            }
                          >
                            {" "}
                            {item?.name}
                          </option>
                        ))}
                    </select>
                    {error?.category ? (
                      <p style={{ color: "red" }}>{error.category}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-group">
                    <label
                      for="categoryId"
                      className="all_vendor_radio_button_div"
                    >
                      Select Sub Category
                    </label>
                    <select
                      // value={subCategoryId}
                      name="subCategory"
                      id="categoryId"
                      className="form-control categories_select_tg_enter_product"
                      onChange={handleChange}
                    >
                      {isAddPage ? (
                        <option value="">Select Sub Category</option>
                      ) : (
                        ""
                      )}
                      {subCategories &&
                        subCategories.map((item, index) => (
                          <option
                            key={index}
                            value={item?._id}
                            selected={
                              data?.subcategory?._id === item?._id ? true : false
                            }
                          >
                            {" "}
                            {item?.name}
                          </option>
                        ))}
                    </select>
                    {error?.subcategory ? (
                      <p style={{ color: "red" }}>{error.subcategory}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Description</label>
                  <ReactQuill className="inventory_react_quill" />
                </div> */}
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1" className="all_vendor_radio_button_div">Images</label>
                  <Dragger
                    className="about_us_dragger_file"
                    onChange={handleFileChange}
                  >
                    {/* <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p> */}
                    <button
                      type="button"
                      className="about_us_dragger_file_button"
                    >
                      Upload file
                    </button>
                    {/* <p className="ant-upload-hint">
                      Support for a single or bulk upload. Strictly prohibited
                      from uploading company data or other banned files.
                    </p> */}
                    <p style={{color:"blue"}}>*Note: Please choose an image of JPEG/JPG/PNG format file.</p>
                  </Dragger>
                </div>
                <div className="inventory_edit_submit_btn">
                  <button
                    type="button"
                    className="btn btn-primary inventory_save_button all_save_btn_btn_btn"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ProductListDeletePopUp
        popUpOpen={popUpOpen}
        EDITDATA={data}
        togglePopUp={togglePopUp}
      />
    </div>
  );
}

export default ProductListEditPage;
