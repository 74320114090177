import { API_ADMIN_URL } from "../../config/constants";
import { postRequest } from "../API";


/*********************************************************
* Function Name : list
* Description   : list of email templets
* By            : Afsar Ali
* Date          : 13-July-2024 
*********************************************************/
export const list = async(options) => {
    try{
        const params = {
            url: `${API_ADMIN_URL}cms/email-templets/list`,
            postData: options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status: true, result: res?.data?.response?.result, count : res?.data?.response?.count};
        }else{
            return {status: false, mesasge: res?.response?.data?.statusMessage }
        }
    }catch(error){
        console.log("Error", error);
        return {status: false, message: "Under MAintenance, Please try after some time"}
    }
}

/*********************************************************
* Function Name : addEditData
* Description   : Add/Edit Data in Email templets
* By            : Afsar Ali
* Date          : 13-July-2024 
*********************************************************/
export const addEditData = async (options) => {
    try {
        const params = {
            url: `${API_ADMIN_URL}cms/email-templets/addeditdata`,
            postData: options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return { status: true, result: res?.data?.response?.result};
        }else{
            return {status: false, message: res?.response?.data?.statusMessage}
        }
    }
    catch (error) {
     console.log("Error", error);
     return {status: false, mesasge: "Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : chnageStatus
* Description   : Chnage Status of email templets
* By            : Afsar Ali
* Date          : 13-July-2024 
*********************************************************/
export const chnageStatus = async (options) => {
    try {
        const params = {
            url: `${API_ADMIN_URL}cms/email-templets/change-status`,
            postData: options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return { status: true, result: res?.data?.response?.result};
        }else{
            return {status: false, message: res?.response?.data?.statusMessage}
        }
    }
    catch (error) {
     console.log("Error", error);
     return {status: false, mesasge: "Under Maintenance, Please try after some time."}
    }
};//End of Function

