import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import Slide from "@mui/material/Slide";
import "../BookingDetails.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { notification } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import moment from "moment";
import {
  availableSlots,
  rescheduleBooking,
} from "../../../controllers/bookings/boardingControllers";
import { Link, useNavigate } from "react-router-dom";
import LoadingEffect from "../../../components/Loading/LoadingEffect";

const BookingFilterDrawer = ({
  drawerOpen,
  RequesttoggleDrawer,
  bookingData,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date());

  const [fromBookingSlots, setFromBookingSlots] = useState([]);
  const [toBookingSlots, setToBookingSlots] = useState([]);
  const [appointmentTime, setAppointmentTime] = useState("");

  const [from_date, setFromDate] = useState("");
  const [from_time, setFromTime] = useState("");
  const [to_date, setToDate] = useState("");
  const [to_time, setToTime] = useState("");

  const onChange = (value, event) => {
    const t1 = moment(date).format("YYYY-MM-DD");
    const t2 = moment(value).format("YYYY-MM-DD");
    if (t1 !== t2) {
      setAppointmentTime("");
      const now = new Date();
      const time = `${now.getHours()}:${now.getMinutes()}`;
      const tt = moment(`${t2} ${time}`).format("YYYY-MM-DD HH:mm:ss");
      // console.log(tt);
      setFromDate(new Date(tt));
    }
  };

  const getFromTimeSlot = async () => {
    try {
      setIsLoading(true);
      // Get the today store time
      let open_time = "08:00";
      let close_time = "20:00";
      const daysOfWeek = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const currentDay = daysOfWeek[from_date.getDay()];
      const currentDayObject = bookingData?.shopId?.store_time.find(
        (item) => item.day === currentDay
      );
      const currentOpenTime = new Date(
        `2000-01-01T${currentDayObject.open_time}`
      );
      const currentTime = from_date.getHours() * 60 + from_date.getMinutes();
      const openHour =
        currentOpenTime.getHours() * 60 + currentOpenTime.getMinutes();

      if (currentTime >= openHour) {
        const now = moment(new Date()).format("YYYY-MM-DD");
        const currentDate = moment(from_date).format("YYYY-MM-DD");
        if (now === currentDate) {
          open_time = `${from_date.getHours()}:${from_date.getMinutes()}`;
        } else {
          open_time = "08:00";
        }
        close_time = currentDayObject.close_time;

        const slots = [];
        // Parse open and close time strings
        const openTimeParts = open_time
          .split(":")
          .map((part) => parseInt(part));
        const closeTimeParts = close_time
          .split(":")
          .map((part) => parseInt(part));

        let hour = openTimeParts[0];
        let minute = openTimeParts[1];

        // If the minute part is not 0, increment hour
        if (minute !== 0) {
          hour++;
          minute = 0;
        }
        // Generate slots
        while (
          hour < closeTimeParts[0] ||
          (hour === closeTimeParts[0] && minute <= closeTimeParts[1])
        ) {
          // Format hour and minute
          const hourStr = hour.toString().padStart(2, "0");
          const minuteStr = minute.toString().padStart(2, "0");

          // Add slot to list
          slots.push({ time: `${hourStr}:${minuteStr}`, status: "Available" });

          hour++;
          // minute = 0;
        }
        setFromBookingSlots(slots);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log("The store is closed.", close_time);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (bookingData) {
  //     getFromTimeSlot();
  //   }
  // }, [from_date]);

  const handleFormTimeChnage = (item) => {
    if (item.status === "Available") {
      setFromTime(item.time);
    }
  };

  const onChangeToDate = (value, event) => {
    const t1 = moment(date).format("YYYY-MM-DD");
    const t2 = moment(value).format("YYYY-MM-DD");
    if (t1 !== t2) {
      setAppointmentTime("");
      const now = new Date();
      const time = `${now.getHours()}:${now.getMinutes()}`;
      const tt = moment(`${t2} ${time}`).format("YYYY-MM-DD HH:mm:ss");
      // console.log(tt);
      setToDate(new Date(tt));
    }
  };

  const getToTimeSlot = async () => {
    try {
      setIsLoading(true);
      // Get the today store time
      let open_time = "08:00";
      let close_time = "20:00";
      const daysOfWeek = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const currentDay = daysOfWeek[to_date.getDay()];
      const currentDayObject = bookingData?.shopId?.store_time.find(
        (item) => item.day === currentDay
      );
      const currentOpenTime = new Date(
        `2000-01-01T${currentDayObject.open_time}`
      );
      const currentTime = to_date.getHours() * 60 + to_date.getMinutes();
      const openHour =
        currentOpenTime.getHours() * 60 + currentOpenTime.getMinutes();

      if (currentTime >= openHour) {
        const now = moment(new Date()).format("YYYY-MM-DD");
        const currentDate = moment(to_date).format("YYYY-MM-DD");
        if (now === currentDate) {
          open_time = `${to_date.getHours()}:${to_date.getMinutes()}`;
        } else {
          open_time = "08:00";
        }
        close_time = currentDayObject.close_time;

        const slots = [];
        // Parse open and close time strings
        const openTimeParts = open_time
          .split(":")
          .map((part) => parseInt(part));
        const closeTimeParts = close_time
          .split(":")
          .map((part) => parseInt(part));

        let hour = openTimeParts[0];
        let minute = openTimeParts[1];

        // If the minute part is not 0, increment hour
        if (minute !== 0) {
          hour++;
          minute = 0;
        }
        // Generate slots
        while (
          hour < closeTimeParts[0] ||
          (hour === closeTimeParts[0] && minute <= closeTimeParts[1])
        ) {
          // Format hour and minute
          const hourStr = hour.toString().padStart(2, "0");
          const minuteStr = minute.toString().padStart(2, "0");

          // Add slot to list
          slots.push({ time: `${hourStr}:${minuteStr}`, status: "Available" });

          hour++;
          // minute = 0;
        }
        setToBookingSlots(slots);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log("The store is closed.", close_time);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (bookingData) {
  //     getToTimeSlot();
  //   }
  // }, [to_date]);

  const handleToTimeChnage = (item) => {
    if (item.status === "Available") {
      setToTime(item.time);
    }
  };

  const submitHandler = async () => {
    try {
      const options = {
        booking_id: bookingData._id,
        from_date: moment(from_date).format("YYYY-MM-DD"),
        // from_time: from_time,
        to_date: moment(to_date).format("YYYY-MM-DD"),
        // to_time: to_time,
      };
      const res = await rescheduleBooking(options);
      if (res.status === true) {
        notification.open({
          message: "Reschedule  Successfully",
          description: "Appointment reschedule successfully .",
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
        });
        navigate("/bookings/boarding/list");
      } else {
        notification.open({
          message: "Oops!",
          description: "Error while appointment reschedule, Please try again.",
          placement: "topRight",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      notification.open({
        message: "Error!",
        description: "Please try again after some time.",
        placement: "topRight",
        icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      });
    }
  };
  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={RequesttoggleDrawer}
      className="reuest_filter_drawer_main_section"
    >
      {/* Select From Date & Time */}
      <Slide direction="left" in={drawerOpen}>
        <div className="myDrawer drawer_for_width_re_schedule">
          <div className="myMainDrawerClass">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              From :
            </Typography>

            <div>
              <IconButton onClick={RequesttoggleDrawer}>
                <IoMdClose />
              </IconButton>
            </div>
          </div>

          <Typography variant="body1" component="div">
            <div className="re_schedule_main_section">
              <div className=" row re_schedule_main_section_child_section">
                <Calendar
                  minDate={new Date()}
                  onChange={onChange}
                  value={from_date}
                  className="react_calander_in_sign_up"
                />
                {/* <div className="row">
                  <div className="container vendor_reschedule_card_section">
                    <div className="row re_schedule_card_all_section">
                      {fromBookingSlots?.length > 0
                        ? fromBookingSlots.map((item) => (
                            <>
                              {item.time === from_time ? (
                                <>
                                  <div className="col-sm-4">
                                    <div className="card re_schedule_child_card re_schedule_child_card_first_card">
                                      <div className="card-body">
                                        <Link
                                          to={false}
                                          className="time_link_vendor"
                                        >
                                          {moment(
                                            `2020-04-23 ${item.time}`
                                          ).format("hh:mm A")}
                                        </Link>
                                      </div>
                                      <span>{item.status}</span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-sm-4">
                                    <div
                                      className={`card re_schedule_child_card ${
                                        item.status !== "Available"
                                          ? "re_schedule_child_card_second_child"
                                          : ""
                                      }`}
                                      onClick={() => handleFormTimeChnage(item)}
                                    >
                                      <div className="card-body">
                                        <Link
                                          to={false}
                                          className="time_link_vendor_tag"
                                        >
                                          {moment(
                                            `2020-04-23 ${item.time}`
                                          ).format("hh:mm A")}
                                        </Link>
                                      </div>
                                      <span>{item.status}</span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          ))
                        : null}
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </Typography>
        </div>
      </Slide>
      {/* Select To Date & Time */}
      <Slide direction="left" in={drawerOpen}>
        <div className="myDrawer drawer_for_width_re_schedule">
          <div className="myMainDrawerClass">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              To :
            </Typography>
          </div>

          <Typography variant="body1" component="div">
            <div className="re_schedule_main_section">
              <div className=" row re_schedule_main_section_child_section">
                <Calendar
                  minDate={new Date()}
                  onChange={onChangeToDate}
                  value={to_date}
                  className="react_calander_in_sign_up"
                />
                {/* <div className="row">
                  <div className="container vendor_reschedule_card_section">
                    <div className="row re_schedule_card_all_section">
                      {toBookingSlots?.length > 0
                        ? toBookingSlots.map((item) => (
                            <>
                              {item.time === to_time ? (
                                <>
                                  <div className="col-sm-4">
                                    <div className="card re_schedule_child_card re_schedule_child_card_first_card">
                                      <div className="card-body">
                                        <Link
                                          to={false}
                                          className="time_link_vendor"
                                        >
                                          {moment(
                                            `2020-04-23 ${item.time}`
                                          ).format("hh:mm A")}
                                        </Link>
                                      </div>
                                      <span>{item.status}</span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-sm-4">
                                    <div
                                      className={`card re_schedule_child_card ${
                                        item.status !== "Available"
                                          ? "re_schedule_child_card_second_child"
                                          : ""
                                      }`}
                                      onClick={() => handleToTimeChnage(item)}
                                    >
                                      <div className="card-body">
                                        <Link
                                          to={false}
                                          className="time_link_vendor_tag"
                                        >
                                          {moment(
                                            `2020-04-23 ${item.time}`
                                          ).format("hh:mm A")}
                                        </Link>
                                      </div>
                                      <span>{item.status}</span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          ))
                        : null}
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  <button
                    className="btn btn-danger all_save_btn_btn_btn"
                    onClick={submitHandler}
                  >
                    Re-Schedule
                  </button>
                </div>
              </div>
            </div>
          </Typography>
        </div>
      </Slide>
      <LoadingEffect isLoading={isLoading} />
    </Drawer>
  );
};

export default BookingFilterDrawer;

// BY Deafult Open
