import React, { useEffect, useState } from "react";
import { IoSettingsOutline, IoNotificationsOffSharp } from "react-icons/io5";
import { MdOutlinePayment } from "react-icons/md";
import { IoMdStarOutline } from "react-icons/io";
import { BiSupport } from "react-icons/bi";
import { CiDiscount1 } from "react-icons/ci";
import { NavLink } from "react-router-dom";
import { TbCategory } from "react-icons/tb";
import { MdOutlineInventory2 } from "react-icons/md";
import { IoHomeOutline } from "react-icons/io5";
import { RiBarChartHorizontalLine } from "react-icons/ri";
import { CiMail } from "react-icons/ci";
// import SidebarLogo from "../Image/SideBarIcon.png";
import SidebarLogo from "../../Image/SideBarIcon.png";
import { CiShoppingBasket } from "react-icons/ci";
import { FaGreaterThan } from "react-icons/fa";
import { VscAccount } from "react-icons/vsc";
import { TbPointFilled } from "react-icons/tb";
import { Collapse } from "antd";
import "./SideBar.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

import {
  getRandomColor,
  createImageFromInitials,
} from "../../controllers/common";
import { ASSETS_BASE_URL } from "../../config/constants";

const { Panel } = Collapse;

const SidebarItem = ({ to, icon, name, isOpen, className }) => (
  <NavLink to={to} className={`link ${className}`} activeClassName="active">
    <div className="icon">{icon}</div>
    <div style={{ display: isOpen ? "block" : "none" }} className="link_text">
      {name}
    </div>
  </NavLink>
);

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleCollapseToggle = () => {
    setIsOpen(!isOpen);
  };
  const user = JSON.parse(sessionStorage.getItem("ADMIN-INFO"));
  const [USERDATA, setUserData] = useState(user || "");

  
  const [PERMISSIONDATA, setPermissionData] = useState('');

  useEffect(()=>{
    if(sessionStorage.getItem("ADMIN-PERMISSION") !== 'undefined'){
      const permission = JSON.parse(sessionStorage.getItem("ADMIN-PERMISSION"));
      setPermissionData(permission);
    }
  },[])

  return (
    <div
      className={`container-fluid side_bar_main_div ${
        isOpen ? "sidebar-open" : "sidebar-closed"
      }`}
    >
      <div
        style={{ width: isOpen ? "300px" : "70px", position: "relative " }}
        className="sidebar"
      >
        <div className="top_section">
          <img
            src={SidebarLogo}
            className="sidebar_logo"
            alt="Logo"
            style={{
              display: isOpen ? "block" : "none",
              height: "50px",
              width: "auto",
            }}
          />
          <div
            style={{ marginLeft: isOpen ? "130px" : "0px" }}
            className="bars"
          >
            <RiBarChartHorizontalLine onClick={handleCollapseToggle} />
          </div>
        </div>
        <div className="my_sidebar_all_section">
          <SidebarItem
            to="/dashboard"
            icon={<IoHomeOutline />}
            name="Dashboard"
            isOpen={isOpen}
          />
          <React.Fragment>
          {(PERMISSIONDATA?.accounts?.user === 'Y' || PERMISSIONDATA?.accounts?.vendor === 'Y' || user?.admin_type === 'Super Admin') && (
            <Collapse accordion className="my_collapse_icon">
              <Panel
                header={
                  <React.Fragment>
                    <VscAccount className="sidebar_collapse_iohomeoutline" />
                    <span className={`sidebar_collapse_iohomeoutline_categoires ${isOpen ? "visible" : "hidden"}`}> Accounts </span>
                  </React.Fragment>
                }
                key="categories"
                className={`side_bar_categories ${isOpen ? "arrow-visible" : "arrow-hidden"}`}
              >
                {user?.admin_type === 'Super Admin' &&(
                  <SidebarItem to="/sub-admin/list" icon={<TbPointFilled />} name="Sub Admin" isOpen={isOpen} />
                )}
                {(PERMISSIONDATA?.accounts?.user === 'Y' || user?.admin_type === 'Super Admin') && (
                  <SidebarItem to="/users/list" icon={<TbPointFilled />} name="Users" isOpen={isOpen}/>
                )}
                {(PERMISSIONDATA?.accounts?.vendor === 'Y' || user?.admin_type === 'Super Admin') && (
                  <SidebarItem to="/vendors/list" icon={<TbPointFilled />} name="Vendors" isOpen={isOpen}/>
                )}
              </Panel>
            </Collapse>
          )}
          </React.Fragment>

          {(
            PERMISSIONDATA?.category?.product === 'Y' || 
            PERMISSIONDATA?.category?.request === 'Y' || 
            PERMISSIONDATA?.category?.service === 'Y' ||
            user?.admin_type === 'Super Admin') && (
            <React.Fragment>
              <Collapse accordion className="my_collapse_icon">
                <Panel
                  header={
                    <React.Fragment>
                      <TbCategory className="sidebar_collapse_iohomeoutline" />
                      <span
                        className={`sidebar_collapse_iohomeoutline_categoires ${
                          isOpen ? "visible" : "hidden"
                        }`}
                      >
                        Categories
                      </span>
                    </React.Fragment>
                  }
                  key="categories"
                  className={`side_bar_categories ${
                    isOpen ? "arrow-visible" : "arrow-hidden"
                  }`}
                >
                  {(PERMISSIONDATA?.category?.product === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/categories/product/list" icon={<TbPointFilled />} name="Products" isOpen={isOpen}/>
                  )}
                  {(PERMISSIONDATA?.category?.service === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/categories/service/list" icon={<TbPointFilled />} name="Services" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.category?.service === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/categories/request/list" icon={<TbPointFilled />} name="Request" isOpen={isOpen} />
                  )}
                </Panel>
              </Collapse>
            </React.Fragment>
          )}

          {(
            PERMISSIONDATA?.cms?.banner === 'Y' || 
            PERMISSIONDATA?.cms?.treats === 'Y' || 
            PERMISSIONDATA?.cms?.breeds === 'Y' ||
            PERMISSIONDATA?.cms?.generalData === 'Y' ||
            PERMISSIONDATA?.cms?.term_condition === 'Y' ||
            PERMISSIONDATA?.cms?.provacyPolicy === 'Y' ||
            PERMISSIONDATA?.cms?.reason === 'Y' ||
            user?.admin_type === 'Super Admin'
          ) && (
            <React.Fragment>
              <Collapse accordion className="my_collapse_icon">
                <Panel
                  header={
                    <React.Fragment>
                      <TbCategory className="sidebar_collapse_iohomeoutline" />
                      <span
                        className={`sidebar_collapse_iohomeoutline_categoires ${isOpen ? "visible" : "hidden"}`} >CMS</span>
                    </React.Fragment>
                  }
                  key="categories"
                  className={`side_bar_categories ${
                    isOpen ? "arrow-visible" : "arrow-hidden"
                  }`}
                >
                  {(PERMISSIONDATA?.cms?.banner === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/cms/banner" icon={<TbPointFilled />} name="Banner" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.cms?.term_condition === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/cms/term-condition" icon={<TbPointFilled />} name="Term And Condoition" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.cms?.provacyPolicy === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/cms/privacy-policy" icon={<TbPointFilled />} name="Privacy Policy" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.cms?.treats === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/cms/treats" icon={<TbPointFilled />} name="Treats" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.cms?.breeds === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/cms/breeds" icon={<TbPointFilled />} name="Breeds" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.cms?.generalData === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/cms/general-data" icon={<TbPointFilled />} name="General Data" isOpen={isOpen} />
                  )}
                     {(PERMISSIONDATA?.cms?.generalData === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/cms/blogs" icon={<TbPointFilled />} name="Blogs" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.cms?.emailTemplates === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/cms/email-templates" icon={<TbPointFilled />} name="Email Templates" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.cms?.reason === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/cms/reasons" icon={<TbPointFilled />} name="Reasons" isOpen={isOpen} />
                  )}
              </Panel>
              </Collapse>
            </React.Fragment>
          )}

          {(PERMISSIONDATA?.subCategory?.products === 'Y' || user?.admin_type === 'Super Admin') && (
            <React.Fragment>
              <Collapse accordion className="my_collapse_icon">
                <Panel
                  header={
                    <div>
                      <TbCategory className="sidebar_collapse_iohomeoutline" />
                      <span
                        className={`sidebar_collapse_iohomeoutline_categoires ${
                          isOpen ? "visible" : "hidden"
                        }`}
                      >
                        Sub Categories
                      </span>
                    </div>
                  }
                  key="categories"
                  className={`side_bar_categories ${
                    isOpen ? "arrow-visible" : "arrow-hidden"
                  }`}
                >
                  {(PERMISSIONDATA?.subCategory?.products === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/subcategories/product/list" icon={<TbPointFilled />} name="Products" isOpen={isOpen} />
                  )}
                  {/* <SidebarItem to="/subcategories/service/list" icon={<TbPointFilled />} name="Services" isOpen={isOpen} ></SidebarItem> */}
                </Panel>
              </Collapse>
            </React.Fragment>
          )}

          {/* <div>
          <Collapse accordion className="my_collapse_icon">
            <Panel
              header={
                <div>
                  <TbCategory className="sidebar_collapse_iohomeoutline" />
                  <span className={`sidebar_collapse_iohomeoutline_categoires ${isOpen ? "visible" : "hidden"}`} >
                    Sub-Subcategories
                  </span>
                </div>
              }
              key="categories"
              className={`side_bar_categories ${isOpen ? "arrow-visible" : "arrow-hidden"}`}
            >
              <SidebarItem to="/subcategories/product/list" icon={<TbPointFilled />} name="Products" isOpen={isOpen} ></SidebarItem>
            </Panel>
          </Collapse>
        </div> */}
          {(PERMISSIONDATA?.products?.products === 'Y' || PERMISSIONDATA?.products?.request === 'Y' || user?.admin_type === 'Super Admin') && (
            <React.Fragment>
              <Collapse accordion className="my_collapse_icon">
                <Panel
                  header={
                    <React.Fragment>
                      <VscAccount className="sidebar_collapse_iohomeoutline" />
                      <span className={`sidebar_collapse_iohomeoutline_categoires ${isOpen ? "visible" : "hidden"} `}>Products</span>
                    </React.Fragment>
                  }
                  key="categories"
                  className={`side_bar_categories ${
                    isOpen ? "arrow-visible" : "arrow-hidden"
                  }`}
                >
                  {(PERMISSIONDATA?.products?.products === 'Y'|| user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/product/list" icon={<TbPointFilled />} name="Product List" isOpen={isOpen} className="side_bar_account_user_list" />
                  )}
                </Panel>
              </Collapse>
            </React.Fragment>
          )}
          {(PERMISSIONDATA?.orders?.orders === 'Y' || user?.admin_type === 'Super Admin') && (
            <SidebarItem
              to="/order/list"
              icon={<CiShoppingBasket />}
              name="Orders"
              isOpen={isOpen}
            />
          )}
          {(PERMISSIONDATA?.inventory?.inventory === 'Y' || PERMISSIONDATA?.inventory?.request === 'Y' || user?.admin_type === 'Super Admin') && (
            <React.Fragment>
              <Collapse accordion className="my_collapse_icon">
                <Panel
                  header={
                    <React.Fragment>
                      <MdOutlineInventory2 className="sidebar_collapse_iohomeoutline" />
                      <span
                        className={`sidebar_collapse_iohomeoutline_categoires ${
                          isOpen ? "visible" : "hidden"
                        }`}
                      >
                        Inventory
                      </span>
                    </React.Fragment>
                  }
                  key="inventory"
                  className={`side_bar_categories ${
                    isOpen ? "arrow-visible" : "arrow-hidden"
                  }`}
                >
                  {(PERMISSIONDATA?.inventory?.inventory === 'Y'|| user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/inventory/list" icon={<TbPointFilled />} name="Inventory" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.inventory?.request === 'Y'|| user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/inventory/request-list" icon={<TbPointFilled />} name="Request" isOpen={isOpen} />
                  )}
                </Panel>
              </Collapse>
            </React.Fragment>
          )}
          {(
            PERMISSIONDATA?.services?.grooming === 'Y' || 
            PERMISSIONDATA?.services?.veterinary === 'Y' || 
            PERMISSIONDATA?.services?.training === 'Y' ||
            PERMISSIONDATA?.services?.boarding === 'Y' ||
            PERMISSIONDATA?.services?.friendly === 'Y' ||
            PERMISSIONDATA?.services?.adoption === 'Y' ||
            PERMISSIONDATA?.services?.dayCare === 'Y' ||
            user?.admin_type === 'Super Admin'
          ) && (
            <React.Fragment>
              <Collapse accordion className="my_collapse_icon">
                <Panel
                  header={
                    <React.Fragment>
                      <MdOutlineInventory2 className="sidebar_collapse_iohomeoutline" />
                      <span
                        className={`sidebar_collapse_iohomeoutline_categoires ${
                          isOpen ? "visible" : "hidden"
                        }`}
                      >
                        Services
                      </span>
                    </React.Fragment>
                  }
                  key="services"
                  className={`side_bar_categories ${
                    isOpen ? "arrow-visible" : "arrow-hidden"
                  }`}
                >
                  {(PERMISSIONDATA?.services?.grooming === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/grooming/list" icon={<TbPointFilled />} name="Grooming" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.services?.veterinary === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/veterinary/list" icon={<TbPointFilled />} name="Veterinary" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.services?.training === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/training/list" icon={<TbPointFilled />} name="Training" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.services?.boarding === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/boarding/list" icon={<TbPointFilled />} name="Boarding" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.services?.friendly === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/dog-friendly" icon={<TbPointFilled />} name="Friendly" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.services?.adoption === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/dog-adoption" icon={<TbPointFilled />} name="Adoption" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.services?.dayCare === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/daycare/list" icon={<TbPointFilled />} name="Day care" isOpen={isOpen} />
                  )}
                  {/* <SidebarItem to="/service/request-list" icon={<TbPointFilled />} name="Request" isOpen={isOpen} ></SidebarItem> */}
                </Panel>
              </Collapse>
            </React.Fragment>
          )}
          
          {(
            PERMISSIONDATA?.servicesRequest?.grooming === 'Y' || 
            PERMISSIONDATA?.servicesRequest?.veterinary === 'Y' || 
            PERMISSIONDATA?.servicesRequest?.training === 'Y' ||
            PERMISSIONDATA?.servicesRequest?.boarding === 'Y' ||
            PERMISSIONDATA?.servicesRequest?.adoption === 'Y' ||
            PERMISSIONDATA?.servicesRequest?.dayCare === 'Y' ||
            user?.admin_type === 'Super Admin'
          ) && (
            <React.Fragment>
              <Collapse accordion className="my_collapse_icon">
                <Panel
                  header={
                    <React.Fragment>
                      <MdOutlineInventory2 className="sidebar_collapse_iohomeoutline" />
                      <span
                        className={`sidebar_collapse_iohomeoutline_categoires ${
                          isOpen ? "visible" : "hidden"
                        }`}
                      >
                        Services Request
                      </span>
                    </React.Fragment>
                  }
                  key="services"
                  className={`side_bar_categories ${
                    isOpen ? "arrow-visible" : "arrow-hidden"
                  }`}
                >
                  {(PERMISSIONDATA?.servicesRequest?.grooming === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/grooming/request-list" icon={<TbPointFilled />} name="Grooming" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.servicesRequest?.grooming === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/veterinary/request-list" icon={<TbPointFilled />} name="Veterinary" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.servicesRequest?.grooming === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/training/request-list" icon={<TbPointFilled />} name="Training" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.servicesRequest?.grooming === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/boarding/request-list" icon={<TbPointFilled />} name="Boarding" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.servicesRequest?.grooming === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/adoption/request-list" icon={<TbPointFilled />} name="Adoption" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.servicesRequest?.grooming === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/day-care/request-list" icon={<TbPointFilled />} name="Day Care" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.servicesRequest?.grooming === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/service/bulk-request/list" icon={<TbPointFilled />} name="Bulk Request" isOpen={isOpen} />
                  )}
                </Panel>
              </Collapse>
            </React.Fragment>
          )}

          {/* <SidebarItem to="/service/list" icon={<MdOutlineInventory2 />} name="Services" isOpen={isOpen} /> */}

          {/* <SidebarItem to="/bookings/list" icon={<CiShoppingBasket />} name="Bookings" isOpen={isOpen} /> */}
          {(
            PERMISSIONDATA?.bookings?.grooming === 'Y' || 
            PERMISSIONDATA?.bookings?.veterinary === 'Y' || 
            PERMISSIONDATA?.bookings?.training === 'Y' ||
            PERMISSIONDATA?.bookings?.boarding === 'Y' ||
            PERMISSIONDATA?.bookings?.friendly === 'Y' ||
            PERMISSIONDATA?.bookings?.dayCare === 'Y' ||
            user?.admin_type === 'Super Admin'
          ) && (
            <React.Fragment>
              <Collapse accordion className="my_collapse_icon">
                <Panel
                  header={
                    <div>
                      <CiShoppingBasket className="sidebar_collapse_iohomeoutline" />
                      <span
                        className={`sidebar_collapse_iohomeoutline_categoires ${
                          isOpen ? "visible" : "hidden"
                        }`}
                      >
                        Bookings
                      </span>
                    </div>
                  }
                  key="services"
                  className={`side_bar_categories ${
                    isOpen ? "arrow-visible" : "arrow-hidden"
                  }`}
                >
                  {(PERMISSIONDATA?.bookings?.grooming === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/bookings/grooming/list" icon={<TbPointFilled />} name="Grooming" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.bookings?.veterinary === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/bookings/veterinary/list" icon={<TbPointFilled />} name="Veterinary" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.bookings?.training === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/bookings/training/list" icon={<TbPointFilled />} name="Training" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.bookings?.boarding === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/bookings/boarding/list" icon={<TbPointFilled />} name="Boarding" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.bookings?.dayCare === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/bookings/day-care/list" icon={<TbPointFilled />} name="Day Care" isOpen={isOpen} />
                  )}
                </Panel>
              </Collapse>
            </React.Fragment>
          )}
          {(PERMISSIONDATA?.insights?.insights === 'Y' || user?.admin_type === 'Super Admin') && (
            <SidebarItem to="/insights/list" icon={<CiShoppingBasket />} name="Insights" isOpen={isOpen} />
          )}
          {(PERMISSIONDATA?.insurance?.insurance === 'Y' || user?.admin_type === 'Super Admin') && (
            <SidebarItem to="/insurance/list" icon={<CiShoppingBasket />} name="Insurance" isOpen={isOpen} />
          )}
          {/* <SidebarItem to="/payments" icon={<MdOutlinePayment />} name="Payments" isOpen={isOpen} /> */}
          {(
            PERMISSIONDATA?.payments?.grooming === 'Y' || 
            PERMISSIONDATA?.payments?.veterinary === 'Y' || 
            PERMISSIONDATA?.payments?.training === 'Y' ||
            PERMISSIONDATA?.payments?.boarding === 'Y' ||
            PERMISSIONDATA?.payments?.friendly === 'Y' ||
            PERMISSIONDATA?.payments?.dayCare === 'Y' ||
            user?.admin_type === 'Super Admin'
          ) && (
            <React.Fragment>
              <Collapse accordion className="my_collapse_icon">
                <Panel
                  header={
                    <React.Fragment>
                      <MdOutlinePayment className="sidebar_collapse_iohomeoutline" />
                      <span
                        className={`sidebar_collapse_iohomeoutline_categoires ${
                          isOpen ? "visible" : "hidden"
                        }`}
                      >
                        Payments
                      </span>
                    </React.Fragment>
                  }
                  key="payments"
                  className={`side_bar_categories ${
                    isOpen ? "arrow-visible" : "arrow-hidden"
                  }`}
                >
                  {(PERMISSIONDATA?.payments?.shop === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/payments/shop/list" icon={<TbPointFilled />} name="Shop" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.payments?.grooming === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/payments/grooming/list" icon={<TbPointFilled />} name="Grooming" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.payments?.boarding === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/payments/boarding/list" icon={<TbPointFilled />} name="Boarding" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.payments?.veterinary === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/payments/veterinary/list" icon={<TbPointFilled />} name="Veterinary" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.payments?.training === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/payments/training/list" icon={<TbPointFilled />} name="Training" isOpen={isOpen} />                  )}
                  <SidebarItem
                    to="/payments/day-care/list"
                    icon={<TbPointFilled />}
                    name="Day Care"
                    isOpen={isOpen}
                  ></SidebarItem>
                </Panel>
              </Collapse>
            </React.Fragment>
          )}

          {/*<SidebarItem
            to="/promtotions"
            icon={<CiDiscount1 />}
            name="Promotion"
            isOpen={isOpen}
          />*/}
          {(
            PERMISSIONDATA?.promotion?.promotion === 'Y' || 
            PERMISSIONDATA?.promotion?.request === 'Y' || 
            user?.admin_type === 'Super Admin'
          ) && (
            <React.Fragment>
              <Collapse accordion className="my_collapse_icon">
                <Panel
                  header={
                    <React.Fragment>
                      <MdOutlineInventory2 className="sidebar_collapse_iohomeoutline" />
                      <span
                        className={`sidebar_collapse_iohomeoutline_categoires ${
                          isOpen ? "visible" : "hidden"
                        }`}
                      >
                        Promotion
                      </span>
                    </React.Fragment>
                  }
                  key="Promotion"
                  className={`side_bar_categories ${
                    isOpen ? "arrow-visible" : "arrow-hidden"
                  }`}
                >
                  {(PERMISSIONDATA?.promotion?.promotion === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/promtotions" icon={<TbPointFilled />} name="Promotion List" isOpen={isOpen} />
                  )}
                  {(PERMISSIONDATA?.promotion?.request === 'Y' || user?.admin_type === 'Super Admin') && (
                    <SidebarItem to="/promtotions/request-list" icon={<TbPointFilled />} name=" Promotion Request" isOpen={isOpen} />
                  )}
                </Panel>
              </Collapse>
            </React.Fragment>
          )}
          {(PERMISSIONDATA?.rating?.rating === 'Y' || user?.admin_type === 'Super Admin') && (
            <SidebarItem to="/rating-review" icon={<IoMdStarOutline />} name="Rating & Review" isOpen={isOpen} />
          )}
          {(PERMISSIONDATA?.message?.message === 'Y' || user?.admin_type === 'Super Admin') && (
            <SidebarItem to="/message" icon={<CiMail />} name="Message" isOpen={isOpen} />
          )}
          {(PERMISSIONDATA?.faq?.faq === 'Y' || user?.admin_type === 'Super Admin') && (
            <SidebarItem to="/faqs" icon={<BiSupport />} name="FAQs" isOpen={isOpen} />
          )}
          {(PERMISSIONDATA?.message?.message === 'Y' || user?.admin_type === 'Super Admin') && (
            <SidebarItem to="/support/list" icon={<BiSupport />} name="Support List" isOpen={isOpen} />
          )}
          {(PERMISSIONDATA?.notifications?.notifications === 'Y' || user?.admin_type === 'Super Admin') && (
            <SidebarItem to="/notifications" icon={<IoNotificationsOffSharp />} name="Notifications" isOpen={isOpen} className="custom_profile_class_productList" />
          )}
          <React.Fragment>
            <SidebarItem
              icon={
                <img
                  //   src={createImageFromInitials(50, `${USERDATA?.admin_first_name} ${USERDATA?.admin_last_name}`, getRandomColor())}
                  src={
                    user?.admin_image
                      ? ASSETS_BASE_URL + user.admin_image
                      : "https://frisbee-llc.s3.me-central-1.amazonaws.com/images/favicon.png"
                  }
                  alt=""
                  className="user_profile_pic_sidebar"
                  onClick={handleCollapseToggle}
                  width="100px"
                />
              }
              to="/profile"
              name={
                <div className="sidebar_profile_main_content_section">
                  <React.Fragment>
                    <div className="sidebar_profile_main_content">
                      <div className="user_profile_pic_Admin_name">
                        <span className="user_profile_pic_Admin_panel">
                          {`${user?.admin_first_name} ${user?.admin_last_name}`}
                        </span>{" "}
                        <br />
                        <span className="user_profile_pic_Admin_panel_">
                          Admin
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <FaGreaterThan
                      className="side_bar_fagreaterthan"
                      onClick={handleCollapseToggle}
                    />
                  </React.Fragment>
                </div>
              }
              isOpen={isOpen}
              className="custom_profile_class"
            >
            </SidebarItem>
          </React.Fragment>
        </div>
      </div>
      <main>{children}</main>
    </div>
  );
};

export default Sidebar;
