import React, { useEffect, useState } from "react";

import { FaDesktop, FaArrowLeft } from "react-icons/fa";

import "react-quill/dist/quill.snow.css";
import ExcelJS from 'exceljs';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { bulkUpload } from "../../../controllers/Products/product";
import { notification, Progress, Upload } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import { list as categoryList } from "../../../controllers/categories/products";
import { list } from "../../../controllers/subcategories/products";

import { beforeUpload, createSlug } from '../../../controllers/common';

const conicColors = {
  '0%': '#87d068',
  '50%': '#ffe58f',
  '100%': '#ffccc7',
};

function ProductListEditPage() {
  const navigate = useNavigate();
  const data = useLocation().state;
  const [dataForm, setFormData] = useState();
  const { Dragger } = Upload;
  const [error, setError] = useState([]);

  const [imageList, setImageList] = useState("");
  const [categories, setcategory] = useState([]);
  const [categoryId, setcategoryId] = useState(data?.category._id ?? "");
  const [subCategoryId, setSubCategoryId] = useState(
    data?.subcategory._id ?? ""
  );
  const [subCategories, setSubCategories] = useState([]);
  const [isAddPage, setIsAddPage] = useState(data ? false : true);
  const [fileName, setFileName] = useState('');
  const [jsonData, setJsonData] = useState(null);

  const [progress, setProgress] = useState(0);

  /*********************************************************
   *  This function is use to handle success notification show
   *********************************************************/
  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data Saved Successfully .",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  }; //End
  /*********************************************************
   *  This function is use to handle error notification show
   *********************************************************/
  const handleErrorMsg = () => {
    notification.open({
      message: "Opps!",
      description: error?.formError,
      placement: "topRight",
      icon: <CloseOutlined twoToneColor="#fc2339" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  }; //End

  /*********************************************************
   *  This function is use to handle image object
   *********************************************************/
  const handleFileChange = (e) => {
    setImageList(e.fileList[0].originFileObj);
    setFileName(e.fileList[0]?.originFileObj?.name);
    const file = e.fileList[0].originFileObj;
    const workbook = new ExcelJS.Workbook();
    const reader = new FileReader();

    reader.onload = async (event) => {
      const buffer = event.target.result;
      await workbook.xlsx.load(buffer);

      const worksheet = workbook.worksheets[0];
      const rows = [];

      worksheet.eachRow((row, rowNumber) => {
        const rowValues = row.values.slice(1); // Remove the first empty value
        rows.push(rowValues);
      });

      // Convert rows to JSON (assuming first row is the header)
      const headers = rows[0];
      const data = rows.slice(1).map(row => {
        const rowData = {};
        row.forEach((cell, index) => {
          rowData[headers[index]] = cell;
        });
        return rowData;
      });
      setJsonData(data);
    };
    reader.readAsArrayBuffer(file);
    setError((pre)=>({
      ...pre,
      file : ""
    }));
  };
 
  /*********************************************************
   *  This function is use to fetch category list
   *********************************************************/
  const getCategoryList = async () => {
    const options = {
      type: "",
      condition: { status: "A" },
      select: { name: true },
      sort: { name: 1 },
      populate: { key: "", select: "" },
    };
    const listData = await categoryList(options);
    if (listData.status === true) {
      setcategory(listData?.result);
    } else {
      setcategory([]);
    }
  }; //End

  /*********************************************************
   *  This function is use to fetch sub category list
   *********************************************************/
  const getSubCategories = async (carId) => {
    setSubCategories([]);
    const options = {
      type: "",
      condition: {
        categoryId: carId,
      },
      select: {},
      sort: { _id: -1 },
      populate: { key: "categoryId", select: "name" },
    };
    const listData = await list(options);
    if (listData.status === true) {
      setSubCategories(listData?.result);
    } else {
      setSubCategories([]);
    }
  }; //End

  /*********************************************************
   *  This function is use to handle input chnage and set in state
   *********************************************************/
  const handleChange = (e) => {
    if (e.target.name === "category") {
      setcategoryId(e.target.value);
      getSubCategories(e.target.value);
      setSubCategoryId("");
      setError("");
    } else if (e.target.name === "subCategory") {
      setSubCategoryId(e.target.value);
      setError("");
    } else {
      setFormData((pre) => ({
        ...pre,
        [e.target.name]: e.target.value,
      }));

      setError((pre) => ({
        ...pre,
        [e.target.name]: "",
      }));
    }
  }; //End

  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if(!categoryId){
  //     setError((pre)=>({
  //       ...pre,
  //       category : "Please select category"
  //     }));
  //   } else if(!subCategoryId){
  //     setError((pre)=>({
  //       ...pre,
  //       subcategory : "Please select sub-category"
  //     }));
  //   } else{
  //     const option = {
  //       categoryId : categoryId,
  //       subCategoryId : subCategoryId,
  //       data : jsonData
  //     }
  //     if(jsonData?.length > 0){
  //       const dataArray = await transformData(option);
  //       if (dataArray && dataArray?.length > 0) {
  //         const res = await bulkUpload(dataArray); 
  //         if (res.status === true) {
  //           handleSuccessMsg();
  //           // navigate("/product/list");
  //         } else {
  //           setError((pre) => ({
  //             ...pre,
  //             formError: res?.message,
  //           }));
  //           // navigate("/product/list");
  //         }
  //       }
  //     } else{
  //       setError((pre)=>({
  //         ...pre,
  //         file : "Please upload file"
  //       }));
  //     }
  //   }
  // }; //End

  // const transformData = async (option) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { categoryId, subCategoryId, data } = option;
  //       const result = await Promise.all(data.map(async (item) => {
  //         const slug = await createSlug(item?.name);
  //         return {
  //           category: categoryId,
  //           subcategory: subCategoryId,
  //           name: item?.name || '',
  //           slug: slug,
  //           image: item?.image?.text || '',
  //           details: item?.details || '',
  //           status: 'P'
  //         };
  //       }));
  //       resolve(result);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!categoryId) {
        setError((pre) => ({
            ...pre,
            category: "Please select category"
        }));
      } else if (!subCategoryId) {
          setError((pre) => ({
              ...pre,
              subcategory: "Please select sub-category"
          }));
      } else {
          const option = {
              categoryId: categoryId,
              subCategoryId: subCategoryId,
              data: jsonData
          };
          if (jsonData?.length > 0) {
              try {
                  const dataArray = await transformData(option);
                  if (dataArray && dataArray?.length > 0) {
                      for (let i = 0; i < dataArray.length; i += 10) {
                          const batch = dataArray.slice(i, i + 10);
                          const res = await bulkUpload(batch);
                          if (res.status !== true) {
                              setError((pre) => ({
                                  ...pre,
                                  formError: res?.message,
                              }));
                              break;
                          }
                          const progressPercentage = Math.round(((i + 10) / dataArray.length) * 100);
                          setProgress(progressPercentage);
                      }
                      handleSuccessMsg();
                      navigate("/product/list");
                  }
              } catch (error) {
                  setError((pre) => ({
                      ...pre,
                      formError: error.message,
                  }));
              }
          } else {
              setError((pre) => ({
                  ...pre,
                  file: "Please upload file"
              }));
          }
      } 
    } catch (error) {
      console.log('error',error)
    }
};

  const transformData = async (option) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { categoryId, subCategoryId, data } = option;
            let result = [];

            for (let i = 0; i < data.length; i += 10) {
                const batch = data.slice(i, i + 10);
                const transformedBatch = await Promise.all(batch.map(async (item) => {
                    const slug = await createSlug(item?.name);
                    return {
                        category: categoryId,
                        subcategory: subCategoryId,
                        name: item?.name || '',
                        slug: slug,
                        image: item?.image?.text || '',
                        details: item?.details || '',
                        status: 'A'
                    };
                }));
                result = result.concat(transformedBatch);
            }

            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
};

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <div>
            <h5 className="inventory_edit_page_heading">
              Upload Product{" "}
            </h5>
          </div>
          <Link to={`/product/list`}>
            <div>
              <button type="button" className="btn btn-secondary">
                <FaArrowLeft /> Back
              </button>
            </div>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form action="" >
                <div class="form-group">
                  <div class="form-group">
                    <label for="categoryId" className="all_vendor_radio_button_div"> Select Category </label>
                    <select value={categoryId || ""} name="category" id="categoryId" className="form-control categories_select_tg_enter_product" onChange={handleChange} >
                      {isAddPage ? ( 
                        <option value="">Select Category</option>
                      ) : ( "" )}
                      {categories &&
                        categories.map((item, index) => (
                          <option key={index} value={item?._id} selected={ data?.categoryId?._id === item?._id ? true : false } >
                            {" "}
                            {item?.name}
                          </option>
                        ))}
                    </select>
                    {error?.category ? ( <p style={{ color: "red" }}>{error.category}</p> ) : ( "" )}
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-group">
                    <label for="categoryId" className="all_vendor_radio_button_div" > Select Sub Category </label>
                    <select value={subCategoryId} name="subCategory" id="categoryId" className="form-control categories_select_tg_enter_product" onChange={handleChange} >
                      {isAddPage ? (
                        <option value="">Select Sub Category</option>
                      ) : ( "" )}
                      {subCategories &&
                        subCategories.map((item, index) => (
                          <option
                            key={index}
                            value={item?._id}
                            selected={
                              data?.categoryId?._id === item?._id ? true : false
                            }
                          >
                            {item?.name}
                          </option>
                        ))}
                    </select>
                    {error?.subcategory ? ( <p style={{ color: "red" }}>{error.subcategory}</p> ) : ("")}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1" className="all_vendor_radio_button_div">File</label>
                  <Dragger
                    className="about_us_dragger_file"
                    onChange={handleFileChange}
                    accept='.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    showUploadList={false}
                  >
                    <button type="button" className="about_us_dragger_file_button" > {fileName?fileName:`Upload file`}</button> 
                    <p style={{color:"blue"}}>*Note: Please choose a file of xlsx format only.</p>
                    {error?.file ? ( <p style={{ color: "red" }}>{error.file}</p> ) : ("")}
                  </Dragger>
                  <Link to={`https://frisbee-llc.s3.me-central-1.amazonaws.com/assets/bulk-product-upload.xlsx`} target="_blank">Download template</Link>
                </div>
                {progress === 0 ? (
                  <div className="inventory_edit_submit_btn">
                    <button type="button" className="btn btn-primary inventory_save_button all_save_btn_btn_btn" onClick={handleSubmit} > Save </button>
                  </div>
                ):(
                  <div class="form-group">
                    <div class="form-group">
                      <Progress type="line" percent={progress} strokeColor={conicColors} />
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductListEditPage;
