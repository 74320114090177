import React, { useEffect, useState } from "react";
import { Upload, Flex, Radio, notification, InputNumber, Select } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import {
  addeditdata,
  chnageStatus,
  categoryList,
} from "../../../controllers/cms/blogs";
import { fetchIpAddress } from "../../../controllers/API";

import LoadingEffect from "../../../components/Loading/LoadingEffect";
import { beforeUpload } from "../../../controllers/common";

const UserListEditPage = () => {
  const { Option } = Select;
  const { Dragger } = Upload;

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [error, setError] = useState([]);
  const [ADDEDITDATA, setAddEditSata] = useState(state ? state : []);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [image, setImage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    vendor_account_number: ADDEDITDATA?.vendor_account_number || "",
  });
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  /*********************************************************
   *  This function is use to handle imput chnage
   *********************************************************/
  const handleChange = (e) => {
    setAddEditSata((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setError((pre) => ({
      ...pre,
      [e.target.name]: "",
    }));
  };
  /*********************************************************
   *  This function is use to handle image
   *********************************************************/
  // const handleImageChnage = (e) => {
  //   setImage(e.fileList);
  // };
  
  const  handleImageChnage = (e) => {
    const fileList = e.fileList.slice(-1); // Keep only the last image
    setImage(fileList);
    if (fileList.length === 0) {
        setError(prev => ({ ...prev, image: "Image is required." }));
    } else {
        setError(prev => ({ ...prev, image: "" }));
    }
};
  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const {
        _id,
        title,
        subtitle,
        content,
        metaTitle,
        metaKeywords,
        metaDescription,
      } = ADDEDITDATA;
      const ipAddress = (await fetchIpAddress()) || "0.0.0.0";

      const form = new FormData();
      if (_id && _id !== "undefined" && _id !== null) {
        form.append("edit_id", _id);
      } 
      form.append("title", title);
 
      form.append("subtitle", subtitle);
      form.append("content", content );
      form.append("metaTitle", metaTitle);
      form.append("metaKeywords", metaKeywords);
      form.append("metaDescription", metaDescription);
      if (image.length > 0) {
        form.append("image", image[0]?.originFileObj);
      }
      
      form.append("ipAddress", ipAddress);

      const isValidate = true;

      if (isValidate) {
        const options = {
          url: "cms/blogs/addeditdata",
          postData: form,
        };
        const res = await addeditdata(options);
        if (res.status === true) {
          notification.open({
            message: "Data updated.",
            description: `Data updated Successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          navigate("/cms/blogs");
        } else {
          notification.open({
            message: "Opps!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      } else {
        notification.open({
          message: "Opps!",
          description: `Please enter the required fields.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      console.log("error", error);
    }finally{
      setIsLoading(false);
    }
  };

  

  /*********************************************************
   *  This function is use to handle delete account
   *********************************************************/
  const handleDelete = async () => {
    try {
      const options = {
        url: `cms/blogs/changestatus`,
        postData: {
          user_id: ADDEDITDATA?._id,
          status: "D",
        },
      };
      const res = await chnageStatus(options);
      if (res.status === true) {
        notification.open({
          message: "User deleted successfully.",
          description: `Data deleted Successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        navigate("/cms/blogs");
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Opps!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
  };



  useEffect(() => {

    document.title = `Frisbee Super Admin || ${ADDEDITDATA?._id ? "Edit" : "Add"
      } Blogs`;
  }, [ADDEDITDATA]);
  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <div>
            <h5 className="inventory_edit_page_heading">
              {ADDEDITDATA?._id ? `Edit` : `Add`} Blogs
            </h5>
          </div>
          <Link to="/cms/blogs">
            <div>
              <button type="button" className="btn btn-secondary">
                <ArrowLeftOutlined /> Back
              </button>
            </div>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form method="post" className="inventory_form_main_contnet">
                <div className="row inventory_form_main_content_form_section">
                  <div className="row">
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="vendor_first_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>Title :{" "}
                      </label>
                      <input
                        title="Enter your Blog Title."
                        placeholder="Enter your Blog Title."
                        type="text"
                        name="title"
                        className="form-control store_input_field"
                        id="title"
                        value={ADDEDITDATA?.title}
                        onChange={handleChange}
                      />
                      {error.title ? (
                        <p style={{ color: "red" }}>
                          {error.title}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="vendor_last_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>Subtitle :{" "}
                      </label>
                      <input
                        title="Enter your subtitle."
                        placeholder="Enter your subtitle."
                        type="text"
                        name="subtitle"
                        className="form-control store_input_field"
                        id="subtitle"
                        value={ADDEDITDATA?.subtitle}
                        onChange={handleChange}
                      />
                      {error.subtitle ? (
                        <p style={{ color: "red" }}>{error.subtitle}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="vendor_email"
                        className="all_section_for_label_main_class  all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Content :
                      </label>
                      <textarea
                        title="Enter your Content."
                        placeholder="Enter your Content."
                        type="text"
                        name="content"
                        className="form-control store_input_field"
                        id="content"
                        value={ADDEDITDATA?.content}
                        // readOnly={ADDEDITDATA?._id ? true : false}
                        onChange={handleChange}
                      />
                      {error.content ? (
                        <p style={{ color: "red" }}>{error.content}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="vendor_commission"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Meta Title :
                      </label>
                      <input
                        title="Enter Meta Title."
                        placeholder="Enter Meta Title"
                        type="text"
                        name="metaTitle"
                        className="form-control store_input_field"
                        id="metaTitle"
                        value={ADDEDITDATA?.metaTitle}
                        onChange={handleChange}
                      />
                      {error.metaTitle ? (
                        <p style={{ color: "red" }}>
                          {error.metaTitle}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="vendor_commission"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Meta Keywords :
                      </label>
                      <input
                        title="Enter Meta Keywords."
                        placeholder="Enter Meta Keywords"
                        type="text"
                        name="metaKeywords"
                        className="form-control store_input_field"
                        id="metaKeywords"
                        value={ADDEDITDATA?.metaKeywords}
                        onChange={handleChange}
                      />
                      {error.metaKeywords ? (
                        <p style={{ color: "red" }}>
                          {error.metaKeywords}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="vendor_commission"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Meta Description :
                      </label>
                      <textarea
                        title="Enter Meta Description."
                        placeholder="Meta Description"
                        type="text"
                        name="metaDescription"
                        className="form-control store_input_field"
                        id="metaDescription"
                        value={ADDEDITDATA?.metaDescription}
                        onChange={handleChange}
                      />
                      {error.metaDescription ? (
                        <p style={{ color: "red" }}>
                          {error.metaDescription}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
 
                  
                </div>

                <div className="form-group">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="all_section_for_label_main_class"
                  >
                    {!ADDEDITDATA?._id && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                    Images
                  </label>
                  <Dragger
                    className="about_us_dragger_file"
                    maxCount="1"
                    // listType="picture"
                    onChange={handleImageChnage}
                    beforeUpload={beforeUpload}
                  >
                    <button
                      type="button"
                      className="about_us_dragger_file_button"
                    >
                      Upload file
                    </button>
                  </Dragger>
                  <p
                    style={{ color: "blue" }}
                    className="all_top_and_bottom_for_input_text"
                  >
                    *Note: Please select an image format (JPEG/JPG/PNG) and
                    ensure its dimensions are 420px x 280px.
                  </p>
                  {error.image ? (
                    <p style={{ color: "red" }}>{error.image}</p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="inventory_edit_submit_btn">
                  <button
                    type="button"
                    className="btn btn-primary inventory_save_button"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <LoadingEffect isLoading={isLoading} />
    </div>
  );
};

export default UserListEditPage;
