import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import { notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteReasons } from "../../../controllers/cms/reasons";

const PromotionsDeletePopUp = ({ popUpOpen, togglePopUp, deleteId, setDeleteId }) => {
  const handleCancel = () => {
    togglePopUp();
    setDeleteId('');
  };
  const handleDelete = async () => {
    try {
      if(deleteId){
        const options = { id : deleteId};
        const res = await deleteReasons(options);
        console.log("res", res)
        if (res.status === true) {
          notification.open({
            message: "Deleted!",
            description: "Data Deleted Successfully.",
            placement: "topRight",
            duration: 2,
            icon: <DeleteOutlined style={{ color: "green" }} />,
          });
        } else {
          notification.open({
            message: "Oops!!",
            description: "Please try again.",
            placement: "topRight",
            duration: 2,
            icon: <DeleteOutlined style={{ color: "red" }} />,
          });
        }
        if (typeof setDeleteId === "function") {
          setDeleteId("");
        }
        handleCancel();
      } else{
        notification.open({
          message: "Error",
          description: "Data Not Found! Try Again.",
          placement: "topRight",
          duration: 2,
          icon: <DeleteOutlined style={{ color: "red" }} />,
        });  
      }
    } catch (error) {
      notification.open({
        message: "Error",
        description: "An error occurred while deleting. Please try again.",
        placement: "topRight",
        duration: 2,
        icon: <DeleteOutlined style={{ color: "red" }} />,
      });
    }
  };

  return (
    <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            className="myFilterClass"
          >
            Confirm
          </Typography>
          <IconButton onClick={togglePopUp}>
            <IoMdClose />
          </IconButton>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Do you want to remove this item?</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} style={{ color: "red" }}>
            Delete
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default PromotionsDeletePopUp;
