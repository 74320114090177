import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, notification } from "antd";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import TobNavBar from "../../DashBaord/Top_navbar"
import VendorListDeletePopUp from "./VendorListDeletePopUp";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../UserList/UserList.css";

import { InfoCircleOutlined, CheckCircleOutlined, LikeOutlined, DislikeOutlined } from "@ant-design/icons";

import moment from "moment";
import { ASSETS_BASE_URL } from "../../../config/constants";
import SkeltonList from "../../../components/SkeltonEffect/list";
import { list, chnageStatus } from "../../../controllers/stores/storesController";
import { getPage, statusMessage } from '../../../controllers/common';

import { FaPlusSquare } from "react-icons/fa";

function VendorList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

VendorList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {

  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  /************************************************************************************* */
  const location = useLocation();
  const { state } = location;
  const [VENDORDATA, setVendorData]= useState(state?state:"");
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const [deleteId, setDeleteId]= useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from : '',
    to : new Date(),
    filter_by : "",
    search : ""
  })//End

  const [showRequest, setShowRequest] = useState("");
  /*********************************************************
  *  This function is handle show edit page
  *********************************************************/
  const handleEdit = async (item={}) =>{
    navigate('/store/addeditdata',{state : {vendor : VENDORDATA, addeditdata : item}});
  }
  /*********************************************************
  *  This function is use to shoe branch list
  *********************************************************/
  const handleBranchList = (item) =>{
    navigate('/branch/list',{state : item})
  }
  /*********************************************************
  *  This function is use to fetch vendor list
  *********************************************************/
  const getList = async ()=>{
    setListData([]);
    const options = {
      type : "",
      condition : { 
        ...(filter?.from ? {
          createdAt: {
            '$gte': moment(filter?.from).format('YYYY-MM-DD'),
            '$lte': moment().format('YYYY-MM-DD'),
          },
        } : null),
        ...(filter?.search?{[filter?.filter_by]:{ $regex: filter.search, $options: 'i' }}:null),
        ...(showRequest?{status : showRequest}:null),
        vendorData : VENDORDATA?._id
      },
      select    : { },
      sort      : {"_id" : -1},
      skip      : SKIP?SKIP:0,
      limit     : LIMIT?LIMIT:10
    }
    const listData = await list(options);
    if(listData.status === true){
      if(listData?.result?.length > 0){
        setListData(listData?.result);
      } else{
        setListData([{shop_name : 'No Data Found'}]);  
      }
      const countOptions = {
        type : "count",
        condition : {
          ...(filter?.from ? {
            createdAt: {
              '$gte': moment(filter?.from).format('YYYY-MM-DD'),
              '$lte': moment().format('YYYY-MM-DD'),
            },
          } : null),
          ...(showRequest?{status : showRequest}:null) 
        }
      }
      const count = await list(countOptions);
      setTotalPages(getPage(count?.result)); 
    }else{
      setListData([{shop_name : 'No Data Found'}]);
    }
  };//End

  /*********************************************************
  *  This function is for handle page change
  *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage -1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };
  /*********************************************************
  *  This function is use to handle remove vendor
  *********************************************************/
  const handleStatusChnage = async (status='', id) => {
    try{
      setListData([]);
      const options ={
        url : `accounts/stores/changestatus`,
        postData :{
          user_id : id,
          status : status,
        }
      }
      const res = await chnageStatus(options);
      if(res.status === true){
        notification.open({
          message: "Data updated.",
          description:  `Data updated Successfully.`,
          placement: "topRight", 
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
          duration:2
        });
      } else{
        notification.open({
          message: "Opps!",
          description:  `${res?.message}`,
          placement: "topRight", 
          icon: <InfoCircleOutlined style={{ color: 'red' }} />,
          duration:2
        });
      }
    } catch (error){
      notification.open({
        message: "Opps!",
        description:  `Operation not perform yet! please try in some time.`,
        placement: "topRight", 
        icon: <InfoCircleOutlined style={{ color: 'red' }} />,
        duration:2
      });
    }
    getList();
  }
  

  const dropdownMenu = (items) => {
    return <>
    <Menu>
      <Menu.Item key="1" onClick={()=>handleEdit(items)}>
          <FaEdit />
          <span className="show_span_edit">Edit</span>
      </Menu.Item>
      {items?.status==='I' && (
        <Menu.Item key={`02`} onClick={()=>{ handleStatusChnage('A', items._id) }}>
          <LikeOutlined /> <span className="show_span">Active</span>
        </Menu.Item>  
      )}
      {items?.status==='A' && (
        <Menu.Item key={`02`} onClick={()=>{ handleStatusChnage('I', items._id) }}>
          <DislikeOutlined /> <span className="show_span">Inactive</span>
        </Menu.Item>  
      )}
    </Menu>
    </>
  };

  /*********************************************************
  *  This function is load when page load and with dependency update
  *********************************************************/
  useEffect(()=>{
    getList();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = 'Frisbee Super Admin || Vendors List'
  },[currentPage,showRequest, popUpOpen]);

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={`${VENDORDATA.vendor_last_name}'s Store List`}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={()=>setShowRequest('')}
                    />
                    <Tab
                      label="Deleted"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                      onClick={()=>setShowRequest('D')}
                    />
                  </Tabs>
                </div>
                <div className="fa_filter_rating_review">
                  <div className="inventory_filter_div">
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt="filter"
                      className="user_iconses"
                    />
                    <Link to={false} className="filter_btn_invontry_rating_review">
                      Filters
                    </Link>
                  </div>
                </div>

                <div onClick={()=>handleEdit()}>
                  <button
                    type="button"
                    class="btn btn-dark"
                  >
                    <FaPlusSquare className="Promotions_faPlus_Squares" />
                    <span className="request_new_categories">Add New</span>
                  </button>
                </div>

              </div>
            </Box>
            {/* First Inventory */}
            <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Owner's Data</th>
                              <th>Created On</th>
                              <th>Contact No</th>
                              {/* <th>Branches</th> */}
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                          {ALLLISTDATA?.length > 0 ? (ALLLISTDATA.map((items, index)=>(<>
                            <tr key={index}>
                              <td>
                                <div className="admin_user_list_table">
                                  <div>
                                    {items?.image?(
                                      <img src={`${ASSETS_BASE_URL}${items.image}`} alt={items?.image} width="50px"/>
                                    ):(
                                      <img src={`../images/IntentoryTableLogo.png`} alt={`profile-pic`} width="30px"/>
                                    )}
                                  </div>
                                  <div className="admin_user_list_name_gmail">
                                    <span className="admin_user_list_name">
                                      {`${items?.shop_name}`}
                                    </span>
                                    <span className="admin_user_list_gmail">
                                      {items?.email}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {items?.vendorData && (
                                  <>
                                  {`${items?.vendorData?.vendor_first_name} ${items?.vendorData?.vendor_last_name} `}<br/>   
                                  {`${items?.vendorData?.vendor_email}`}<br/>
                                  {`${items?.vendorData?.vendor_phone}`}
                                  </>
                                )}
                              </td>
                              <td className="admin_user_list_date">
                              {moment(`${items?.createdAt}`).format('DD/MM/YYYY, h:m A')}{" "}
                              </td>
                              <td className="admin_user_list_date"> {items?.phone} </td>
                              {/* <td className="admin_user_list_date"> 
                                <span onClick={()=>handleBranchList(items)} >View All</span> 
                              </td> */}
                              <td className="admin_user_list_date" dangerouslySetInnerHTML={{__html : statusMessage(items.status)}}></td>
                              <td>
                                <Dropdown
                                  overlay={()=>dropdownMenu(items)}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            </>))) : (<SkeltonList row={10} col={4} />)}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={TOTALPAGES} onChange={handlePageChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </Box>
        </div>
        <VendorListDeletePopUp
          popUpOpen={popUpOpen}
          togglePopUp={togglePopUp}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
        />
      </div>
    </>
  );
}
