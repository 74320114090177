import { API_ADMIN_URL } from "../../config/constants";
import { postRequest } from "../API";


/*********************************************************
* Function Name : addEditData
* Description   : Get list of addEditData Term And Condition
* By            : Noor ALam
* Date          : 23May-2024 
*********************************************************/
export const addEditData = async (options) => {
    try {
        const params = {
            url: `${API_ADMIN_URL}cms/treats/addeditdata`,
            postData: options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return { status: true, result: res?.data?.response?.result};
        }else{
            return {status: false, message: res?.response?.data?.statusMessage}
        }
    }
    catch (error) {
     console.log("Error", error);
     return {status: false, mesasge: "Under Maintenance, Please try after some time."}
    }
};//End of Function


/*********************************************************
* Function Name : getData
* Description   : getData of Term And Condition
* By            : Noor ALam
* Date          : 23May-2024 
*********************************************************/
export const getData = async(options) => {
    try{
        const params = {
            url: `${API_ADMIN_URL}cms/treats/getData`,
            postData: options.postData
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status: true, result: res?.data?.response?.result};
        }else{
            return {status: false, mesasge: res?.response?.data?.statusMessage}
        }

    }catch(error){
        console.log("Error", error);
        return {status: false, message: "Under MAintenance, Please try after some time"}
    }
}