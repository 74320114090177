import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import "./List.css";

import { addEditData } from "../../../controllers/subcategories/products"

const AddEdit = ({ show, editdata, setEditData, setAddEditPopup, categories }) => {
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };
    
  /*********************************************************
  *  This function is use to handle cancle button and close popup
  *********************************************************/
   const handleCancel = () => {
    setEditData("");
    setFormData("");
    setImageList("");
    setAddEditPopup(false);
  };
  /*********************************************************
  *  This function is use to handle success notification show
  *********************************************************/
  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data saved Successfully .",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  };//End
  /*********************************************************
  *  This function is use to handle error notification show
  *********************************************************/
  const handleErrorMsg = () => {
    notification.open({
      message: "Opps!",
      description: error?.formError,
      placement: "topRight",
      icon: <CloseOutlined  twoToneColor="#fc2339" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  };//End
  const [error, serError] = useState([]);
  const [success, serSuccess] = useState([]);
  const [formData, setFormData] = useState({
    status : "A"
  });
  const [imageList, setImageList] = useState("");
  /*********************************************************
  *  This function is use to handle input chnage and set in state
  *********************************************************/
  const handleChnage = (e)=>{
    setFormData((pre)=>({
      ...pre,
      [e.target.name] : e.target.value
    }))
    
    setEditData((pre) => ({
      ...pre,
      [e.target.name] : [e.target.value]
    }))

    serError((pre)=>({
      ...pre,
      [e.target.name] : ""
    }))
  }; //End
  
  /*********************************************************
  *  This function is use to handle form submit
  *********************************************************/
  const handleSubmit = async (e)=>{
    e.preventDefault();
    try{
      if(!formData?.categoryId){
        serError((pre)=>({
          ...pre,
          categoryId : "Please select category."
        }))
      } else if(!formData?.name){
        serError((pre)=>({
          ...pre,
          name : "Please enter sub category."
        }))
      } else{
        const params = {
          url : `subcategories/products/addeditdata/${editdata?._id?editdata?._id:':editId'}`,
          postData : formData
        }
        const res = await addEditData(params);
        if(res.status === true){
          setFormData("");
          setImageList("");
          handleSuccessMsg();
          handleCancel();
        }else{
          serError((pre)=>({
            ...pre,
            formError : res?.message
          }))
          handleErrorMsg();
          setFormData("");
          setImageList("");
          handleCancel();
        }
      }
    } catch (error){
      serError((pre)=>({
        ...pre,
        formError : "Opps! Fail to perform."
      }))
    }
  } //End
  
  return ( 
    <Dialog
      open={show}
      onClose={handleCancel}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="categories_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="categories_popup_title_heading"
            >
              {editdata?._id?"Edit":"Add"} Sub-Category
            </Typography>
          </div>
          <Typography variant="body1" component="div" className="my_delete_popup_paragraph" >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form">

                  <div class="form-group">
                    <div class="form-group">
                      <label for="categoryId" className="categories_open_popup_label_name" >
                        Select Category
                      </label>
                      <select name="categoryId" id="categoryId" className="form-control categories_select_tg_enter_product" onChange={handleChnage}>
                        <option value="" >Select Category</option>
                        {categories && categories.map((item, index)=>(
                          <option value={item?._id}  selected={editdata?.categoryId?._id === item?._id?true:false} > {item?.name}</option>
                        ))}
                      </select>
                      {error?.categoryId?<p style={{color:"red"}}>{error.categoryId}</p>:""}
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="form-group">
                      <label
                        for="cannedProducts"
                        className="categories_open_popup_label_name"
                      >
                        Sub Category
                      </label>
                      <input
                        type="text"
                        class="form-control categories_select_tg_enter_product"
                        id="cannedProducts"
                        placeholder="Enter Sub Category Name"
                        name="name"
                        value={formData?.name?formData?.name:editdata?.name}
                        onChange={handleChnage}
                      />
                      {error?.name?<p style={{color:"red"}}>{error.name}</p>:""}
                    </div>
                  </div>
                  
                

                  <div className="product_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={handleSubmit}
                    >
                      {editdata._id?"Save Changes":'Create'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>          
        </div>
      </div>
    </Dialog>
  );
};

export default AddEdit;
