import { API_ADMIN_URL } from "../../config/constants";
import { postRequest, fetchIpAddress } from "../API";

/*********************************************************
* Function Name : List
* Description   : This function is used for get all service list
* By            : Afsar Ali
* Date          : 29-03-2024 
*********************************************************/
export const list = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/request/list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : chnageStatus
* Description   : This function is used for get all service list
* By            : Afsar Ali
* Date          : 29-03-2024 
*********************************************************/
export const chnageStatus = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/request/change-status`,
            postData : {
                ...options,
                ipAddress : await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function



/*********************************************************
* Function Name : dogAdoptionList from adoption table
* Description   : This function is used for  dog adoption list from adoption table
* By            : Noor Alam
* Date          : 7May-2024 
*********************************************************/
export const adoptionsList = async(options) => {
    try{
     const params = {
        url: `${API_ADMIN_URL}servicesmaster/dog-adoption/requestList`,
        postData: options
     }
     const res = await postRequest(params); 
     if(res.status === true || res.status === 200){
        return { status: true, result: res?.data?.response.result};
     }else{
        return {status: false, result: res?.response?.data?.statusMessage };
     }
    }catch(error){
        console.log("Error", error);
        return {status: false, message: "Under Maintenance, Please try after some time."}
    }
};

/*********************************************************
* Function Name : chnageAdoptionRequestStatus
* Description   : This function is used for change adoption resuest status
* By            : Afsar Ali
* Date          : 10-05-2024 
*********************************************************/
export const chnageAdoptionRequestStatus = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/dog-adoption/request/chnage-status`,
            postData : {
                ...options,
                ipAddress : await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/********************** Veterinary ********************************* */
/*********************************************************
* Function Name : veterinaryList
* Description   : This function is used for vetrinary request list
* By            : Afsar Ali
* Date          : 15-05-2024 
*********************************************************/
export const veterinaryList = async(options) => {
    try{
     const params = {
        url: `${API_ADMIN_URL}servicesmaster/veterinary/request/list`,
        postData: options
     }
     const res = await postRequest(params); 
     if(res.status === true || res.status === 200){
        return { status: true, result: res?.data?.response.result};
     }else{
        return {status: false, result: res?.response?.data?.statusMessage };
     }
    }catch(error){
        console.log("Error", error);
        return {status: false, message: "Under Maintenance, Please try after some time."}
    }
};

/*********************************************************
* Function Name : veterinaryChnageStatus
* Description   : This function is used for change veterinary request status
* By            : Afsar Ali
* Date          : 15-05-2024 
*********************************************************/
export const veterinaryChnageStatus = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/veterinary/request/change-status`,
            postData : {
                ...options,
                ipAddress : await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/********************** Training ********************************* */
/*********************************************************
* Function Name : trainingList
* Description   : This function is used for vetrinary request list
* By            : Afsar Ali
* Date          : 15-05-2024 
*********************************************************/
export const trainingList = async(options) => {
    try{
     const params = {
        url: `${API_ADMIN_URL}servicesmaster/training/request/list`,
        postData: options
     }
     const res = await postRequest(params); 
     if(res.status === true || res.status === 200){
        return { status: true, result: res?.data?.response.result};
     }else{
        return {status: false, result: res?.response?.data?.statusMessage };
     }
    }catch(error){
        console.log("Error", error);
        return {status: false, message: "Under Maintenance, Please try after some time."}
    }
};

/*********************************************************
* Function Name : trainingChnageStatus
* Description   : This function is used for change training request status
* By            : Afsar Ali
* Date          : 15-05-2024 
*********************************************************/
export const trainingChnageStatus = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/training/request/change-status`,
            postData : {
                ...options,
                ipAddress : await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/********************** Boarding ********************************* */
/*********************************************************
* Function Name : boardingList
* Description   : This function is used for boarding request list
* By            : Afsar Ali
* Date          : 15-05-2024 
*********************************************************/
export const boardingList = async(options) => {
    try{
     const params = {
        url: `${API_ADMIN_URL}servicesmaster/boarding/request/list`,
        postData: options
     }
     const res = await postRequest(params); 
     if(res.status === true || res.status === 200){
        return { status: true, result: res?.data?.response.result};
     }else{
        return {status: false, result: res?.response?.data?.statusMessage };
     }
    }catch(error){
        console.log("Error", error);
        return {status: false, message: "Under Maintenance, Please try after some time."}
    }
};

/*********************************************************
* Function Name : boardingChnageStatus
* Description   : This function is used for change boarding request status
* By            : Afsar Ali
* Date          : 15-05-2024 
*********************************************************/
export const boardingChnageStatus = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/boarding/request/change-status`,
            postData : {
                ...options,
                ipAddress : await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/********************** Day Care ********************************* */
/*********************************************************
* Function Name : dayCareList
* Description   : This function is used for day care request list
* By            : Afsar Ali
* Date          : 16-05-2024 
*********************************************************/
export const dayCareList = async(options) => {
    try{
     const params = {
        url: `${API_ADMIN_URL}servicesmaster/day-care/request/list`,
        postData: options
     }
     const res = await postRequest(params); 
     if(res.status === true || res.status === 200){
        return { status: true, result: res?.data?.response.result};
     }else{
        return {status: false, result: res?.response?.data?.statusMessage };
     }
    }catch(error){
        console.log("Error", error);
        return {status: false, message: "Under Maintenance, Please try after some time."}
    }
};

/*********************************************************
* Function Name : dayCareStatus
* Description   : This function is used for change day-care request status
* By            : Afsar Ali
* Date          : 16-05-2024 
*********************************************************/
export const dayCareStatus = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/day-care/request/change-status`,
            postData : {
                ...options,
                ipAddress : await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : bulkList
* Description   : This function is used for get all bulk service request list
* By            : Afsar Ali
* Date          : 12-08-2024 
*********************************************************/
export const bulkList = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/request/bulk-list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : bulkChnageStatus
* Description   : This function is used for chage bulk request
* By            : Afsar Ali
* Date          : 12-08-2024 
*********************************************************/
export const bulkChnageStatus = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/request/bulk-change-status`,
            postData : {
                ...options,
                ipAddress : await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function