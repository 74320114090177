import { useEffect, useState } from "react";
import "./chatList.css";
import AddUser from "./addUser/AddUser";
import { useUserStore } from "../../../../lib/userStore";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../../../../config/firebaseConfig";
import { useChatStore } from "../../../../lib/chatStore";

const ChatList = () => {
  const [chats, setChats] = useState([]);
  const [addMode, setAddMode] = useState(false);
  const [input, setInput] = useState("");
  const { currentUser } = useUserStore();
  const { changeChat } = useChatStore();

  useEffect(() => {
    if (!currentUser?.id) return;

    const userChatDocRef = doc(db, "userChats", currentUser.id);
    const unSub = onSnapshot(userChatDocRef, async (res) => {
      if (!res.exists()) {
        console.error("Document does not exist");
        return;
      }

      const items = res.data()?.chats || [];
      const promises = items.map(async (item) => {
        const userDocRef = doc(db, "users", item.receiverId);
        const userDocSnap = await getDoc(userDocRef);
        const user = userDocSnap.data() || {};
        return { ...item, user };
      });

      try {
        const chatData = await Promise.all(promises);
        setChats(chatData.sort((a, b) => b.updatedAt - a.updatedAt));
      } catch (err) {
        console.error("Error processing chat data", err);
      }
    });

    return () => {
      unSub();
    };
  }, [currentUser?.id]);

  const handleSelect = async (chat) => {
    const userChats = chats.map((item) => {
      const { user, ...rest } = item;
      return rest;
    });
    const chatIndex = userChats.findIndex((item) => item.chatId === chat.chatId);
    userChats[chatIndex].isSeen = true;

    const userChatsRef = doc(db, "userChats", currentUser.id);

    try {
      await updateDoc(userChatsRef, {
        chats: userChats,
      });
      console.log("Changing chat to:", chat.chatId, chat.user);
      if (typeof chat.chatId !== "string") {
        console.log(chat , "typeog");
        console.error("chatId must be a string", chat.chatId);
      }
      if (typeof chat.user.id !== "string") {
        console.error("user.id must be a string", chat.user.id);
      }
      changeChat(chat.chatId, chat.user);
    } catch (err) {
      console.log(err);
    }
  };

const filteredChats = chats.filter((c) => 
  c.user && c.user.username && c.user.username.toLowerCase().includes(input.toLowerCase())
);

  return (
    <div className="chatList">
      <div className="search">
        <div className="searchBar">
          <img src="./images/chat/search.png" alt="" />
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => setInput(e.target.value)}
          />
        </div>
        <img
          src={addMode ? "./images/chat/minus.png" : "./images/chat/plus.png"}
          alt=""
          className="add"
          onClick={() => setAddMode((prev) => !prev)}
        />
      </div>
      {filteredChats.map((chat) => (
        <div
          className="item"
          key={chat.chatId}
          onClick={() => handleSelect(chat)}
          style={{ backgroundColor: chat?.isSeen ? "transparent" : "#5183fe" }}
        >
          <img
            src={chat.user.avatar || "./images/chat/avatar.png"}
            alt=""
          />
          <div className="texts">
            <span>{chat.user.username}</span>
            <p>{chat.lastMessage}</p>
          </div>
        </div>
      ))}
      {addMode && <AddUser />}
    </div>
  );
};

export default ChatList;
