import React, { useRef, useEffect, useState } from "react";
import useLoadGoogleMapsScript from "./useLoadGoogleMapsScript"; // Adjust the path as necessary
const { GOOGLE_MAP_API } = require('../../config/constants');
const MapComponent = ({ setAddEditSata }) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    // componentRestrictions: { country: "in" }, 
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"]
  };
  
  const isScriptLoaded = useLoadGoogleMapsScript(GOOGLE_MAP_API);
  const [isAutoCompleteEnabled, setIsAutoCompleteEnabled] = useState(false);

  useEffect(() => {
    if (!isScriptLoaded) return;

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);

    const handlePlaceChanged = async () => {
      const place = await autoCompleteRef.current.getPlace();
      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const mapLink = `https://www.google.com/maps?q=${lat},${lng}`;
        setAddEditSata((pre)=> ({
          ...pre,
          latitude : lat,
          longitude : lng
        })) 
        
      }
    };

    autoCompleteRef.current.addListener("place_changed", handlePlaceChanged);

    return () => {
      window.google.maps.event.clearInstanceListeners(autoCompleteRef.current);
    };
  }, [isScriptLoaded]);

  const handleInputChange = () => {
    const inputValue = inputRef.current.value;
    // if (inputValue.length >= 3) {
    //   if (!isAutoCompleteEnabled) {
    //     setIsAutoCompleteEnabled(true);
    //     autoCompleteRef.current.setOptions(options); // Enable autocomplete with options
    //   } 
    // } else {
    //   if (isAutoCompleteEnabled) {
    //   }
    // }
    setIsAutoCompleteEnabled(false);
    autoCompleteRef.current.setOptions(options); // Disable autocomplete
  };

  return (
    <>
     <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
      <label htmlFor="title" className="all_top_and_bottom_for_input_text" >Search Store :{" "} </label>
      <input className="form-control store_input_field" ref={inputRef} onInput={handleInputChange} />
    </div>
    </>
   
  );
};

export default MapComponent;
