// import React from "react";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import IconButton from "@mui/material/IconButton";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import { IoMdClose } from "react-icons/io";
// import { notification } from "antd";
// import { DeleteOutlined } from "@ant-design/icons";
// import { deleteBreeds } from "../../../controllers/cms/breeds";
// import { API_ADMIN_URL } from "../../../config/constants";

// const PromotionsDeletePopUp = ({ popUpOpen, togglePopUp, deleteId, setDeleteId }) => {
//   const handleCancel = () => {
//     togglePopUp();
//     setDeleteId('');
//   };
//   const handleDelete = async () => {
//     try {
//       if(deleteId){
//         const options = { id : deleteId};
//         const res = await deleteBreeds(options);
//         if (res.status === true) {
//           notification.open({
//             message: "Deleted!",
//             description: "Data Deleted Successfully.",
//             placement: "topRight",
//             duration: 2,
//             icon: <DeleteOutlined style={{ color: "green" }} />,
//           });
//         } else {
//           notification.open({
//             message: "Oops!!",
//             description: "Please try again.",
//             placement: "topRight",
//             duration: 2,
//             icon: <DeleteOutlined style={{ color: "red" }} />,
//           });
//         }
//         if (typeof setDeleteId === "function") {
//           setDeleteId("");
//         }
//         handleCancel();
//       } else{
//         notification.open({
//           message: "Error",
//           description: "Data Not Found! Try Again.",
//           placement: "topRight",
//           duration: 2,
//           icon: <DeleteOutlined style={{ color: "red" }} />,
//         });  
//       }
//     } catch (error) {
//       notification.open({
//         message: "Error",
//         description: "An error occurred while deleting. Please try again.",
//         placement: "topRight",
//         duration: 2,
//         icon: <DeleteOutlined style={{ color: "red" }} />,
//       });
//     }
//   };

//   return (
//     <Dialog
//       open={popUpOpen}
//       onClose={togglePopUp}
//       maxWidth="md"
//       PaperProps={{
//         className: "myDialogPopUp",
//       }}
//     >
//       <div className="myDrawer">
//         <div className="myMainDrawerClass">
//           <Typography
//             variant="h5"
//             component="div"
//             gutterBottom
//             className="myFilterClass"
//           >
//             Confirm
//           </Typography>
//           <IconButton onClick={togglePopUp}>
//             <IoMdClose />
//           </IconButton>
//         </div>
//         <Typography
//           variant="body1"
//           component="div"
//           className="my_delete_popup_paragraph"
//         >
//           <p>Do you want to remove this item?</p>
//         </Typography>
//         <DialogActions>
//           <Button onClick={handleCancel} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleDelete} style={{ color: "red" }}>
//             Delete
//           </Button>
//         </DialogActions>
//       </div>
//     </Dialog>
//   );
// };

// export default PromotionsDeletePopUp;
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import { notification } from "antd";
import { InfoCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { chnageStatus } from "../../../controllers/vendors/vendorsController";

const VendorListDeletePopUp = ({ popUpOpen, togglePopUp, deleteId, setDeleteId }) => {
  const handleCancel = () => {
    togglePopUp();
  };

  const handleDelete = async () => {
    try{
      const options ={
        url : `accounts/vendors/changestatus`,
        postData :{
          user_id : deleteId,
          status : 'D',
        }
      }
      const res = await chnageStatus(options);
      if(res.status === true){
        notification.open({
          message: "User deleted successfully.",
          description:  `Data deleted Successfully.`,
          placement: "topRight", 
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
          duration:2
        });
        setDeleteId("");
        togglePopUp()
      } else{
        notification.open({
          message: "Opps!",
          description:  `${res?.message}`,
          placement: "topRight", 
          icon: <InfoCircleOutlined style={{ color: 'red' }} />,
          duration:2
        });
      }
    } catch (error){
      notification.open({
        message: "Opps!",
        description:  `Operation not perform yet! please try in some time.`,
        placement: "topRight", 
        icon: <InfoCircleOutlined style={{ color: 'red' }} />,
        duration:2
      });
    }
  };

  return (
    <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirm
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUp}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Do you want to terminate this Blog?</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} style={{ color: "red" }}>
            Delete
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default VendorListDeletePopUp;
