import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification, Select, Upload, Flex } from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import "../ServiceList.css";
import moment from "moment";
import Loading from "../../../components/Loading/LoadingEffect";
import { ucfirst, commonList } from "../../../controllers/common";
import { createPet } from "../../../controllers/services/dogAdoption";
import { list as vendorlist } from "../../../controllers/vendors/vendorsController";
import { beforeUpload } from "../../../controllers/common";
import { list, chnageStatus } from "../../../controllers/cms/breeds";
import axios from "axios";

const ProductListAddEditDog = ({
  show,
  editdata,
  setEditData,
  setAddEditPopup,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, serError] = useState([]);
  const [success, serSuccess] = useState([]);
  const [formData, setFormData] = useState("");
  const [imageList, setImageList] = useState("");
  const [photos, setPhotosList] = useState("");
  const [ALLVENDOR, setAllVendor] = useState([]);
  const [ALLSTORE, setAllStore] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);
  const [ALLCATEGORY, setAllCategory] = useState([]);
  const [ALLSUBCATEGORY, setAllSubCategory] = useState([]);
  const [filterSubCat, setFilterSubCat] = useState([]);
  const [breeds, setBreeds] = useState([]);

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  const handleCancel = () => {
    setEditData("");
    setFormData("");
    setImageList("");
    setPhotosList("");
    setAddEditPopup(false);
  };

  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data saved Successfully .",
      placement: "topRight",
      duration: 2,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };

  const handleErrorMsg = (msg = "") => {
    notification.open({
      message: "Opps!",
      description: error?.formError || msg,
      placement: "topRight",
      duration: 2,
      icon: <CloseOutlined style={{ color: "red" }} />,
    });
  };

  const getVendorList = async () => {
    try {
      const options = {
        condition: { status: "A" },
        select: {
          vendor_id: false,
          vendor_email: false,
          creation_date: false,
          createdAt: false,
          creation_ip: false,
          vendor_address: false,
          vendor_city: false,
          vendor_country: false,
          vendor_image: false,
          vendor_otp: false,
          vendor_phone: false,
          vendor_pincode: false,
          vendor_state: false,
          created_by: false,
          update_date: false,
          update_ip: false,
          updatedAt: false,
          updated_by: false,
          vendor_commission: false,
          vendor_country_code: false,
          vendor_gender: false,
          vendor_revenue: false,
          vendor_street: false,
        },
        sort: { vendor_first_name: 1 },
      };
      const res = await vendorlist(options);
      if (res.status === true) {
        setAllVendor(res?.result);
      } else {
        setAllVendor([]);
      }
    } catch (error) {
      setAllVendor([]);
    }
  };

  const getCommonListData = async (vendorId) => {
    try {
      setAllStore([]);
      setAllBranch([]);
      setAllCategory([]);
      setAllSubCategory([]);
      const options = {
        vendorId: vendorId,
        list: ["service_category", "store", "branch"],
        serviceCat: { select: { name: true } },
        serviceSubCat: { select: { category: true, name: true } },
        store: { select: { shop_name: true } },
        branch: { select: { storeData: true, branch_name: true } },
      };
      const res = await commonList(options);
      if (res?.status === true) {
        setAllStore(res?.result?.store);
        setAllBranch(res?.result?.branch);
        setAllCategory(res?.result?.serviceCat);
        setAllSubCategory(res?.result?.serviceSubCat);
        if (editdata?.branch) {
          const filterArray = res?.result?.branch.filter(
            (val) => val.storeData == editdata?.store
          );
          setFilterBranch(filterArray);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChnage = (e) => {
    const { name, value } = e.target;
    if (name === "vendor") {
      setFormData((pre) => ({
        ...pre,
        [name]: value,
      }));
      getCommonListData(value);
      setFilterBranch([]);
    } else if (name === "store") {
      setFormData((pre) => ({
        ...pre,
        [name]: value,
      }));

      setEditData((pre) => ({
        ...pre,
        [name]: value,
      }));
      const filterArray = ALLBRANCH.filter((val) => val.storeData == value);
      setFilterBranch(filterArray);
    } else {
      setFormData((pre) => ({
        ...pre,
        [name]: value,
      }));
      setEditData((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
    serError((pre) => ({
      ...pre,
      [e.target.name]: "",
    }));
  };

  const handleImageChnage = (e) => {
    setImageList(e.fileList[0].originFileObj);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = new FormData(e.target);
    form.append("image", imageList ? imageList : null);
    form.append("branch", filterBranch[0]?._id || "");
    form.append("ipAddress", sessionStorage.getItem("IP_ADDRESS"));
    const isValidate = validateFormData(form);
    if (isValidate) {
      const res = await createPet(form);
      if (res.status === true) {
        setFormData("");
        setImageList("");
        handleSuccessMsg();
        handleCancel();
      } else {
        serError((pre) => ({
          ...pre,
          formError: res?.message,
        }));
        handleErrorMsg();
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      handleErrorMsg("Please fill required fields.");
    }
  };

  const validateFormData = (formData) => {
    if (
      formData.get("vendor") === "undefined" ||
      formData.get("vendor") === null ||
      formData.get("vendor").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        vendor: "Vendor is required",
      }));
      return false;
    } else if (
      formData.get("store") === "undefined" ||
      formData.get("store") === null ||
      formData.get("store").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        store: "Store is required",
      }));
      return false;
    } else if (
      formData.get("branch") === "undefined" ||
      formData.get("branch") === null ||
      formData.get("branch").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        branch: "Branch is required",
      }));
      return false;
    } else if (
      formData.get("pet_name") === "undefined" ||
      formData.get("pet_name") === null ||
      formData.get("pet_name").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        pet_name: "Pet name is required",
      }));
      return false;
    } else if (
      formData.get("description") === "undefined" ||
      formData.get("description") === null ||
      formData.get("description").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        description: "Description is required",
      }));
      return false;
    } else if (
      formData.get("breed") === "undefined" ||
      formData.get("breed") === null ||
      formData.get("breed").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        breed: "Breed is required",
      }));
      return false;
    } else if (
      formData.get("gender") === "undefined" ||
      formData.get("gender") === null ||
      formData.get("gender").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        gender: "Gender is required",
      }));
      return false;
    } else if (
      formData.get("mobile_no") === "undefined" ||
      formData.get("mobile_no") === null ||
      formData.get("mobile_no").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        mobile_no: "Mobile No is required",
      }));
      return false;
    } else if (
      formData.get("dob") === "undefined" ||
      formData.get("dob") === null ||
      formData.get("dob").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        dob: "Date of birth is required",
      }));
      return false;
    } else if (!editdata?._id && formData.get("image") === "null") {
      serError((preError) => ({
        ...preError,
        image: "Image is required",
      }));
      return false;
    } else {
      return true;
    }
  };

  const getList = async () => {
    try {
      const options = {
        condition: {},
        select: { name: 1 },
        sort: { _id: -1 },
      };
      const listData = await list(options);
      if (listData.status === true) {
        return listData.result.map((breed) => breed.name);
      } else {
        console.error("No Data Found");
        return [];
      }
    } catch (error) {
      console.error("Error fetching breeds:", error);
      return [];
    }
  };

  useEffect(() => {
    getVendorList();
    if (editdata?._id) {
      setIsLoading(true);
      getCommonListData(editdata?.vendor);
    }
  }, [show]);

  useEffect(() => {
    const fetchBreeds = async () => {
      const breedNames = await getList();
      setBreeds(breedNames);
    };
    fetchBreeds();
  }, []);
  return (
    <Dialog
      open={show}
      onClose={handleCancel}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="categories_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="categories_popup_title_heading"
            >
              {editdata?._id ? "Edit Adoption" : "Add Adoption"} Service
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form
                  className="categories_open_popup_form"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="editId"
                    id="editId"
                    value={editdata?._id}
                  />
                  <div class="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        for="vendor"
                        className="categories_open_popup_label_name"
                      >
                        {" "}
                        Vendor{" "}
                      </label>
                      <select
                        id="vendor"
                        name="vendor"
                        onChange={handleChnage}
                        class="form-control"
                      >
                        <option value="">Select a vendor</option>
                        {ALLVENDOR &&
                          ALLVENDOR.map((items, index) => (
                            <option
                              key={index}
                              value={items._id}
                              selected={
                                editdata?.vendor === items._id ? true : false
                              }
                            >
                              {`${ucfirst(items.vendor_first_name)} ${ucfirst(
                                items.vendor_last_name
                              )}`}
                            </option>
                          ))}
                      </select>
                      {error?.vendor ? (
                        <p style={{ color: "red" }}>{error.vendor}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        for="store"
                        className="categories_open_popup_label_name"
                      >
                        {" "}
                        Store{" "}
                      </label>
                      <select
                        id="store"
                        name="store"
                        onChange={handleChnage}
                        class="form-control"
                      >
                        <option value="">Select a store</option>
                        {ALLSTORE &&
                          ALLSTORE.map((items, index) => (
                            <option
                              key={index}
                              value={items._id}
                              selected={
                                editdata?.store === items._id ? true : false
                              }
                            >
                              {`${ucfirst(items.shop_name)}`}
                            </option>
                          ))}
                      </select>
                      {error?.store ? (
                        <p style={{ color: "red" }}>{error.store}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="name"
                        className="categories_open_popup_label_name"
                      >
                        Pet Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="pet_name"
                        placeholder="Enter pet name"
                        name="pet_name"
                        value={
                          formData?.pet_name
                            ? formData?.pet_name
                            : editdata?.pet_name
                        }
                        onChange={handleChnage}
                      />
                      {error?.pet_name ? (
                        <p style={{ color: "red" }}>{error.pet_name}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="name"
                        className="categories_open_popup_label_name"
                      >
                        Description
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="description"
                        placeholder="Enter Description"
                        name="description"
                        value={
                          formData?.description
                            ? formData?.description
                            : editdata?.description
                        }
                        onChange={handleChnage}
                      />
                      {error?.description ? (
                        <p style={{ color: "red" }}>{error.description}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="about"
                        className="categories_open_popup_label_name"
                      >
                        About
                      </label>
                      <textarea
                        class="form-control"
                        id="about"
                        placeholder="Enter about the dog"
                        name="about"
                        onChange={handleChnage}
                      >
                        {formData?.about ? formData?.about : editdata?.about}
                      </textarea>
                      {error?.about ? (
                        <p style={{ color: "red" }}>{error.about}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="breed"
                        className="categories_open_popup_label_name"
                      >
                        Breed
                      </label>
                      <select
                        className="form-control"
                        id="breed"
                        name="breed"
                        value={formData?.breed || editdata?.breed}
                        onChange={handleChnage}
                      >
                        <option value="" disabled>
                          Select breed
                        </option>
                        {breeds.map((breed, index) => (
                          <option key={index} value={breed}>
                            {breed}
                          </option>
                        ))}
                      </select>
                      {error?.breed && (
                        <p style={{ color: "red" }}>{error.breed}</p>
                      )}
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="name"
                        className="categories_open_popup_label_name"
                      >
                        Gender
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="gender"
                        placeholder="Enter Gender"
                        name="gender"
                        value={
                          formData?.gender ? formData?.gender : editdata?.gender
                        }
                        onChange={handleChnage}
                      />
                      {error?.gender ? (
                        <p style={{ color: "red" }}>{error.gender}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="name"
                        className="categories_open_popup_label_name"
                      >
                        Mobile Number
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        id="mobile_no"
                        placeholder="Enter mobile number"
                        name="mobile_no"
                        value={
                          formData?.mobile_no
                            ? formData?.mobile_no
                            : editdata?.mobile_no
                        }
                        onChange={handleChnage}
                      />
                      {error?.mobile_no ? (
                        <p style={{ color: "red" }}>{error.mobile_no}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="dob"
                        className="categories_open_popup_label_name"
                      >
                        Date of Birth{" "}
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        id="dob"
                        placeholder="Enter mobile number"
                        name="dob"
                        value={
                          formData?.dob
                            ? moment(formData?.dob).format("YYYY-MM-DD")
                            : moment(editdata?.dob).format("YYYY-MM-DD")
                        }
                        onChange={handleChnage}
                      />
                      {error?.dob ? (
                        <p style={{ color: "red" }}>{error.dob}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label className="categories_open_popup_label_name">
                        Image
                      </label>
                      <div className="inventory_upload_file_main_div">
                        <Upload
                          onChange={handleImageChnage}
                          beforeUpload={beforeUpload}
                          name="image"
                          maxCount="1"
                          listType="picture-card"
                          className="product_inventory_edit_upload"
                        >
                          <button
                            className="inventroy_edit_page_antd_uplaod_button"
                            type="button"
                          >
                            <div className="inventroy_edit_page_antd_choose_file">
                              Choose File
                            </div>
                          </button>
                        </Upload>
                      </div>
                      <p style={{ color: "blue" }}>
                        *Note: Please choose an image of JPEG/JPG/PNG format
                        file.
                      </p>
                      {error?.image ? (
                        <p style={{ color: "red" }}>{error.image}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="product_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-danger ">
                      {editdata._id ? "Save Changes" : "Create"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
          <Loading isLoading={isLoading} />
        </div>
      </div>
    </Dialog>
  );
};

export default ProductListAddEditDog;
